import { HttpWrapperService } from "../../../shared/services/http.wrapper.service";
import { Injectable } from "@angular/core";



@Injectable()
export class SearchService {

    constructor(private _httpWrapper:HttpWrapperService) {

    }

    GetSearchResultList(data:any) {
        return this._httpWrapper.post({ url: "api/order/search/search", data: data });
    }

}