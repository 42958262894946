import { Component, OnInit, ViewChild, ElementRef, Renderer } from '@angular/core';
import { WindowRef } from '../../shared/services/window.client.service';
import { ListRecordStringService } from '../../admin/common/services/list.record.string.service';
import { ActivityService } from './activity.service';
import * as _ from 'lodash';

@Component({
    selector: 'activity-list',
  templateUrl: './activity.list.component.html',
  styleUrls: ['../../duke/order/order.detail.component.css']
})
export class ActivityListComponent extends ListRecordStringService implements OnInit {

  @ViewChild("dtPick", { static: false }) datePicker: ElementRef;
    WindowRef = WindowRef;
    totalCount: number = 0;
    activityList: any[] = [];
    orderId: number = 0;
    public daterange: any = {};
    file: any;
    public options: any = {
        locale: { format: 'YYYY-MM-DD' },
        alwaysShowCalendars: false,
    };
    downloading: boolean = false;
    isRecordExist: boolean = true;

    SortColumns: any = {
        OrderNumber: 'OrderNumber',
        OrderDate: 'OrderDate',
        Email: 'Email',
        Quantity: 'Quantity',
        Status: 'Status'
    }

    public selectedDate(values: any) {
        let that = this;
        //that.search_filter.filter.startDate = values.start.toDate();
        //that.search_filter.filter.endDate = values.end.toDate();
              that.search_filter.filter.startDate = values[0];
        that.search_filter.filter.endDate = values[1];
        that.getOrderList();
    }

    search_filter: any = {
        filter: {
            formNumber: "",
            email: "",
            startDate: "",
            endDate: "",
            costCenter: "",
            statusId: "",
            level1: "",
            level2: "",
            level3: ""
        },
        pageSize: 25,
        pageNumber: 1,
        order: 0,
        propName: ""
    }

    clearAllFilters() {
        let that = this;
        that.search_filter = {
            filter: {
                formNumber: "",
                email: "",
                startDate: "",
                endDate: "",
                costCenter: "",
                statusId: "",
                level1: "",
                level2: "",
                level3: ""
            },
            pageSize: 25,
            pageNumber: 1,
            order: 0,
            propName: ""
        }
        that.getOrderList();
    }

    constructor(private _activityService: ActivityService, private renderer: Renderer) {
        super();
    }

    ngOnInit() {
        let that = this;
        that.getOrderList();
    }

    sorting(propName: string) {
        let that = this;
        that.search_filter.propName = propName;
        that.search_filter.order = (that.search_filter.order == 0) ? 1 : 0;
        that.getOrderList();
    }

    focusOnDateRange() {
        let that = this;
        that.renderer.invokeElementMethod(that.datePicker.nativeElement, 'focus', []);

    }

    getOrderList() {
        let that = this;
        that.isRecordExist = true;
        that._activityService.ListActivity(that.search_filter).then(function (data: any) {
            that.activityList = data.collection;
            that.totalCount = data.totalRecords;
            that.calculateRecordString(data, that.search_filter);
            that.isRecordExist = that.activityList.length > 0;
        })
    }

    //getLevel1Categories() {
    //    let that = this;
    //    that.level1Categories = that.categoryDataList.filter(function (item: any) {
    //        return item.level == 1;
    //    });

    //}

    //getLevel2Categories(check: boolean) {
    //    let that = this;
    //    that.getOrderList();
    //    that.level2Categories = that.categoryDataList.filter(function (item: any) {
    //        return item.level == 2 && item.parentCategoryId == that.selectedCategories.selectedLevel1CategoryId;
    //    });
    //    if (!check) {
    //        that.search_filter.filter.level2 = "";
    //        that.search_filter.filter.level3 = "";
    //    }
    //}


    //getLevel3Categories(check: boolean) {
    //    let that = this;
    //    that.getOrderList();
    //    that.level3Categories = that.categoryDataList.filter(function (item: any) {
    //        return item.level == 3 && item.parentCategoryId == that.selectedCategories.selectedLevel2CategoryId
    //    });
    //    if (!check) {
    //        that.search_filter.filter.level3 = "";
    //    }
    //}

    exportToExcel() {
        let that = this;
        that.downloading = true;
        that._activityService.exportToExcel(that.search_filter).then(function (result: any) {
            var fileName = "order.xlsx";
            that.downloadFile(result, fileName);
            that.downloading = false;
        })
    }

    exportToCSV() {
        let that = this;
        that.downloading = true;
        that._activityService.exportToCSV(that.search_filter).then(function (result: any) {
            var fileName = "order.csv";
            that.downloadFile(result, fileName);
            that.downloading = false;
        })
    }

    downloadFile(data: any, fileName: any) {
        var file = new Blob([data], {
            type: 'application/octet-binary'
        });
        var fileUrl = URL.createObjectURL(file);

        // for IE 10+
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(file, fileName);
        } else {
            var element = document.createElement('a');
            element.href = fileUrl;
            element.setAttribute('download', fileName);
            element.setAttribute('target', '_blank');
            document.body.appendChild(element);
            element.click();
        }
    };
}
