import { Injectable } from "@angular/core";




@Injectable()
export class ValidationMessageService {

    static messages: any = {
        alphabets: " Only alphabets are allowed",
        alphaNumeric: " Only alphanumeric are allowed",
        alphanumericSpace: ' Only alphanumeric and space are allowed',
        specialAlphanumericSpace: " Only special character alphanumeric space are allowed",
        specialAlphanumeric: " Only special character alphanumeric are allowed",
        integer: " Only numbers are allowed",
        number: " Only numbers are allowed",
        nonZeroNumber: " Only non zero values are allowed",
        email: " Please enter a valid email",
        weekPasswordStrength: " Minimum password length of 8 character.",
        enterPassword: " Please enter password.",
        confirmPassword: " Please confirm password.",
        usZipMaxLength: "Max length of Zip can be of 6 character.",
        usZipMinLength: "Min length of Zip can be of 5 character.",
        usPhone: 'Please enter a valid Phone Number.',
        max1024Chars: 'Cannot enter more than 1024 chars.',
        max512Chars: 'Cannot enter more than 512 chars.',
        max256Chars: 'Cannot enter more than 256 chars.',
        validateEqual: 'Password mismatch.',
    };

    static patterns: any = {
        usZip: '[0-9]*',
        specialAlphanumericSpace: '[^~,][^~,]*',//'[A-Za-z0-9_@./#&+ ]*',
        specialAlphanumeric: '[^~,][^~,]*',//'[A-Za-z0-9_@./#&+]*',
        alphanumericSpace: '[A-Za-z0-9 ]*',
        alphanumeric: '[A-Za-z0-9]*',
        alphabetSpace: '[A-Za-z ]*',
        //email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
        email: '[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$',
        usPhone: '[0-9]*',// '[(]([0-9]{3}[)] [0-9]{3}-[0-9]{4})'
        number: '[0-9.]*',
        integer: '[0-9]*',
        nonZeroNumber: '[1-9][0-9]*$',
        alphabetCaps: '[A-Z]*',
        alphanumericWithAmpersandSpacePeriodAndComma: '[A-Za-z0-9 &,\.]*'
    }

}
