import { Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "manage-keywords",
    templateUrl: "./keywords.component.html",
})


export class KeywordsComponent {

    constructor(private _activeModal: NgbActiveModal) {

    }
    close() {
        let that = this;
        that._activeModal.close();
    }

    dismiss() {
        this._activeModal.dismiss();
    }
}
