import { Injectable } from "@angular/core";
import { HttpWrapperService } from "../../../shared/services/http.wrapper.service";

@Injectable()
export class EventBundleService {

    constructor(private httpWrapper: HttpWrapperService) {

    }

    GeteventBundle(id: number) {
        return this.httpWrapper.get({ url: 'api/catalogue/eventbundle/' + id });
    }

    SaveEventBundle(data: any) {
        return this.httpWrapper.post({ url: 'api/catalogue/eventbundle', data: data });
    }

    UpdateEventBundleStatus(data: any) {
        return this.httpWrapper.post({ url: 'api/catalogue/eventbundle/status', data: data });
    }

    CheckUniqueNumber(data: any) {
        return this.httpWrapper.post({ url: 'api/catalogue/eventbundle/check', data: data });
    }

    GeteventBundleList(data: any) {
        return this.httpWrapper.post({
            url: 'api/catalogue/eventbundle/list',
            data: data
        });
    }

    GetLiteratureByID(id: any) {
        return this.httpWrapper.post({
            url: 'api/catalogue/eventbundle/literature?id=' + id,
        });
    }
}