import { Component } from "@angular/core";



@Component({
    selector: 'admin',
  templateUrl: './admin.component.html',

})
export class AdminComponent {



}
