import { Injectable } from '@angular/core';
import { HttpWrapperService } from '../../../shared/services/http.wrapper.service';

@Injectable()
export class UserOrderService {

    constructor(private _httpWrapper: HttpWrapperService) {
    }

    SaveOrder(data: any) {
        return this._httpWrapper.post({ url: "api/order/order/place", data: data });
    }

    SaveBundleOrder(data: any) {
        return this._httpWrapper.post({ url: "api/order/order/bundle", data: data });
    }


    ListOrder(data: any) {
        return this._httpWrapper.post({ url: "api/order/order/list", data: data });
    }


    GetOrder(id: number) {
        return this._httpWrapper.get({ url: "api/order/order/" + id });
    }


    GetOrderItem(id: number) {
        return this._httpWrapper.get({ url: "api/order/order/item/" + id });
    }
}





