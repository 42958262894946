import { Component } from "@angular/core";

@Component({
    selector: "related-products",
  templateUrl: "./related.products.component.html",
})


export class RelatedProductsComponent {


}
