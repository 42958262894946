import { Injectable } from "@angular/core";
import { HttpWrapperService } from "../../../shared/services/http.wrapper.service";



@Injectable()
export class ImageService {
    constructor(private _httpWrapper:HttpWrapperService) {

    }


    GetImage(id: number) {
        return this._httpWrapper.get({ url: 'api/catalogue/image/' + id });
    }


    SaveImage(data: any) {
        return this._httpWrapper.post({ url: 'api/catalogue/image', data: data });
    }

    UpdateImageStatus(data: any) {
        return this._httpWrapper.post({ url: 'api/catalogue/image/status', data: data });
    }


    GetImageList(data: any) {
        return this._httpWrapper.post({
            url: 'api/catalogue/image/list',
            data: data
        });
    }

    CheckUniqueNumber(data: any) {
        return this._httpWrapper.post({ url: 'api/catalogue/image/check', data: data });
    }



}