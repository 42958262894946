import { HttpWrapperService } from "../../../shared/services/http.wrapper.service";
import { Injectable } from "@angular/core";



@Injectable()
export class LiteratureDetailService {

    constructor(private _httpWrapper:HttpWrapperService) {

    }

    GetSubCategoryList(data:any) {
        return this._httpWrapper.post({ url: "api/order/literature/search", data: data });
    }


    GetNewlyAddedLiteratureList() {
        return this._httpWrapper.post({ url: "api/order/literature/new", data: {}});
    }


    GetLiteratureDetail(id: number) {
        return this._httpWrapper.get({ url: "api/order/literature/"+id});
    }



}