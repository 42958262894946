
export class ListRecordStringService {
 
    recordString: string = "";

    calculateRecordString(data: any, search_filter: any) {
        this.recordString = "Showing ";

        if (search_filter.pageNumber > 1) {
            this.recordString += " " + ((search_filter.pageSize * (search_filter.pageNumber - 1)) + 1);
        } else {
            this.recordString += " " + search_filter.pageNumber;
        }

        if (search_filter.pageSize * search_filter.pageNumber > data.totalRecords) {
            this.recordString += " to " + data.totalRecords + " of " + data.totalRecords + " records";
        } else {
            this.recordString += " to " + search_filter.pageSize * search_filter.pageNumber + " of " + data.totalRecords + " records";
        }
    }
}