import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { WindowRef } from '../../../shared/services/window.client.service';
import { LocalStorageWrapper } from '../../../shared/services/local.storage.service';
import { Router } from '@angular/router';
import { NotifyFlashMessageService } from '../../../shared/services/notify.flash.messages.service';

@Injectable()
export class LoginHelperService {
    WindowRef = WindowRef;
    private _window: any = null;

    constructor(private _cookie: CookieService,
        private router: Router,
        private _toaster: NotifyFlashMessageService
    ) {
        let that = this;
        this._window = new WindowRef();
    }

    setCookieObject(data: any) {
        var that = this;
        data = that.changeDataModel(data);
        if (!that.validateLoginResponseData(data
        )) {
            return;
        }
        var localStorageObject = new LocalStorageWrapper();
        //(new WindowRef()).nativeWindow['dam_user_identity'] = data;
        WindowRef.userIdentity = data;
      localStorageObject.setUserIdentity(data);
      that._cookie.set(that._window.nativeWindow.authTokenString, data.Token);
      var tokenObject: any = JSON.parse(that._cookie.get('authTokenName'));
        that.navigateToDashboard(tokenObject);
    }

    validateLoginResponseData(data: any) {
        let that = this;
        let isValid = true;
        if (data.Email == null) { //data.UserTypeId != WindowRef.lookup.user_type.duke
            that._toaster.error("Invalid credentials for login!");
            isValid = false;
        }
        return isValid;
    }


    changeDataModel(data: any) {
        return {
            "Id": data.id,
            "UserAccountId": null,
            "FirstName": data.firstName,
            "LastName": data.lastName,
            "Email": data.email,
            "BillToAccount": data.billToAccount,
            "UserTypeId": data.userTypeId,
            "SessionId": data.sessionId,
        }
    }

    navigateToDashboard(tokenObject: any) {
      var userFound = false;
      if (tokenObject == 'undefined' || tokenObject == null) {
        this.router.navigate(['/auth/login']);
      }
        if (tokenObject.UserTypeId == WindowRef.lookup.user_type.admin) {
            WindowRef.appBaseUrl = "admin";
        } else {
            WindowRef.appBaseUrl = "app";
        }
        
        if (tokenObject && tokenObject.UserTypeId == WindowRef.lookup.user_type.admin) {
            userFound = true;
            this.router.navigate(['/admin/dashboard']);
        }

        if (tokenObject && tokenObject.UserTypeId == WindowRef.lookup.user_type.internal) {
            userFound = true;
            this.router.navigate(['/app']);
        }

        if (tokenObject && tokenObject.UserTypeId == WindowRef.lookup.user_type.external) {
            userFound = true;
            this.router.navigate(['/app']);
        }
        if (tokenObject && tokenObject.UserTypeId == WindowRef.lookup.user_type.duke) {
            userFound = true;
            this.router.navigate(['/duke/home']);
        }
        if (!userFound) {
            this.router.navigate(['/auth/login']);
            //that.logout();
        }
    }
  setManagingCookiesObject(data: any) {
    var localStorageObject = new LocalStorageWrapper();
    localStorageObject.setManagingWebsiteCookies(data);
    
  }
  getManagingCookiesObject() {

    var localStorageObject = new LocalStorageWrapper();

   return  localStorageObject.getManagingWebsiteCookies();
    


  }
}
