import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'marketing',
  templateUrl: './marketing.component.html',
    encapsulation: ViewEncapsulation.None
})
export class MarketingComponent {

}
