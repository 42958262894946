import { Component, OnInit, Input } from "@angular/core";
import { trigger, state, style, animate, transition } from '@angular/animations';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { LoggerService } from "../../shared/services/logger.service";
import * as _ from 'lodash';
import { ActivatedRoute, Router } from "@angular/router";
import { WindowRef } from "../../shared/services/window.client.service"; 
import { NotifyFlashMessageService } from "../../shared/services/notify.flash.messages.service";
import { LocalStorageWrapper } from '../../shared/services/local.storage.service';

@Component({
    selector: "admin-distribute-address",
  templateUrl: "./admin.order.distribution.component.html",
    animations: [
        trigger('fadeInOut', [
            transition('void => *', [
                style({ opacity: 0 }), //style only for transition transition (after transiton it removes)
                animate(700, style({ opacity: 1 })) // the new state of the transition(after transiton it removes)
            ]),
            transition('* => void', [
                animate(0, style({ opacity: 0 })) // the new state of the transition(after transiton it removes)
            ])
        ])
    ]

})

export class AdminAddressDistributionComponent implements OnInit {
    WindowRef = WindowRef;
        
    total: number = 0;
    selected: number = 0;
    @Input() distributionlist: any[] = [];
    @Input() cartItems: any[] = [];

   

    ngOnInit(): void {
        
    }

    constructor(private _activeModal: NgbActiveModal, private _loggerService: LoggerService,
        private _toaster: NotifyFlashMessageService
        ) {
    }

    

   
    
    distributeAddress() {
        let that = this;
        var invalidDistribution = false;
        that.cartItems.forEach(function (item: any) {
            if (!invalidDistribution) {
                var result = that.checkDistributionQuantity(item);
                if (!result) {
                    that._toaster.error("Quantity distribution is not equal to total quantity!");
                    invalidDistribution = true;
                }
            }
        });

        if (invalidDistribution)
            return;

       
        that._activeModal.close();
       
    }

    checkDistributionQuantity(item: any) {
        let that = this;
        that.total = 0;
        that.selected = 0;
        that.distributionlist.forEach(function (address: any) {
            address.cartItems.forEach(function (cartItem: any) {
                if (cartItem.id == item.id) {
                    that.total = cartItem.orderQuantity;
                    that.selected = (+that.selected) + (+cartItem.quantity);
                }
            });
        });
        if (that.total == null || that.total <= 0)
            return true;
        else if (that.total == that.selected)
            return true;
        else
            return false;
    }


    

    dismiss() {
        this._activeModal.dismiss();
    }
}
