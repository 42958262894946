import { Component, OnInit, Input } from "@angular/core";
import {trigger, state, style, animate, transition} from '@angular/animations';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { KeywordService } from "../common/services/keyword.service";
import { LoggerService } from "../../shared/services/logger.service";
import * as _ from 'lodash';



@Component({
    selector: "manage-keyword",
  templateUrl: "./manage.keyword.model.component.html",
    styleUrls: ['../literature/literature.edit.component.css'],
    animations: [
        trigger('fadeInOut', [
            transition('void => *', [
                style({opacity:0}), //style only for transition transition (after transiton it removes)
                animate(700, style({opacity:1})) // the new state of the transition(after transiton it removes)
            ]),
            transition('* => void', [
                animate(0, style({opacity:0})) // the new state of the transition(after transiton it removes)
            ])
        ])
    ]

})
export class ManageKeywordModelComponent implements OnInit {

    @Input() selectedKeywords: any[] = [];
    @Input() uniqueNo: string = ""; 
    @Input() name: string = "";
    @Input() isLiterature: boolean;

    keyword: string = "";
    addKeyWords: boolean = false;
    keywordList: any[] = [];
    statusKeyword: boolean = false;
    keywordID: number;
    changedStatusKeyword:any = null;

    ngOnInit(): void {
        let that = this;
        that.getAllKeywords();
    }

    manageKeywords() {
        this.addKeyWords = !this.addKeyWords;
        this.sortKeywordList();
        console.log(this.selectedKeywords)
    }
    constructor(private _keywordService:KeywordService,private _activeModal: NgbActiveModal,private _loggerService:LoggerService) {

    }

    getAllKeywords() {
        let that = this;
        that._keywordService.getAllKeywords().then(function (data) {
            that.keywordList = data.map(function (item:any) {
                item.isSelected = false;
                let foundSelected = that.selectedKeywords.find(function (t:any) {
                    return t.key == item.id;
                });
                if (foundSelected) {
                    item.isSelected = true;
                }
                return item;
            });
            that.sortKeywordList();
            that._loggerService.log(that.keywordList);
        });
    }


    saveKeyword(keyword?:any) {
        let that = this;
        let needRefresh = true;
        if (!keyword) {
             needRefresh = false; 
             keyword = { title: that.keyword, alias: that.keyword.replace(/ /g, "_").toLowerCase() }
        }
      
        that._keywordService.saveKeyword(keyword).then(function (data) {
            that._loggerService.log(data);
            that.keyword = "";
            //if (!needRefresh) {
            //    that.getAllKeywords(); 
            //}
            that.getAllKeywords();

        });
    }

    clear() {
        this.keyword = "";
    }

    close() {
        let that = this;
        that.updateSelectedKeywords();
        that._activeModal.close(that.selectedKeywords);
    }

    dismiss() {
        this._activeModal.dismiss();
    }

    updateSelectedKeywords() {
        let that = this;
        that.selectedKeywords = [];
        that.keywordList.forEach(function (item:any) {
            if (item.isSelected) {
                that.selectedKeywords.push({key:item.id,value:item.title})
            }
        });
    }

    

    changeActivetionStatus(k: any) {
        let that = this;     
        if (!k.isActive) {
            k.isActive = !k.isActive;
            that.changedStatusKeyword = null;
            that.saveKeyword(k);
        } else {
            that.changedStatusKeyword = k;    
        }
    }


    confirmToChangeStatus(check: boolean) {
        let that = this;
        if (check && that.changedStatusKeyword) {

            that.changedStatusKeyword.isActive = !that.changedStatusKeyword.isActive;
            that.saveKeyword(that.changedStatusKeyword);
            
        }
        that.changedStatusKeyword = null;

    }


    sortKeywordList() {
        let that = this;
        that.keywordList.sort(function (a: any, b: any) {
            return (a.isActive == b.isActive) ? 0 : a.isActive ? -1 : 1;
        });
    }





}
