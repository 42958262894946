import {Injectable} from '@angular/core';
import { LocalStorageWrapper } from '../shared/services/local.storage.service';
import { WindowRef } from '../shared/services/window.client.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';





@Injectable()
export class AuthGuard {
    WindowRef = WindowRef;
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let that = this;
        let url: string = state.url;
        return that.checkAccess(url);
    }


    checkAccess(url: string): boolean {
        var isValidUser = true;
        var userTypeId = WindowRef.userIdentity.UserTypeId;
        if (userTypeId == WindowRef.lookup.user_type.admin) {
            WindowRef.appBaseUrl = "admin";
        } else {
            WindowRef.appBaseUrl = "app";
        }

        if (url.indexOf('admin') > -1 && (WindowRef.userIdentity.UserTypeId != WindowRef.lookup.user_type.admin)) {
            isValidUser = false;
        }

        if (url.indexOf('app') > -1 && ((WindowRef.userIdentity.UserTypeId != WindowRef.lookup.user_type.internal)  && (WindowRef.userIdentity.UserTypeId != WindowRef.lookup.user_type.external))) {
            isValidUser = false;
        }

        if (url.indexOf('duke') > -1 && (WindowRef.userIdentity.UserTypeId != WindowRef.lookup.user_type.duke)) {
            isValidUser = false;
        }

        return isValidUser;
    }
  
}