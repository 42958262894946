import { Component, OnInit } from "@angular/core";
import { LoggerService } from "../../shared/services/logger.service";
import { Router, ActivatedRoute } from "@angular/router";
import { WindowRef } from "../../shared/services/window.client.service";
import { ListRecordStringService } from "../common/services/list.record.string.service";
import { NotifyFlashMessageService } from "../../shared/services/notify.flash.messages.service";
import { UserService } from "./services/user.service";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";


@Component({
    selector: "user-edit",
  templateUrl: "./user.edit.component.html",
})

export class UserEditComponent implements OnInit {
    WindowRef = WindowRef;
    _isEdit: boolean = false;
    user: any = {};
    statusList: any[] = [];
    ngOnInit(): void {
        let that = this;
        that.getStatus(); 
        let userId = parseInt(this._activatedRoute.snapshot.params['id']);
        if (userId) {
            that._isEdit = true;
            that.getUser(userId);
        } else {
            that._isEdit = false;
            that.user.isChangePassword = true;
        }
    }
    getStatus() {
        var that = this;
        that._userService.getStatus().then(function (result) {
            if (result != null && result.length > 0) {
                that.statusList = result;
            }
        });
    }



    constructor(private _userService: UserService,
        private _activatedRoute: ActivatedRoute,
        private _toaster: NotifyFlashMessageService,
        private _loggerService: LoggerService,
        private _router: Router,
        private _modalService: NgbModal
    ) {

    }

    saveUser() {
        let that = this;

        if ((that.user.billToAccount == null || that.user.billToAccount == '') && (that.user.providerCategoryId == null || that.user.providerCategoryId <= 0)) {
            that._toaster.error("Please select a provider category or enter cost center!");
            return;
        }

        if (that.user.isChangePassword) {
            if (that.user.password != null && (that.user.confirmPassword == null || that.user.confirmPassword == "")) {
                that._toaster.error("Please enter confirm password!");
                return;
            }
            if (that.user.confirmPassword != null && (that.user.password == null || that.user.password == "")) {
                that._toaster.error("Please enter password!");
                return;
            }
            if (that.user.confirmPassword != that.user.password) {
                that._toaster.error("Password and Confirm Password do not match!");
                return;
            }
        }
        that._userService.Save(that.user).then(function (data) {
            if (data)
                that.navigateToList();
            else
                that._toaster.error("Unable to update user!");
        }
            , function (err) {

            });
    }
    onSelectionChange(k: any) {
        this.user.providerCategoryId = k.id;
    }


    navigateToList() {
        this._router.navigate(["/admin/user"]);
    }

    getUser(id: number) {
        let that = this;
        this._userService.GetUser(id).then(function (data) {
            that.user = data;
        }
            , function (err) {

            });
    }
}
