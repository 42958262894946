import { HttpWrapperService } from "../../../shared/services/http.wrapper.service";
import { Injectable } from "@angular/core";



@Injectable()
export class PostCardService {

    constructor(private _httpWrapper:HttpWrapperService) {

    }

    GetPreview(data:any) {
        return this._httpWrapper.post({ url: "api/order/postcard/preview", data: data });
    }



}