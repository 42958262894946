import { WindowRef } from "./window.client.service";

export class Util {
    WindowRef = WindowRef;

    static getDynamicMenuForAdmin() {
        let that = this;
        let menuArr = [
            {
                id: 1,
                name: 'IMAGES',
                type: 'dropdown',
                url: '/' + WindowRef.appBaseUrl + '/image',
                class: 'active',
                title: 'Images',
                category_type: WindowRef.lookup.product_type.image,
                category_name: 'image',
                children: [

                ]
            },
            {
                id: 2,
                name: 'Literature',
                type: 'dropdown',
                url: '/' + WindowRef.appBaseUrl + '/literature',
                title: 'Literature',
                category_type: WindowRef.lookup.product_type.literature,
                category_name: 'literature',
                children: [

                ]
            }
        ];
        menuArr = menuArr.map((item: any) => {
            if (!item.children.length) {
                item.children = Util.getLevel1Categories(item);
            }
            return item;
        });
        return menuArr;
    }

    static getDynamicMenu() {
        let that = this;
        let menuArr = [
            {
                id: 1,
                name: 'IMAGES',
                type: 'dropdown',
                url: '/' + WindowRef.appBaseUrl + '/image',
                class: 'active',
                title: 'Images',
                category_type: WindowRef.lookup.product_type.image,
                category_name: 'image',
                children: [

                ]
            },
            {
                id: 2,
                name: 'Literature',
                type: 'dropdown',
                url: '/' + WindowRef.appBaseUrl + '/literature',
                title: 'Literature',
                category_type: WindowRef.lookup.product_type.literature,
                category_name: 'literature',
                children: [

                ]
            }
            //{
            //    id: 3,
            //    name: 'BUSINESS CARDS',
            //    type: 'dropdown',
            //    url: '/app/business_card',
            //    title: "BUISNESS CARDS",
            //    category_type: null,
            //    category_name: 'business_card',
            //    children: [
            //        {
            //            id: 62,
            //            name: 'Corporate',
            //            type: 'dropdown',
            //            url: '/app/card/62',
            //            title: "Corporate",
            //            category_type: null,
            //            category_name: 'corporate',

            //        },
            //        {
            //            id: 64,
            //            name: 'Post Acute Care Sales',
            //            type: 'dropdown',
            //            url: '/app/card/64',
            //            title: "Post Acute",
            //            category_type: null,
            //            category_name: 'post_acute',
            //        },
            //        {
            //            id: 61,
            //            name: 'The Aftermarket Group',
            //            type: 'dropdown',
            //            url: '/app/card/61',
            //            title: "After Mark Group",
            //            category_type: null,
            //            category_name: 'after_market_group',

            //        },
            //        {
            //            id: 65,
            //            name: 'Rehab Sales',
            //            type: 'dropdown',
            //            url: '/app/card/65',
            //            title: "Rehab",
            //            category_type: null,
            //            category_name: 'rehab',
            //        },
            //        {
            //            id: 63,
            //            name: 'Pindot',
            //            type: 'dropdown',
            //            url: '/app/card/63',
            //            title: "Pindot",
            //            category_type: null,
            //            category_name: 'pindot',
            //        },                 
            //        {
            //            id: 66,
            //            name: 'Top End',
            //            type: 'dropdown',
            //            url: '/app/card/66',
            //            title: "Top End",
            //            category_type: null,
            //            category_name: 'top_end',
            //        }


            //    ]
            //},
            //{
            //    id: 4,
            //    name: 'PORTABLE TRADESHOWS',
            //    type: 'dropdown',
            //    url: '/app/portable_tradeshow',
            //    title: "PORTABLE TRADESHOWS",
            //    category_type: null,
            //    category_name: 'portable_tradeshow',
            //    children: [

            //    ]
            //},
            //{
            //    id: 5,
            //    name: 'MERCHANDISE',
            //    type: 'dropdown',
            //    url: '/app/merchandise',
            //    title: "MERCHANDISE",
            //    category_type: null,
            //    category_name: 'merchandise',
            //    children: [

            //    ]
            //}
        ];
        menuArr = menuArr.map((item: any) => {
            if (!item.children.length) {
                item.children = Util.getLevel1Categories(item);
            }
            return item;
        });
        return menuArr;
    }


    static getLevel1Categories(mainMenu: any) {
        let that = this;
        let menuArray: any[] = [];
        if (!WindowRef.lookupDb.category[mainMenu.category_name]) return [];
        let levelOneData = WindowRef.lookupDb.category[mainMenu.category_name].filter((item: any) => {
            return item.level == 1;
        });
        //if (mainMenu.id == 2) {
        //    levelOneData.push({
        //        id: 1,
        //        level: 1,
        //        name: "Sales Bundle",
        //        parentCategoryId: null
        //    });
        //}

        levelOneData.forEach((item: any) => {
            let l1Menu: any = Util.getMenuModel(item, mainMenu.url, mainMenu.category_type, mainMenu.category_name);
            let l1Children = Util.getLevel2Categories(item.id, l1Menu.url, l1Menu.category_type, l1Menu.category_name);
            l1Menu['children'] = l1Children;
            menuArray.push(l1Menu);
        })

        return menuArray;


    };


    static getLevel2Categories(parent_category_id: number, url: string, category_type: number, category_name: string) {
        let that = this;
        let l2MenuArray: any[] = [];
        if (!WindowRef.lookupDb.category[category_name]) return [];
        let levelTwoData = WindowRef.lookupDb.category[category_name].filter((item: any) => {
            return item.level == 2 && item.parentCategoryId == parent_category_id;
        });

        levelTwoData.forEach((item: any) => {
            let l2Menu = Util.getMenuModel(item, url, category_type, category_name);
            let l2Children = Util.getLevel3Categories(item.id, l2Menu.url, l2Menu.category_type, l2Menu.category_name);
            l2Menu['children'] = l2Children;
            l2MenuArray.push(l2Menu);
        });

        return l2MenuArray;

    };


    static getLevel3Categories(parent_category_id: number, url: string, category_type: number, category_name: string) {
        let that = this;
        let l3MenuArray: any[] = [];
        if (!WindowRef.lookupDb.category[category_name]) return [];
        let levelTwoData = WindowRef.lookupDb.category[category_name].filter((item: any) => {
            return item.level == 3 && item.parentCategoryId == parent_category_id;
        });

        levelTwoData.forEach((item: any) => {
            let l3Menu = Util.getMenuModel(item, url, category_type, category_name);
            l3MenuArray.push(l3Menu);
        });
        return l3MenuArray;

    };

    static getMenuModel(item: any, url: string, category_type: number, category_name: string): any {
        return {
            id: item.id,
            name: item.name,
            type: 'link',
            url: url + "/" + Util.encodeUrl(item.name),
            title: item.name.toUpperCase(),
            category_type: category_type,
            category_name: category_name,
            children: []
        }
    }
    static encodeUrl(name: string): string {
        return encodeURI(name.replace(/&/g, "$"));
    }

    static decodeUrl(name: string): string {
        var uri = decodeURI(name);
        console.log(uri);
        return uri.replace(/\$/g, "&");
    }

    static getCompleteUrlForCategoryId(productTypeId: number, url: string, categoryId: number) {
        var totalCategories = [];
        let categoryLevel;
        let filter = {
            level1: null,
            level2: null,
            level3: null,
        }

        let parentCategoryId: number;
        let data = {
            category: "name",
            url: url
        }

        if (productTypeId == WindowRef.lookup.product_type.literature) {
            totalCategories = WindowRef.lookupDb.category.literature;
        }

        if (productTypeId == WindowRef.lookup.product_type.image) {
            totalCategories = WindowRef.lookupDb.category.image;
        }

        var category = totalCategories.find((item: any) => {
            return item.id == categoryId;
        });



        if (!category) {
            return data;
        } else {
            categoryLevel = category.level;
            data.category = category.name;
        }


        if (categoryLevel == 3) {
            filter.level3 = category;
            filter.level2 = Util.getLevel2Category(totalCategories, filter.level3);
            filter.level1 = Util.getLevel1Category(totalCategories, filter.level2);
        }


        if (categoryLevel == 2) {
            filter.level2 = category;
            filter.level1 = Util.getLevel1Category(totalCategories, filter.level2);
        }

        if (categoryLevel == 1) {
            filter.level1 = category;
        }

        data.url = Util.getCompleteUrl(url, filter)

        return data;
    }

    static getCompleteUrl(url: string, filter: any) {

        if (filter.level1) {
            url = url + "/" + Util.encodeUrl(filter.level1.name);
        }

        if (filter.level2) {
            url = url + "/" + Util.encodeUrl(filter.level2.name);
        }

        if (filter.level3) {
            url = url + "/" + Util.encodeUrl(filter.level3.name);
        }

        return url;
    }

    static getLevel2Category(totalCategories: any[], category: any) {
        let categoryl2 = totalCategories.find((item: any) => {
            return item.id == category.parentCategoryId;
        });
        return categoryl2;
    }


    static getLevel1Category(totalCategories: any[], category: any) {
        let categoryl1 = totalCategories.find((item: any) => {
            return item.id == category.parentCategoryId;
        });
        return categoryl1;
    }




}