import { Injectable } from "@angular/core";
import { HttpWrapperService } from "../../../shared/services/http.wrapper.service";



@Injectable()
export class KeywordService {

    constructor(private _httpWrapper:HttpWrapperService) {

    }

    getAllKeywords() {
        return this._httpWrapper.get({ url: "api/util/keyword" });
    }


    saveKeyword(data:any) {
        return this._httpWrapper.post({ url: "api/util/keyword", data:data });
    }




}