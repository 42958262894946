import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { WindowRef } from '../../shared/services/window.client.service';
import { LoggerService } from '../../shared/services/logger.service';
import { Util } from '../../shared/services/util';
import { CookieService } from "ngx-cookie-service";
import { LocalStorageWrapper } from '../../shared/services/local.storage.service';
import { Subscription } from 'rxjs';
import { CartMessageService } from '../addtocart/service/cart.message.service';
import { NotifyFlashMessageService } from '../../shared/services/notify.flash.messages.service';
import { ConfirmationModelComponent } from '../../admin/common/confirmation.model.component';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactInfoComponent } from "../../admin/common/contact.info.component";

@Component({
    selector: 'nav-menu',
  templateUrl: './navmenu.component.html',
  styleUrls: ['./navmenu.component.css']
})
export class NavMenuComponent implements OnInit, OnDestroy {

    menuList: any[] = [];
    WindowRef = WindowRef;
    newItem: any;
    public href: string = "";
    cartItems: any[] = [];
    subscription: Subscription;
    url: string = "";
    searchString = "";
  changeText: boolean;
    search_filter: any = {
        filter: {
            searchString: "",
            productTypeId: null,
        }
    }

    constructor(private _activatedRoute: ActivatedRoute,
        private _loggerService: LoggerService,
        private _toaster: NotifyFlashMessageService,
        private _window: WindowRef,
        private _cookie: CookieService,
        private _modalService: NgbModal,
        private _router: Router,
        private _cartMessageService: CartMessageService, private router: Router) {
    }

    ngOnInit() {
        let that = this;
        that.menuList = Util.getDynamicMenu();
        that._loggerService.warn(that.menuList);
        that.getExistingCartItems();
        that.subscription = that._cartMessageService.getMessage().subscribe((message: any) => {
            if (message.item) {
                that.checkAndAddNewItem(message);
            } else {
                that.getExistingCartItems();
            }
        });
        this.url = this.router.url;
        if (this.url.indexOf(WindowRef.appBaseUrl + "/search") > -1) {
            this._activatedRoute.queryParams.subscribe((params: Params) => {
                that.search_filter.filter.searchString = params['searchString'].trim();
                that.search_filter.filter.productTypeId = params['productTypeId'];
                if (!that.search_filter.filter.productTypeId) {
                    that.search_filter.filter.productTypeId = WindowRef.lookup.product_type.literature;
                }
                that.searchByString();
            });
        } else {
            that.search_filter = {
                filter: {
                    searchString: "",
                    productTypeId: null,
                }
            }
        }

    }

    searchByString() {
        this._loggerService.log(this.searchString);
        this._router.navigate(["/" + WindowRef.appBaseUrl + "/search"], { queryParams: { 'searchString': this.search_filter.filter.searchString, 'productTypeId': this.search_filter.filter.productTypeId } });
    }

    ngOnDestroy(): void {
        let that = this;
        that.subscription.unsubscribe();
    }


    logout() {

        let that = this;
        var localStorageObj = new LocalStorageWrapper();

      localStorageObj.purge();
      that._cookie.delete("authTokenName");


        delete (new WindowRef()).nativeWindow['dam_user_identity'];
        delete (new WindowRef()).nativeWindow['redirect_url'];

        setTimeout(function () {
            //     that._router.navigate(['/auth/login']);
            that._window.nativeWindow.open("Home/logout", "_self")

            //that._window.nativeWindow.location.reload();
        }, 1000);
    }

    getExistingCartItems() {
        let that = this;
        var localStorageObj = new LocalStorageWrapper();
        that.cartItems = localStorageObj.getCartItems();
        for (var x = 0; x < that.cartItems.length; x++) {
            that.cartItems[x].sequence = x + 1;
        }
    }

    checkAndAddNewItem(message: any) {
        let that = this;
        var localStorageObj = new LocalStorageWrapper();
        let duplicateItem = that.cartItems.find(function (i) {
            return (message.item.id == i.id && message.item.isPdfRequired == i.isPdfRequired) &&
                ((message.item.productTypeId == WindowRef.lookup.product_type.literature) || (message.item.productTypeId == WindowRef.lookup.product_type.image));
        });

        if (duplicateItem) {
            that._toaster.warning("Item already added");
            return;
        }

        that.cartItems.push(message.item);
        localStorageObj.setCartItems(that.cartItems);
        that._toaster.success("Item successfully added to your cart");
        return;

    }

    openContactInfoModel() {
        let that = this;

        let reject = function (result: any) {

        };
        let resolve = function (data: any) {

        };
        let model = that._modalService.open(ContactInfoComponent, { size: 'lg' } as NgbModalOptions);
        // model.result.then();
        model.result.then((result: any) => {
            resolve(result);
        }, (result: any) => {
            reject(result);
        });

    }

    checkToLogout() {
        let that = this;
        let reject = function (result: any) {

        };
        let resolve = function (result: any) {
            if (result) {
                that.logout();
            }
        };

        let model = that._modalService.open(ConfirmationModelComponent, { size: 'sm' } as NgbModalOptions);
        model.componentInstance.confirmMessage = "Are you sure you want to logout!";
        model.result.then((result: any) => {
            resolve(result);
        }, (result: any) => {
            reject(result);
        });


    }

  mouseoutchangeText() {
    this.changeText = false;
  }


}
