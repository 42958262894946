import {Injectable} from '@angular/core';
import { LocalStorageWrapper } from '../shared/services/local.storage.service';
import { WindowRef } from '../shared/services/window.client.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';





@Injectable()
export class AuthInitialDataGuard {
    public canActivate(url: string): Promise<boolean> {


        let that = this;
        let isLookupsAvaialable: boolean = that.getLookupAvailability();
        let promise = new Promise<boolean>(function (resolve, reject) {
            let interval = setInterval(function () {
                let isLookupsAvaialable: boolean = that.getLookupAvailability();
                if (isLookupsAvaialable) {
                    clearInterval(interval);
                    resolve(true);
                }
            }, 100);
        });

        return promise;
    }


    private getLookupAvailability(): boolean {
        return WindowRef.lookup != null;
    }


}
