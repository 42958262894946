import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChildren, ChangeDetectorRef  } from "@angular/core";
//import { WindowRef } from "../../shared/services/window.client.service";
import { LoggerService } from "../../shared/services/logger.service";
import { NgModel, NgForm } from "@angular/forms";


@Component({
    selector: "category-component",
  templateUrl: "./category.component.html",
    styleUrls: ['../literature/literature.edit.component.css']
})
export class CategoryComponent implements OnInit{
   

    level1Categories: any[] = [];
    level2Categories: any[] = [];
    level3Categories: any[] = [];
    @Input() categoryDataList: any[] = [];
    @ViewChildren(NgModel) controls: Array<NgModel>;

    selectedCategories: any = {
        selectedLevel1CategoryId: "",
        selectedLevel2CategoryId: "",
        selectedLevel3CategoryId:""
    };

  constructor(private parentForm: NgForm, private _loggerService: LoggerService, private cdref: ChangeDetectorRef) {

    }

    ngOnInit(): void {
        let that = this; 

        that.getLevel1Categories();
    }

    ngAfterViewInit() {
        this.controls.forEach((control: NgModel) => {
            this.parentForm.addControl(control);
        });
    }
  ngAfterContentChecked() {
    
    this.cdref.detectChanges();

  }
    ngOnDestroy() {
        this.controls.forEach((control: NgModel) => {
            this.parentForm.removeControl(control);
        });
    }



    setCategories() {
        let check = true;
        let that = this;
        if (that.selectedCategories && that.selectedCategories.selectedLevel1CategoryId) {
            that.getLevel2Categories(check);
        }

        if (that.selectedCategories && that.selectedCategories.selectedLevel2CategoryId) {
            that.getLevel3Categories(check);
        }


    }

  
    getLevel1Categories() {
        let that = this;
        that.level1Categories = that.categoryDataList.filter(function (item: any) {
            return item.level == 1;
        });

        that._loggerService.log(that.level1Categories);
    }


    getLevel2Categories(check: boolean) {
        let that = this;
        that.level2Categories = that.categoryDataList.filter(function (item: any) {
            return item.level == 2 && item.parentCategoryId == that.selectedCategories.selectedLevel1CategoryId;
        });
        if (!check) {
            that.selectedCategories.selectedLevel2CategoryId = "";
            that.selectedCategories.selectedLevel3CategoryId = "";
        }
    }


    getLevel3Categories(check: boolean) {
        let that = this;
        that.level3Categories = that.categoryDataList.filter(function (item: any) {
            return item.level == 3 && item.parentCategoryId == that.selectedCategories.selectedLevel2CategoryId
        });
        if (!check) {
            that.selectedCategories.selectedLevel3CategoryId = "";
        }
    }
















}


