import { Component, ElementRef, Input, Output, EventEmitter, ViewChild, HostListener } from '@angular/core';
import { HttpWrapperService } from '../services/http.wrapper.service';
import { NotifyFlashMessageService } from '../services/notify.flash.messages.service';

@Component({
    selector: 'file-upload',
    template: '<input type="file" class="inline-block input-file" [accept]="filter" name ="file" [multiple]="multiple" #fileInput >'
})
export class FileUploadComponent {
    @Input() multiple: boolean = false;
    @Input() filter: string;
    @Input() separatorTypeId: number;
    @Output() output: EventEmitter<any> = new EventEmitter<any>();
    maxFileSize:number = 5242880;

    protected element: ElementRef;

    constructor(element: ElementRef, private _toaster: NotifyFlashMessageService, private _httpWrapper: HttpWrapperService,) {
        this.element = element;

    }

    @HostListener('change')
    public onChange() {
        let reject = function (err:any) {
            that.output.emit({ err: err, response: null });
        };
        let resolve = function (data:any) {
            that.output.emit({ err: null, data: data });
        };
        let that = this;
        let inputEl: HTMLInputElement = that.element.nativeElement.firstChild;
        let fileList = inputEl.files || [];
        let fileCount: number = fileList.length;
        let formData = new FormData();

        if (fileCount > 0) { // a file was selected
            for (let i = 0; i < fileCount; i++) {
                console.log();
                //let fileSize = fileList[i].size;
                //if (that.maxFileSize <= fileSize) {
                //    that._toaster.warning("Please upload file below 5 MB.");
                //}

                formData.append('file', fileList[i]);
                if (that.separatorTypeId) formData.append('separatorTypeId', that.separatorTypeId.toString());
            }

            this._httpWrapper.post({ url: '/api/util/file/upload', data: formData }).then(resolve,reject);
        }
    }

}
