import { Component, OnInit, ViewChild, ElementRef, Renderer } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { WindowRef } from "../../shared/services/window.client.service";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { EventBundleService } from "./services/eventbundle.service";
import { ValidationMessageService } from '../../shared/services/validation.service';
import { ConfirmationModelComponent } from "../common/confirmation.model.component";
import { NotifyFlashMessageService } from "../../shared/services/notify.flash.messages.service";
import * as _ from 'lodash';
import { NgForm } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
    selector: "edit-eventbundle",
  templateUrl: "./eventbundle.edit.component.html",
})

export class EventBundleEditComponent implements OnInit {

  @ViewChild("dtPick", { static: false }) datePicker: ElementRef;

    validationMessages: any = null;
    _isEdit: boolean = false;
    WindowRef = WindowRef;
    selectedPdfId: string = "";
    eventBundleItemList: any[] = [];
    isListEmpty: boolean = false;

    constructor(private _eventbundleService: EventBundleService,
        private _activatedRoute: ActivatedRoute,
        private _toaster: NotifyFlashMessageService,
        private _router: Router,
        private _modalService: NgbModal,
        private renderer: Renderer
    ) {
        let that = this;
        that.validationMessages = ValidationMessageService;
    }

    copyEventBundle: any = {};

    ngOnInit(): void {
        let that = this;
        let eventbundleId = parseInt(this._activatedRoute.snapshot.params['id']);
        if (eventbundleId) {
            that._isEdit = true;
            that.geteventbundle(eventbundleId);
        } else {
            that._isEdit = false;
        }
    }

    eventbundle: any = {
        id: 0,
        bundleTypeId: 0,
        name: null,
        statusId: WindowRef.lookup.product_status.active,
        formNumber: "",
        startDate: null,
        dateString: null,
        isUpdated: false,
        maximumOrderQuantity: 1,
        eventBundleList: []
    }
    datePickerOptions: any = {
        //minDate: new Date(),
    }
    focusOnDateRange() {
        let that = this;
        that.renderer.invokeElementMethod(that.datePicker.nativeElement, 'focus', []);
    }

    checkUniqueNumber() {
        let that = this;
        if (that.eventbundle.name == null || that.eventbundle.name == "")
            return;
        that._eventbundleService.CheckUniqueNumber({ Name: that.eventbundle.name, Id: that.eventbundle.id }).then(function (data) {
            if (data) {
                that._toaster.error("Please enter a unique Bundle Name");
            }
        }
            , function (err) {

            });
    }

    checkValidDate() {
        let that = this;
        if (!this.eventbundle.startDate) {
            that._toaster.error("Please enter Start Date");
            return;
        }

        if (!this.eventbundle.endDate) {
            that._toaster.error("Please enter End Date");
            return;
        }

        if (this.eventbundle.startDate > this.eventbundle.endDate) {
            that._toaster.error("Please enter valid Start Date and End Date");
            return;
        }
    }


    calculateDateString(values: any) {
        let that = this;
        let monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        //that.eventbundle.startDate = values.start.toDate();
        //that.eventbundle.endDate = values.end.toDate();
      that.eventbundle.startDate = values[0];
      that.eventbundle.endDate = values[1];

        if (that.eventbundle.startDate && that.eventbundle.endDate) {
            let str = ""
            str = monthNames[that.eventbundle.startDate.getMonth()] + " " + that.eventbundle.startDate.getDate() + ", " + that.eventbundle.startDate.getFullYear() + " - " +
                monthNames[that.eventbundle.endDate.getMonth()] + " " + that.eventbundle.endDate.getDate() + ", " + that.eventbundle.endDate.getFullYear();
            that.eventbundle.dateString = str;
        } else {
            that.eventbundle.dateString = null;
        }

    }

    saveEventBundle(form: NgForm) {
        let that = this;

        if (!this.eventbundle.startDate || !this.eventbundle.endDate) {
            that._toaster.error("Please enter Applicability Date!");
            return;
        }

        if (this.eventbundle.startDate > this.eventbundle.endDate) {
            that._toaster.error("Please enter valid Applcability Date!");
            return;
        }

        that.eventbundle.startDate = new DatePipe("en-US").transform(that.eventbundle.startDate, 'MM-dd-yyyy');
        that.eventbundle.endDate = new DatePipe("en-US").transform(that.eventbundle.endDate, 'MM-dd-yyyy');

        if (that.eventbundle.bundleTypeId == WindowRef.lookupDb.bundle_type.inside_sales_bundle
            && that.eventbundle.maximumOrderQuantity <= 0) {
            that._toaster.error("Please enter Max Order Quantity!");
            return;
        }

        that.eventbundle.eventBundleList = that.eventBundleItemList;
        that._eventbundleService.SaveEventBundle(that.eventbundle).then(function (data) {
            if (data)
                that.navigateToList();
            else
                that.resetForm(form);
        }
            , function (err) {

            });
    }

    resetForm(form: NgForm) {
        let that = this;
        that.eventbundle.name = null;
        that.eventbundle.startDate = null;
        that.eventbundle.EndDate = null;
    }
    navigateToList() {
        this._router.navigate(["/admin/eventbundle"]);
    }

    geteventbundle(id: number) {
        let that = this;
        that.eventBundleItemList = [];
        this._eventbundleService.GeteventBundle(id).then(function (data) {
            that.eventbundle = data;
            that.datePickerOptions.startDate = new DatePipe("en-US").transform(data.startDate, 'MM-dd-yyyy');
            that.datePickerOptions.endDate = new DatePipe("en-US").transform(data.endDate, 'MM-dd-yyyy');

            that.eventBundleItemList = that.eventbundle.eventBundleList;

            that.copyEventBundle = _.cloneDeep(that.eventbundle);
        }
            , function (err) {
            });
    }
    onFormNumberChange() {
        if (this.selectedPdfId != null && this.selectedPdfId != "0") {
            let that = this;
            var index = that.eventBundleItemList.findIndex(x => x.id == that.selectedPdfId);
            if (index === -1) {
                this._eventbundleService.GetLiteratureByID(this.selectedPdfId).then(function (data) {
                    that.eventBundleItemList.push(data);
                }, function (err) {
                });
            }
            else {
                alert("This item already exist");
                return false;
            }

        }
    }

    deleteItem(rowIndex: number) {
        let that = this;
        let message = "Are you sure you want to remove this item! you will not be able to undo it.";
        let model = that._modalService.open(ConfirmationModelComponent, { size: 'sm' } as NgbModalOptions);
        model.componentInstance.confirmMessage = message;
        model.result.then((result: any) => {
            if (result == true) {
                that.eventBundleItemList.splice(rowIndex, 1);
            }
        });
    }

    IsEmptyList() {
        let that = this;
      if (that.eventbundle.name != null && that.eventbundle.bundleTypeId != 0) //&& that.eventBundleItemList.length != 0
            return false;
        return true;

    }
}
