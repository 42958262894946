import { Component, OnInit } from '@angular/core';
import { LoggerService } from '../../shared/services/logger.service';
import { PortalImageService } from '../../admin/portal_image/services/portal.image.service';
import { LiteratureDetailService } from '../literature/services/literature.detail.service';
import { OrderItemCreatorService } from '../addtocart/service/order.item.creator.service';
import { Util } from '../../shared/services/util';
import { WindowRef } from '../../shared/services/window.client.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SearchService } from './service/search.service';
import { ListRecordStringService } from '../../admin/common/services/list.record.string.service';



@Component({
    selector: 'search',
  templateUrl: './search.component.html',
    styleUrls: ['./search.component.css', '../footer/footer.component.css','../literature/literature.sub.category.list.component.css','../image/image.sub.category.list.component.css']
})
export class SearchComponent extends  ListRecordStringService implements OnInit {
    WindowRef = WindowRef;
    resultList: any[] = [];
    totalCount: number = 0;
    totalRecordsInLiterature: number = 0;
    totalRecordsInImage: number = 0;
    showMessage: boolean = false;

    search_filter: any = {
        filter: {
            searchString: "",
            productTypeId: null,
        },
        pageSize: 12,
        pageNumber: 1
    }

    constructor(private _activatedRoute: ActivatedRoute,
        private _literatureSubCategoryListService: LiteratureDetailService,
        private _portalImageService: PortalImageService,
        private _searchService:SearchService,
        public orderItemCreatorService: OrderItemCreatorService,
        private _router: Router,
        private _logger: LoggerService) {
        super();
        
    }

    ngOnInit(): void {
        let that = this;
        this._activatedRoute.queryParams.subscribe((params: Params) => {
            that.search_filter.filter.searchString = params['searchString'].trim();
            that.search_filter.filter.productTypeId = params['productTypeId'];
            if (!that.search_filter.filter.productTypeId) {
                that.search_filter.filter.productTypeId = WindowRef.lookup.product_type.literature;
            }
            that.getSearchResult('');
        });
    }


  getSearchResult(pc: string) {
      let that = this;
      if (pc != "pagechange") {this.search_filter.pageNumber = 1;}
        that._logger.log(that.search_filter);
        that._searchService.GetSearchResultList(that.search_filter).then(function (data: any) {
            console.log(data);
            that.resultList = data.collection;
            that.totalRecordsInImage = data.totalRecordsInImage;
            that.totalRecordsInLiterature = data.totalRecordsInLiterature;
            that.calculateRecordString(data, that.search_filter);
            that.mapSearchResultCategoryLink();
            if (that.search_filter.filter.productTypeId == WindowRef.lookup.product_type.literature) {
                that.totalCount = data.totalRecordsInLiterature;
            } else {
                that.totalCount = data.totalRecordsInImage;
            }

            if (data.totalRecordsInLiterature <= 0 && data.totalRecordsInImage > 0) {
                that.applyProductTypeFilter(WindowRef.lookup.product_type.image);
            }
            that.showMessage = true;

        });
    }


    mapSearchResultCategoryLink() {
        let that = this;
        let type = WindowRef.lookup.product_type.image
        let startLink = '#/' + this.WindowRef.appBaseUrl + '/image'
        if (that.search_filter.filter.productTypeId == WindowRef.lookup.product_type.literature) {
            type = WindowRef.lookup.product_type.literature;
            startLink = '#/' + this.WindowRef.appBaseUrl +'/literature';
        }
        that.resultList.map(function (item:any) {
            let data: any = Util.getCompleteUrlForCategoryId(type, startLink, item.categoryId);
            item.category = data.category;
            item.url = data.url;
            return item;
        });

    }

    applyProductTypeFilter(productTypeId:any) {
        let that = this;
        that.search_filter.filter.productTypeId = productTypeId;
        that.searchByString();
    }

    quantitySelected(value: number, literature: any) {
        literature.orderQuantity = value;
    }

    searchByString() {
        this._router.navigate(["/" + this.WindowRef.appBaseUrl+"/search"], { queryParams: { 'searchString': this.search_filter.filter.searchString, 'productTypeId': this.search_filter.filter.productTypeId } });
    }


}
