import { FileUploadComponent } from "./components/file.upload.component";
import { ImageUploadComponent } from "./components/image.upload.component";
import { CustomUploadComponent } from "./components/custom.upload.component";



export class Shared {


    static getAllComponents(): any {
        return [
            FileUploadComponent,
            ImageUploadComponent,
            CustomUploadComponent
        ]
    }


    //static getAllPaths(): any {
    //    return {
    //        path: 'app', component: MarketingComponent, children: [
    //            { path: '', component: HomeComponent, data: { title: 'Dashboard' } },
    //            { path: 'home', component: HomeComponent, data: { title: 'Dashboard' } },
    //            { path: 'fetch-data', component: FetchDataComponent },
    //            { path: '**', redirectTo: 'home' }
    //        ]
    //    }

    //}

}
