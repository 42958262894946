import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { WindowRef } from '../../shared/services/window.client.service';
import { LoggerService } from '../../shared/services/logger.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Util } from '../../shared/services/util';
import { ImageDetailService } from './service/image.detail.service';
// import { NgxCarousel } from 'ngx-carousel';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { OrderItemCreatorService } from '../addtocart/service/order.item.creator.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
    selector: 'image-detail',
  templateUrl: './image.detail.component.html',
  styleUrls: ['./image.detail.component.css']
})
export class ImageDetailComponent implements OnInit {
    WindowRef = WindowRef;
    subscribedRouterEvent: any = null;
    literatureId: number;

    imageDetail: any = {
        id: null,
        name: null,
        categoryId: null,
        imageNumber: null,
        originalFile: {},       
        statusId: null,
        activationDate: null,
        keywords: [],
        relatedImages: [],
        relatedLiteratures: []     
    };

    requestUrlDetail: any = {
        level1: null,
        level2: null,
        level3: null,
    };


    requestVisibleDetail: any = {
        level1: null,
        level2: null,
        level3: null,
    }

    constructor(
        private _imageDetailService:ImageDetailService,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _location: Location,
        public orderItemCreatorService: OrderItemCreatorService,
        private _loggerService: LoggerService) {

    }

    back() {
        this._location.back();
    }

    customOptions: OwlOptions = {
        loop: false,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        dots: false,
        navSpeed: 700,
      navText: ['<i class="fa fa-arrow-left" aria-hidden="true"></i>', '<i class="fa fa-arrow-right" aria-hidden="true"></i>'],
        responsive: {
          0: {
            items: 1
          },
          400: {
            items: 2
          },
          740: {
            items: 3
          },
          940: {
            items: 4
          }
        },
        nav: true
      }



    ngOnInit() {
        let that = this;
        that.subscribedRouterEvent = this._activatedRoute.paramMap.subscribe(params => {
            that._loggerService.log(params);
            that.parseRequestDetail(params);
        });
        that._loggerService.log("ImageDetailComponent initialized");  
    }

    getKeywordSearchResult(value: any) {
        this._router.navigate(["/" + WindowRef.appBaseUrl + "/search"], { queryParams: { 'searchString': value, 'productTypeId': WindowRef.lookup.product_type.image } });
    }
    mapImageSizeChecks() {
        let that = this;
    
        that.imageDetail.isJPGFormatedRequired = false;
        that.imageDetail.isPNGFormatedRequired = false;
        that.imageDetail.isOriginalSizeRequired = false;
        that.imageDetail.relatedImages.map(function (item: any) {
            item.isJPGFormatedRequired = false;
            item.isPNGFormatedRequired = false;
            item.isOriginalSizeRequired = false;
            return item;
        });
        console.log(that.imageDetail);
    }


    parseRequestDetail(params?: any): any {
        let that = this;
        params = params || that._activatedRoute.snapshot.params;

        that.literatureId = params.get('id') || null,
        that.getDetail(that.literatureId);
        that._loggerService.log(that.literatureId);
    }

    getDetail(id:number) {
        let that = this;
        that._imageDetailService.GetImageDetail(id).then(function (data) {
            that.imageDetail = data
            that.calculateBreadCrumCategory(data.categoryId);
            that.mapCategoryLink();
        });  
    }

    mapCategoryLink() {
        let that = this;
        that.imageDetail.relatedLiteratures.map(function(item:any) {
            let data: any = Util.getCompleteUrlForCategoryId(WindowRef.lookup.product_type.literature, '#/' + WindowRef.appBaseUrl+'/literature', item.categoryId);
            item.category = data.category;
            item.url = data.url;
            return item;
        });


        that.imageDetail.relatedImages.map(function (item:any) {
            let data: any = Util.getCompleteUrlForCategoryId(WindowRef.lookup.product_type.image, '#/' + WindowRef.appBaseUrl+'/image', item.categoryId);
            item.category = data.category;
            item.url = data.url;
            return item;
        });

    }

    calculateBreadCrumCategory(categoryId: number) {
        let that = this;
        let categoryWithLevel = WindowRef.lookupDb.category['image'].find(function (item: any) {
            return item.id == categoryId;
        });


        if (!categoryWithLevel) return;

        if (categoryWithLevel.level == 3) {
            that.getAndPopulateCategoryForLevel3(categoryWithLevel);
        }

        if (categoryWithLevel.level == 2) {
            that.getAndPopulateCategoryForLevel2(categoryWithLevel);
        }

        if (categoryWithLevel.level == 1) {
            that.getAndPopulateCategoryForLevel1(categoryWithLevel);
        }
    }



    getAndPopulateCategoryForLevel3(category: any) {
        let that = this;
        that.requestUrlDetail.level3 = Util.encodeUrl(category.name);
        that.requestVisibleDetail.level3 = category.name;
        let parentCategory = WindowRef.lookupDb.category['image'].find(function (item: any) {
            return item.id == category.parentCategoryId;
        });
        that.getAndPopulateCategoryForLevel2(parentCategory);
    }

    getAndPopulateCategoryForLevel2(category: any) {
        let that = this;
        that.requestUrlDetail.level2 = Util.encodeUrl(category.name);
        that.requestVisibleDetail.level2 = category.name;
        let parentCategory = WindowRef.lookupDb.category['image'].find(function (item: any) {
            return item.id == category.parentCategoryId;
        });
        that.getAndPopulateCategoryForLevel1(parentCategory);
    }

    getAndPopulateCategoryForLevel1(category: any) {
        let that = this;
        that.requestUrlDetail.level1 = Util.encodeUrl(category.name);
        that.requestVisibleDetail.level1 = category.name;
    }

    quantitySelected(value: number, literature: any) {
        literature.orderQuantity = value;
    }


}
