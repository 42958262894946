import { Inject, Injectable } from '@angular/core';


// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/catch';
import { HttpWrapperService } from '../../../shared/services/http.wrapper.service';
import { NotifyFlashMessageService } from '../../../shared/services/notify.flash.messages.service';

@Injectable()
export class UserService {

    constructor(
        @Inject(HttpWrapperService) private _httpWrapper: HttpWrapperService, private _toaster: NotifyFlashMessageService
    ) { }


    GetUserList(data: any) {
        return this._httpWrapper.post({
            url: 'api/user/user/list',
            data: data
        });
    }

    GetUser(id: number) {
        return this._httpWrapper.get({ url: 'api/user/user/' + id });
    }

    SaveUser(data: any) {
        return this._httpWrapper.post({ url: 'api/user/user', data: data });
    }

    getStatus() {
        return this._httpWrapper.get({ url: '/api/util/lookup/status' });
    }

    getShippingInfo() {
        return this._httpWrapper.get({ url: 'api/user/user/shipping' });
    }

    Save(data: any) {
        let that = this;
        return this._httpWrapper.put({
            url: 'api/user/user/', data: data, success: function (deffered: any, result: any, def: any) {
                var msg = '';
                if (def.showOnSuccess && result.messageType == 3) {
                    that._toaster.error(result.message);
                }

                return deffered.resolve(result.data);
            } });
    }
    DeleteUser(id: number) {
        return this._httpWrapper.delete({ url: 'api/user/user/' + id });
    }
}