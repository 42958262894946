import { HttpWrapperService } from "../../../shared/services/http.wrapper.service";
import { Injectable } from "@angular/core";



@Injectable()
export class BusinessCardService {

    constructor(private _httpWrapper:HttpWrapperService) {

    }

    GetPreview(data:any) {
        return this._httpWrapper.post({ url: "api/order/businesscard/preview", data: data });
    }

    Update(data: any) {
        return this._httpWrapper.post({ url: "api/order/order/updatebusinesscard",data});
    }



}