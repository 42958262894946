import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { WindowRef } from '../../shared/services/window.client.service';
import { LoggerService } from '../../shared/services/logger.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Util } from '../../shared/services/util';
import { CarouselModule } from 'ngx-bootstrap/carousel';

import { ValidationMessageService } from '../../shared/services/validation.service';
import { NgForm } from '@angular/forms';
import { BusinessCardService } from '../../marketing/businesscard/services/businesscard.service';
import { UserOrderService } from '../../marketing/addtocart/service/user.order.service';
import domtoimage from 'dom-to-image';


@Component({
    selector: 'admin-businesscard-add',
  templateUrl: '../../marketing/businesscard/businesscard.component.html',
  styleUrls: ['../../marketing/businesscard/businesscard.component.css']
})
export class AdminBuisnessCardEditComponent implements OnInit {


  @ViewChild('custom_card', { static: false }) custom_card: ElementRef;

    validationMessages: any = null;
    WindowRef = WindowRef;
    isForEdit: boolean = true;
    subscribedRouterEvent: any = null;
    typeOfCard: string = "";
    cardTypeId = null;
    cardId: number = 0;
    isValidcostCenterOnCard = false;
    buisnessCardDetail: any = {
        id: null,
        typeId: null,
        name: "",
        nameOnCard: null,
        titleOnCard: null,
        emailOnCard: null,
        phoneOnCard: { phone1: null, phone2: null, phone3: null },
        mobileOnCard: { phone1: null, phone2: null, phone3: null },
        addressOnCard: "",
        addressObj: {},
        costCenterOnCard: "",
        costCenter: { costCenter1: null, costCenter2: null, costCenter3: null, costCenter4: null, costCenter5: '.4610.05450' },
        departmentOnCard: null,
        HTML: null,
        file: null,
        base64Url: null,
        orderQuantity: null,
        minOrderQuantity: 250,
        maximumOrderQuantity: 500
    }

    mainItemModel: any;

    constructor(private _activatedRoute: ActivatedRoute, private _router: Router,

        private _businessCardService: BusinessCardService,
        private _orderService: UserOrderService,

        private _loggerService: LoggerService) {
        let that = this;
        that.validationMessages = ValidationMessageService;
    }

    ngOnInit() {
        console.log(domtoimage);
        let that = this;
        that.cardId = that._activatedRoute.snapshot.params['id'];
        that.getCard();
    }

    getCard() {
        let that = this;
        that._orderService.GetOrderItem(that.cardId).then(function (result) {
            that.mainItemModel = result;
            that.buisnessCardDetail = that.mainItemModel.businessCardDetail;
            that.fillCostCenter();
            that.parseRequestDetail();
        })
    }

    fillCostCenter() {
        let that = this;
        that.buisnessCardDetail.costCenter = { costCenter1: null, costCenter2: null, costCenter3: null, costCenter4: null, costCenter5: '.4610.05450' };
        if (that.buisnessCardDetail.costCenterOnCard != null) {
            var costCenterList = that.buisnessCardDetail.costCenterOnCard.split(".");
            that.buisnessCardDetail.costCenter.costCenter1 = costCenterList.length > 0 ? costCenterList[0] : '';
            that.buisnessCardDetail.costCenter.costCenter2 = costCenterList.length > 1 ? costCenterList[1] : '';
            that.buisnessCardDetail.costCenter.costCenter3 = costCenterList.length > 2 ? costCenterList[2] : '';
            that.buisnessCardDetail.costCenter.costCenter4 = costCenterList.length > 3 ? costCenterList[3] : '';
            that.buisnessCardDetail.costCenter.costCenter5 = (costCenterList.length > 4 ? "." + costCenterList[4] : '')
                + (costCenterList.length > 5 ? "." + costCenterList[5] : '');
        }
    }

    parseRequestDetail(): any {
        let that = this;

        that.onAddressSelect();
        that.cardTypeId = that.buisnessCardDetail.typeId;

        var card = WindowRef.lookupDb.buisness_card_type.find(function (item: any) {
            return item.id == that.cardTypeId;
        });
        if (!card) {
            that.typeOfCard = "";
        } else {
            if (that.cardTypeId == WindowRef.lookup.buisness_card_type.after_market_group) {
                that.typeOfCard = "The Aftermarket Group Business Card"
            }
            if (that.cardTypeId == WindowRef.lookup.buisness_card_type.corporate) {
                that.typeOfCard = "Corporate Business Card"
            }
            if (that.cardTypeId == WindowRef.lookup.buisness_card_type.pindot) {
                that.typeOfCard = "Pindot Business Card"
            }
            if (that.cardTypeId == WindowRef.lookup.buisness_card_type.post_acute) {
                that.typeOfCard = "Post Acute Care Sales Business Card"
            }
            if (that.cardTypeId == WindowRef.lookup.buisness_card_type.rehab) {
                that.typeOfCard = "Rehab Sales Business Card"
            }
            if (that.cardTypeId == WindowRef.lookup.buisness_card_type.top_end) {
                that.typeOfCard = "Top End Business Card"
            }
        }
        that.buisnessCardDetail.name = that.typeOfCard;
        that.buisnessCardDetail.typeId = that.cardTypeId;

        that._loggerService.log(that.cardTypeId);
    }

    preViewCard() {
        let that = this;
        that.createHtmlPreview();
    }

    createHtmlPreview() {
        let that = this;
        domtoimage.toPng(this.custom_card.nativeElement)
            .then(function (dataUrl: any) {
                that.buisnessCardDetail.base64Url = dataUrl;

                that._businessCardService.GetPreview(that.buisnessCardDetail).then(function (data) {
                    that.buisnessCardDetail.file = data;
                    that.openPreview(data);
                    that._loggerService.log(data);
                }, function (err) {
                    that._loggerService.log(err);
                });
            })
            .catch(function (error: any) {
                console.error('oops, something went wrong!', error);
            });
    }

    openPreview(data: any) {
        if (data) {
            window.open("/api/util/file?path=" + data.relativePath + "&name=" + data.name + "&contentType=" + data.mimeType, "_blank");
        }
    }

    onAddressSelect() {
        let that = this;

        that.buisnessCardDetail.addressObj = WindowRef.lookupDb.i_addresses.find(function (item: any) {
            return that.buisnessCardDetail.addressOnCard == item.fullAddress;
        });
    }

    costCenterValidation() {
        let that = this;

        var costCenter1 = that.buisnessCardDetail.costCenter.costCenter1 != null ? that.buisnessCardDetail.costCenter.costCenter1 : '';
        var costCenter2 = that.buisnessCardDetail.costCenter.costCenter2 != null ? '.' + that.buisnessCardDetail.costCenter.costCenter2 : '';
        var costCenter3 = that.buisnessCardDetail.costCenter.costCenter3 != null ? '.' + that.buisnessCardDetail.costCenter.costCenter3 : '';
        var costCenter4 = that.buisnessCardDetail.costCenter.costCenter4 != null ? '.' + that.buisnessCardDetail.costCenter.costCenter4 : '';
        var costCenter5 = that.buisnessCardDetail.costCenter.costCenter5

        that.buisnessCardDetail.costCenterOnCard = that.buisnessCardDetail.costCenter != null
            ? (costCenter1 + costCenter2 + costCenter3 + costCenter4 + costCenter5) : null;

        var obj = WindowRef.lookupDb.i_business_cost_centers.filter(function (item: any) {
            return item.value == that.buisnessCardDetail.costCenterOnCard;
        });
        if (obj == null || obj.length <= 0) {
            that.isValidcostCenterOnCard = true;
        } else {
            that.isValidcostCenterOnCard = false;
        }
    }

    save() {
        // let that = this;
        // domtoimage.toPng(this.custom_card.nativeElement)
        //     .then(function (dataUrl: any) {
        //         that.mainItemModel.base64Url = dataUrl;

        //         that._businessCardService.Update(that.mainItemModel).then(function (res) {
        //             that._router.navigate(['/admin/order/manage']);
        //         });
        //     })
        //     .catch(function (error: any) {
        //         console.error('oops, something went wrong!', error);
        //     });


        let that = this;
        // let scale = 1.5;
        // const BASE_DPI = 72;
        // const scale = 9;
        domtoimage.toPng(this.custom_card.nativeElement,
            {
                height: this.custom_card.nativeElement.offsetHeight * 15,
                width: this.custom_card.nativeElement.offsetWidth * 15,
                style: {
                    transform: `scale(${15})`,
                    transformOrigin: 'top left',
                    width: `${this.custom_card.nativeElement.offsetWidth}px`,
                    height: `${this.custom_card.nativeElement.offsetHeight}px`
                }
            })
            .then(function (dataUrl: any) {
                // dataUrl =  changeDpiDataUrl(dataUrl, BASE_DPI * scale)
                that.mainItemModel.base64Url = dataUrl;
                that._businessCardService.Update(that.mainItemModel).then(function (res) {
                that._router.navigate(['/admin/order/manage']);
                });
            })
            .catch(function (error: any) {
                console.error('oops, something went wrong!', error);
            });            
    }
}
