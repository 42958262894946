import {Component, OnInit} from '@angular/core';
import { LocalStorageWrapper } from '../../shared/services/local.storage.service';
import { WindowRef } from '../../shared/services/window.client.service';
import { CartMessageService } from './service/cart.message.service';
import { Subscription } from 'rxjs';
@Component({
    selector: 'order-detail',
  templateUrl: './order.detail.component.html',
    styleUrls: ['./order.detail.component.css']
})
export class OrderDetailComponent implements OnInit {
    WindowRef = WindowRef;
    cartItems: any[] = [];
    subscription: Subscription;
    private isCollapsed: boolean;
    private isCollapsed1: boolean;
   
    constructor(private _cartMessageService:CartMessageService) {


    }


    ngOnInit() {
  
    }












}
