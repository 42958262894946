export interface IDropdownListItemModel {
  text: string,
  value: string,
  selected: boolean
}

export class SelectedAutocompleteValue {
  text: string;
  value: string;
}
