import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'logout',
    template: './logout.component.html'
})
export class LogoutComponent {
    constructor(private router: Router) {
        let that = this;
    }

    //login(): void {
    //    var that = this;
    //    that.router.navigate(['auth/login']);
    //}
    
}
