import { FooterComponent } from "./footer/footer.component";
import { DukeComponent } from "./duke.component";
import { OrderListComponent } from "./order/order.list.component";
import { DukeNavMenuComponent } from "./navmenu/duke-navmenu.component";
import { AuthGuard } from "../auth/auth.role.service";
import { DukeHomeComponent } from "./home/duke.home.component";

export class Duke {
    static getAllComponents(): any {
        return [
            DukeComponent,
            FooterComponent,
            OrderListComponent,
            DukeNavMenuComponent,
            DukeHomeComponent,
        ]
    }


    static getAllPaths(): any {
        return {
            path: 'duke', component: DukeComponent, canActivate: [AuthGuard], children: [

                { path: 'order/list', component: OrderListComponent },
                { path: 'home', component: DukeHomeComponent },
                { path: 'order/list/:id', component: OrderListComponent }
            ]
        }

    }

}
