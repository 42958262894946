import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NotifyFlashMessageService } from '../../shared/services/notify.flash.messages.service';
import { ForgotService } from '../forgot/service/forgot.service';

@Component({
    selector: 'forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent {
    infoObj: any = {
        email: '',
    };

    constructor(private router: Router,
        private _toaster: NotifyFlashMessageService,
        private _forgotPasswordService: ForgotService,
    ) {
    }

    sendLink() {
        let that = this;
        if (that.infoObj.email == null) {
            that._toaster.error("Please enter email-id!");
            return;
        }
        that._forgotPasswordService.forgotPassword(that.infoObj.email).then(function (data) {
            if (data) {
                that._toaster.success("Link has been sent successfully. Please check your email.");
                setTimeout(function () {
                    that.router.navigate(['/auth/login']);
                }, 3000);
            }
        })
    };

}
