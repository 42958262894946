import { Component } from '@angular/core';
import { LoggerService } from '../../shared/services/logger.service';



@Component({
    selector: 'duke-home',
  templateUrl: './duke.home.component.html',
  styleUrls: ['./duke.home.component.css']
})
export class DukeHomeComponent {
    constructor(private _logger: LoggerService) { }

    success(message: string) {

    }

    //fileUploaded(responseObj: any) {
    //    this._logger.log(responseObj);
    //}
}
