import { Component, OnInit } from '@angular/core';

import { WindowRef } from '../../shared/services/window.client.service';

import { LoggerService } from '../../shared/services/logger.service';

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PendingOrderService } from './pending.order.service';
import { NotifyFlashMessageService } from '../../shared/services/notify.flash.messages.service';
import { ConfirmationModelComponent } from '../../admin/common/confirmation.model.component';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminAddressDistributionComponent } from './admin.order.distribution.component';

@Component({
    selector: 'admin-edit-order',
  templateUrl: './admin.edit.order.component.html',
  styleUrls: ['../../duke/order/order.detail.component.css']
})
export class AdminEditOrderComponent implements OnInit {
    WindowRef = WindowRef;
    totalCount: number = 0;
    orderList: any[] = [];
    activeIndex: number = 0;
    orderId: number;
    public daterange: any = {};
    file: any;
    total: number = 0;
    selected: number = 0;

    public options: any = {
        locale: { format: 'YYYY-MM-DD' },
        alwaysShowCalendars: false,
    };
    orderStatusList: any[] = [];

    public selectedDate(value: any, datepicker?: any) {
        datepicker.start = value.start;
        datepicker.end = value.end;

        this.daterange.start = value.start;
        this.daterange.end = value.end;
        this.daterange.label = value.label;
    }


    orderDetail: any = {
        cartItems: [],
        shippingDetail: {}
    };

    copyOrderDetail: any = {
        cartItems: [],
        shippingDetail: {}
    }



    constructor(private _pendingOrderService: PendingOrderService,
        private _loggerService: LoggerService,
        private _toaster: NotifyFlashMessageService,
        private _modelService: NgbModal, private _activatedRoute: ActivatedRoute, private _router: Router
    ) {

        this.orderStatusList = this.WindowRef.lookupDb.order_status.filter((x: any) =>
            x.id == WindowRef.lookup.order_status.approval_pending || x.id == WindowRef.lookup.order_status.rejected
            || x.id == WindowRef.lookup.order_status.confirmed);

        this.orderId = _activatedRoute.snapshot.params['id'];
    }
    ngOnInit() {
        let that = this;
        that.getOrderDetail(this.orderId);
    }


    getOrderDetail(id: number) {
        let that = this;

        that._pendingOrderService.GetOrder(id).then(function (data: any) {
            that.orderDetail = data;
            that.mapOrderStatusString();
            that.copyOrderDetail = _.cloneDeep(that.orderDetail);
            that._loggerService.log(data);
        })
    }


    saveOrder() {
        let that = this;
        if (that.isInValidDistributeAddress()) return;

        that._pendingOrderService.ModifyOrder(that.orderDetail).then(function (data: any) {
            that._loggerService.log(data);
            that._router.navigate(['/admin/order/manage']);

        });
    }

    changeOrderQuantityInDistribution(currentItem: any) {
        let that = this;
        if (that.orderDetail.addressDistribution.collection.length <= 0) return;

        that.orderDetail.addressDistribution.collection.forEach(function (address: any) {
            address.cartItems.forEach(function (cartItem: any) {
                if (cartItem.id == currentItem.id) {
                    cartItem.orderQuantity = currentItem.orderQuantity;
                }
            });
        });
    }

    cancel() {
        let that = this;

        that._router.navigate(['/admin/order/manage']);
    }

    mapOrderStatusString() {
        let that = this;
        that.orderDetail.cartItems.map(function (item: any) {
            var orderStatus = WindowRef.lookupDb.order_status.find(function (i: any) {
                return i.id == item.statusId;
            });

            var productType = WindowRef.lookupDb.product_type.find(function (i: any) {
                return i.id == item.productTypeId;
            });

            item.status = orderStatus && orderStatus.name || null;
            item.productType = productType && productType.name || null;
            return item;
        });
    }
    openModel() {
        let that = this;

        let reject = function (result: any) {

        };
        let resolve = function (data: any) {

        };
        let model = that._modelService.open(AdminAddressDistributionComponent, { size: 'lg' } as NgbModalOptions);
        (model.componentInstance).distributionlist = that.orderDetail.addressDistribution.collection;
        (model.componentInstance).cartItems = that.orderDetail.cartItems;
        model.result.then((result) => {
            resolve(result);
        }, (result) => {
            reject(result);
        });

        return model;
    }


    isInValidDistributeAddress() {
        let that = this;
        var invalidDistribution = false;
        that.orderDetail.cartItems.forEach(function (item: any) {
            if (!invalidDistribution) {
                var result = that.checkDistributionQuantity(item);
                if (!result) {
                    that._toaster.error("Quantity distribution is not equal to total quantity!");
                    invalidDistribution = true;
                }
            }
        });

        return invalidDistribution;
    }

    checkDistributionQuantity(item: any) {
        let that = this;
        that.total = 0;
        that.selected = 0;
        that.orderDetail.addressDistribution.collection.forEach(function (address: any) {
            address.cartItems.forEach(function (cartItem: any) {
                if (cartItem.id == item.id) {
                    that.total = cartItem.orderQuantity;
                    that.selected = (+that.selected) + (+cartItem.quantity);
                }
            });
        });
        if (that.total == null || that.total <= 0)
            return true;
        else if (that.total == that.selected)
            return true;
        else
            return false;
    }



    quantitySelected(value: number, item: any) {
        let that = this;
        item.orderQuantity = value;
        that.changeOrderQuantityInDistribution(item);
    }

    revertChangedStatus() {
        let that = this;
        that.orderDetail.statusId = that.copyOrderDetail.statusId;
    }

    deleteItem(currentItem: any, index: any) {
        let that = this;
        let message = "Are you sure you want to delete this item! you will not be able to undo it.";
        let model = that._modelService.open(ConfirmationModelComponent, { size: 'sm' } as NgbModalOptions);
        model.componentInstance.confirmMessage = message;
        model.result.then((result: any) => {
            if (result == true) {
                that.orderDetail.cartItems.splice(index, 1);

                if (that.orderDetail.addressDistribution.collection.length <= 0) return;

                that.orderDetail.addressDistribution.collection.forEach(function (address: any) {
                    var cartItemIndex = -1;
                    address.cartItems.forEach(function (cartItem: any) {
                        cartItemIndex = cartItemIndex + 1;
                        if (cartItem.id == currentItem.id) {
                            address.cartItems.splice(cartItemIndex, 1);
                        }
                    });
                });
            }
        }, (result: any) => {
        });
    }
}
