import { DashboardComponent } from "./dashboard/dashboard.component";
import { AdminComponent } from "./admin.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { LiteratureEditComponent } from "./literature/literature.edit.component";
import { LiteratureListComponent } from "./literature/literature.list.component";
import { CategoryComponent } from "./common/category.component";
import { KeywordsComponent } from "./image/keywords/keywords.component";
import { RelatedProductsComponent } from "./image/relatedproducts/related.products.component";
import { UploadImageComponent } from "./image/upload-image/upload.image.component";
import { EventBundleEditComponent } from "./eventbundle/EventBundle.edit.component";
import { EventBundleListComponent } from "./eventbundle/EventBundle.list.component";
import { EventBundleDetailComponent } from "./eventbundle/eventbundle.detail.component";
import { SalesBundleDetailComponent } from "./eventbundle/salesBundle.detail.component";

import { ImageManagementComponent } from "./image/image.management.component";
import { ConfirmationModelComponent } from "./common/confirmation.model.component";
import { ManageKeywordModelComponent } from "./common/manage.keyword.model.component";
import { ImageListComponent } from "./image/image.list.component";
import { ImageEditComponent } from "./image/image.edit.component";
import { AuthGuard } from "../auth/auth.role.service";
import { PortalImageEditComponent } from "./portal_image/portal.image.edit.component";
import { PortalImagePreviewComponent } from "./portal_preview/portal.image.preview.component";

import { ListUserComponent } from "./user/list.user.component";
import { UpdateStatusComponent } from "./user/update.status.component";
import { UserEditComponent } from "./user/user.edit.component";
import { FetchDataComponent } from "../marketing/fetchdata/fetchdata.component";
import { CartDetailComponent } from "../marketing/addtocart/cart.detail.component";
import { LiteratureDetailComponent } from "../marketing/literature/literature.detail.component";
import { LiteratureSubCategoryListComponent } from "../marketing/literature/literature.sub.category.list.component";
import { ImageDetailComponent } from "../marketing/image/image.detail.component";
import { ImageSubCategoryListComponent } from "../marketing/image/image.sub.category.list.component";
import { BuisnessCardComponent } from "../marketing/businesscard/businesscard.component";
import { PostCardComponent } from "../marketing/postcard/postcard.component";
import { OrderDetailComponent } from "../marketing/addtocart/order.detail.component";
import { UserOrderListComponent } from "../marketing/addtocart/user.order.list.component";
import { SearchComponent } from "../marketing/search/search.component";
import { UserManagementComponent } from "./user/user.management.component";
import { ContactInfoComponent } from "./common/contact.info.component";
import { PendingOrderListComponent } from "./order/pending.order.list.component";
import { AdminEditOrderComponent } from "./order/admin.edit.order.component";
import { AdminAddressDistributionComponent } from "./order/admin.order.distribution.component";
import { AdminBuisnessCardEditComponent } from "./order/admin.businesscard.edit.component";
import { ActivityListComponent } from "./activityReporting/activity.list.component";
import { ActivityDetailComponent } from "./activityReporting/activity.detail.component";
import { TermsConditionsComponent } from "./common/terms.conditions.component";
import { CustomAutocompleteComponent } from "./common/custom-autocomplete.component";

export class Admin {
    public static getAllComponents(): any {
        return [
            AdminComponent,
            HeaderComponent,
            FooterComponent,
            DashboardComponent,
            ConfirmationModelComponent,
            LiteratureEditComponent,
            LiteratureListComponent,
            EventBundleEditComponent,
            EventBundleListComponent,
            ImageManagementComponent,
            ImageListComponent,
            ImageEditComponent,
            ManageKeywordModelComponent,
            CategoryComponent,
            KeywordsComponent,
            RelatedProductsComponent,
            UploadImageComponent,
            PortalImageEditComponent,
            PortalImagePreviewComponent,
            ListUserComponent,
            UpdateStatusComponent,
            UserEditComponent,
            FetchDataComponent,
            CartDetailComponent,
            LiteratureDetailComponent,
            LiteratureSubCategoryListComponent,
            ImageDetailComponent,
            ImageSubCategoryListComponent,
            BuisnessCardComponent,
            PostCardComponent,
            OrderDetailComponent,
            UserOrderListComponent,
            SearchComponent,
            UserManagementComponent,
            ContactInfoComponent,
            PendingOrderListComponent,
            AdminEditOrderComponent,
            AdminAddressDistributionComponent,
            AdminBuisnessCardEditComponent,
            ActivityListComponent,
            ActivityDetailComponent,
            TermsConditionsComponent,
            CustomAutocompleteComponent,
            EventBundleDetailComponent,
            SalesBundleDetailComponent
        ];
    };

    public static getAllPaths() {

        return {
            path: "admin", component: AdminComponent, canActivate: [AuthGuard], children: [
                {
                    path: '', component: DashboardComponent
                },
                {
                    path: 'dashboard', component: DashboardComponent
                },
                {
                    path: 'manage-literature/add', component: LiteratureEditComponent
                },
                {
                    path: 'manage-literature/:id', component: LiteratureEditComponent
                },
                {
                    path: 'manage-literature', component: LiteratureListComponent
                },
                {
                    path: 'eventbundle/add', component: EventBundleEditComponent
                },
                {
                    path: 'eventbundle/:id', component: EventBundleEditComponent
                },
                {
                    path: 'eventbundle', component: EventBundleListComponent
                },
                {
                    path: 'event-bundle', component: EventBundleDetailComponent
                },
                {
                    path: 'sales-bundle', component: SalesBundleDetailComponent
                },
                {
                    path: 'image-management', component: ImageManagementComponent, children: [
                        {
                            path: ':id', component: ImageEditComponent
                        },
                        {
                            path: '', component: ImageListComponent
                        }
                    ]
                },
                {
                    path: 'user-management', component: UserManagementComponent, children: [
                        {
                            path: ':id', component: UserEditComponent
                        },
                        {
                            path: '', component: ListUserComponent
                        }
                    ]
                },
                {
                    path: 'portal/image', component: PortalImageEditComponent
                },
                {
                    path: 'portal/preview', component: PortalImagePreviewComponent
                },
                {
                    path: 'user', component: ListUserComponent
                },
                {
                    path: 'user/:id', component: UserEditComponent
                },
                { path: 'fetch-data', component: FetchDataComponent },
                { path: 'get-cart', component: CartDetailComponent },

                { path: 'terms-conditions', component: TermsConditionsComponent },

                { path: 'literature/:id/add-to-cart', component: CartDetailComponent },
                { path: 'literature/:id/detail', component: LiteratureDetailComponent },
                { path: 'literature/:level1/:level2', component: LiteratureSubCategoryListComponent, data: { title: 'Literature' } },
                { path: 'literature/:level1/:level2/:level3', component: LiteratureSubCategoryListComponent, data: { title: 'Literature' } },
                { path: 'literature/:level1', component: LiteratureSubCategoryListComponent, data: { title: 'Literature' } },
                { path: 'literature', component: LiteratureSubCategoryListComponent, data: { title: 'Literature' } },

                { path: 'image/:id/detail', component: ImageDetailComponent },
                { path: 'image/:level1/:level2/:level3', component: ImageSubCategoryListComponent, data: { title: 'Image' } },
                { path: 'image/:level1/:level2', component: ImageSubCategoryListComponent, data: { title: 'Image' } },
                { path: 'image/:level1', component: ImageSubCategoryListComponent, data: { title: 'Image' } },
                { path: 'image', component: ImageSubCategoryListComponent, data: { title: 'Image' } },

                { path: 'card/:id', component: BuisnessCardComponent },
                { path: 'postcard/:id', component: PostCardComponent, data: { title: 'Postcards' } },
                { path: 'order-detail', component: OrderDetailComponent },
                { path: 'order/list', component: UserOrderListComponent },
                { path: 'search', component: SearchComponent },
                { path: 'order/manage', component: PendingOrderListComponent },
                { path: 'order/manage/edit/:id', component: AdminEditOrderComponent },
                { path: 'order/manage/edit/businesscard/:id', component: AdminBuisnessCardEditComponent },
                { path: 'activity/list', component: ActivityListComponent },
                { path: 'activity/detail/:id', component: ActivityDetailComponent },
                { path: '**', redirectTo: 'dashboard' }
            ]
        }
    }
}