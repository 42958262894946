import { Component, OnInit } from '@angular/core';
import { WindowRef } from '../../shared/services/window.client.service';
import { LoggerService } from '../../shared/services/logger.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LiteratureDetailService } from './services/literature.detail.service';
import { Util } from '../../shared/services/util';
import { OrderItemCreatorService } from '../addtocart/service/order.item.creator.service';
import { ValidationMessageService } from '../../shared/services/validation.service';
import { Location } from '@angular/common';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'literature-detail',
    templateUrl: './literature.detail.component.html',
  styleUrls: ['./literature.detail.component.css']
})
export class LiteratureDetailComponent implements OnInit {
    ValidationMessageService = ValidationMessageService;
    WindowRef = WindowRef;

    customOptions: OwlOptions = {
        loop: false,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        dots: false,
        navSpeed: 700,
      navText: ['<i class="fa fa-arrow-left" aria-hidden="true"></i>', '<i class="fa fa-arrow-right" aria-hidden="true"></i>'],
        responsive: {
          0: {
            items: 1
          },
          400: {
            items: 2
          },
          740: {
            items: 3
          },
          940: {
            items: 4
          }
        },
        nav: true
      }



    back() {
        this._location.back();
    }

    subscribedRouterEvent: any = null;
    literatureId: number;


    requestUrlDetail: any = {
        level1: null,
        level2: null,
        level3: null,
    };


    requestVisibleDetail: any = {
        level1: null,
        level2: null,
        level3: null,
    }

    literatureDetail: any = {
        id: null,
        formNumber: null,
        revDate: null,
        departmentId: null,
        versionNumber: null,
        literatureTypeId: null,
        isPDFAvailable: false,
        minOrderQuantity: null,
        quantityOnHand: null,
        maximumOrderQuantity: null,
        lowWaterMarkQuantity: null,
        reOrderQuantity: null,
        orderQuantity: null,
        file: {},
        relatedImages: [],
        relatedLiteratures: [],
        name: null,
        categoryId: null,
        statusId: null,
        activationDate: null,
        keywords: [],
    }

    constructor(private _activatedRoute: ActivatedRoute,
        private _literatureDetailService: LiteratureDetailService,
        private _router: Router,
        private _location: Location,
        public orderItemCreatorService: OrderItemCreatorService,
        private _loggerService: LoggerService) {
    }


    ngOnInit() {
        let that = this;
        that.subscribedRouterEvent = this._activatedRoute.paramMap.subscribe(params => {
            that._loggerService.log(params);
            that.parseRequestDetail(params);
        });
        that._loggerService.log("LiteratureDetailComponent initialized");
    }


    getKeywordSearchResult(value: any) {
        this._router.navigate(["/" + WindowRef.appBaseUrl + "/search"], { queryParams: { 'searchString': value, 'productTypeId': WindowRef.lookup.product_type.literature } });
    }
    parseRequestDetail(params?: any): any {
        let that = this;
        params = params || that._activatedRoute.snapshot.params;

        that.literatureId = params.get('id') || null,
            that.getDetail(that.literatureId);
        that._loggerService.log(that.literatureId);
    }




    getDetail(id: number) {
        let that = this;
        that._literatureDetailService.GetLiteratureDetail(id).then(function (data) {
            that.literatureDetail = data
            that.calculateBreadCrumCategory(data.categoryId);
            that.mapRelatedImageSizeChecks();
            that.mapCategoryLink();
        });
    }

    mapCategoryLink() {
        let that = this;
        that.literatureDetail.relatedLiteratures.map(function (item: any) {
            let data: any = Util.getCompleteUrlForCategoryId(WindowRef.lookup.product_type.literature, '#/' + WindowRef.appBaseUrl + '/literature', item.categoryId);
            item.category = data.category;
            item.url = data.url;
            return item;
        });


        that.literatureDetail.relatedImages.map(function (item: any) {
            let data: any = Util.getCompleteUrlForCategoryId(WindowRef.lookup.product_type.image, '#/' + WindowRef.appBaseUrl + '/image', item.categoryId);
            item.category = data.category;
            item.url = data.url;
            return item;
        });
    }

    mapRelatedImageSizeChecks() {
        let that = this;
        that.literatureDetail.relatedImages.map(function (item: any) {
            item.isJPGFormatedRequired = false;
            item.isPNGFormatedRequired = false;
            item.isOriginalSizeRequired = false;
            return item;
        });
        console.log(that.literatureDetail);
    }

    calculateBreadCrumCategory(categoryId: number) {
        let that = this;
        let categoryWithLevel = WindowRef.lookupDb.category['literature'].find(function (item: any) {
            return item.id == categoryId;
        });


        if (!categoryWithLevel) return;

        if (categoryWithLevel.level == 3) {
            that.getAndPopulateCategoryForLevel3(categoryWithLevel);
        }

        if (categoryWithLevel.level == 2) {
            that.getAndPopulateCategoryForLevel2(categoryWithLevel);
        }

        if (categoryWithLevel.level == 1) {
            that.getAndPopulateCategoryForLevel1(categoryWithLevel);
        }
    }



    getAndPopulateCategoryForLevel3(category: any) {
        let that = this;
        that.requestUrlDetail.level3 = Util.encodeUrl(category.name);
        that.requestVisibleDetail.level3 = category.name;
        let parentCategory = WindowRef.lookupDb.category['literature'].find(function (item: any) {
            return item.id == category.parentCategoryId;
        });
        that.getAndPopulateCategoryForLevel2(parentCategory);
    }

    getAndPopulateCategoryForLevel2(category: any) {
        let that = this;
        that.requestUrlDetail.level2 = Util.encodeUrl(category.name);
        that.requestVisibleDetail.level2 = category.name;
        let parentCategory = WindowRef.lookupDb.category['literature'].find(function (item: any) {
            return item.id == category.parentCategoryId;
        });
        that.getAndPopulateCategoryForLevel1(parentCategory);
    }

    getAndPopulateCategoryForLevel1(category: any) {
        let that = this;
        that.requestUrlDetail.level1 = Util.encodeUrl(category.name);
        that.requestVisibleDetail.level1 = category.name;
    }

    quantitySelected(value: number, literature: any) {
        literature.orderQuantity = value;
    }



}
