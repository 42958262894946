import { Directive, Input, OnInit, Output, EventEmitter, ElementRef } from "@angular/core";
import { NgModel } from "@angular/forms";
import { Observable, fromEvent } from "rxjs";
import { map, filter, debounceTime, tap, switchAll } from 'rxjs/operators';


@Directive({ selector: '[debounce]' })
export class Debounce implements OnInit {
    @Input() delay: number = 700;
    @Output() func: EventEmitter<any> = new EventEmitter();

    constructor(private elementRef: ElementRef, private model: NgModel) {
    }

    ngOnInit(): void {
      const eventStream = fromEvent(this.elementRef.nativeElement, 'keyup').pipe(
            map(() => this.model.value),
            debounceTime(this.delay)
            );

        eventStream.subscribe(input => this.func.emit(input));
    }

}
