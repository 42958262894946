import { Injectable, OnInit } from '@angular/core';
import { WindowRef } from '../../../shared/services/window.client.service';
import { CartMessageService } from './cart.message.service';
import { NotifyFlashMessageService } from '../../../shared/services/notify.flash.messages.service';
import { LocalStorageWrapper } from '../../../shared/services/local.storage.service';

export class CartItem {
    id: any = null;
    name: any = null;
    assetNumber: any = null;
    productTypeId: any = null;
    productType: any = null;
    isPdfRequired: any = null;
    fileId: any = null;
    thumbnailFileId: any = null;
    customFileId: any = null;
    jpgFileId: any = null;
    pngFileId: any = null;
    file: any = null;
    base64Url: any = null;
    maximumOrderQuantity: any = null;
    minOrderQuantity: any = null;
    orderQuantity: any = null;
    reOrderQuantity: any = null;
    quantityOnHand: any = null;
    isJPGFormatedRequired: boolean = false;
    isPNGFormatedRequired: boolean = false;
    isOriginalSizeRequired: boolean = false;
    businessCardDetail: any = null;
    postCardDetail: any = null;
    addedOn: any = null;
    isRequireApproval: boolean = false;
    bundleTypeId: any = null;
    cropFileId: any = null;
}

@Injectable()
export class OrderItemCreatorService implements OnInit {
    WindowRef = WindowRef;
    cartItems: any[] = [];

    constructor(public _cartMessageService: CartMessageService, private _toaster: NotifyFlashMessageService) {
    }

    ngOnInit() {
        let that = this;
        that.getExistingCartItems();
    }

    getExistingCartItems() {
        let that = this;
        var localStorageObj = new LocalStorageWrapper();
        that.cartItems = localStorageObj.getCartItems();
    }

    addBundleToCart(item: any) {
        let that = this;
        let model = null;
        if (item.bundleTypeId == WindowRef.lookup.bundle_type.event_bundle) {
            item.orderQuantity = 1;
            item.maximumOrderQuantity = 1;
        }

        item.productTypeId = WindowRef.lookup.product_type.event_bundle;
        let valid = that.dataValidator(item);
        if (!valid) {
            return;
        }
        model = that.getBundleCartModel(item);
        if (model) {
            that._cartMessageService.sendMessage(model, true);
        }
    }

    addLiteratureToCart(item: any, isPdfRequired: boolean) {
        let that = this;
        let model = null;
        item.productTypeId = WindowRef.lookup.product_type.literature;
        let valid = that.dataValidator(item);
        if (!valid) {
            return;
        }
        model = that.getLiteratureCartModel(item, isPdfRequired);
        if (model) {
            that._cartMessageService.sendMessage(model, true);
        }
    }

    addBusinessCardToCart(item: any, isPdfRequired: boolean) {
        let that = this;
        let model = null;
        item.productTypeId = WindowRef.lookup.product_type.business_card;
        item.isRequireApproval = true;
        let valid = that.dataValidator(item);
        if (!valid) {
            return;
        }
        model = that.getBusinessCardCartModel(item);
        if (model) {
            that._cartMessageService.sendMessage(model, true);
        }
    }

    addPostCardToCart(item: any, isPdfRequired: boolean) {
        let that = this;
        let model = null;
        item.productTypeId = WindowRef.lookup.product_type.post_card;
        let valid = that.dataValidator(item);
        if (!valid) {
            return;
        }
        model = that.getPostCardCartModel(item);
        if (model) {
            that._cartMessageService.sendMessage(model, true);
        }
    }

    dataValidator(item: any) {
        let that = this;
        let isValid = true;
        let isContained = false;

        that.getExistingCartItems();

        if (item.productTypeId == WindowRef.lookup.product_type.event_bundle) {
            if (that.cartItems != null && that.cartItems.length > 0) {
                that.cartItems.forEach(function (cartItem) {
                    if (cartItem.productTypeId == WindowRef.lookup.product_type.event_bundle
                        && cartItem.bundleTypeId != item.bundleTypeId) {
                        if (!isContained) {
                            isContained = true;
                        }
                    }
                    else if (cartItem.productTypeId != WindowRef.lookup.product_type.event_bundle
                        && item.bundleTypeId != WindowRef.lookup.bundle_type.inside_sales_bundle) {
                        if (!isContained) {
                            isContained = true;
                        }
                    }
                });
                if (isContained) {
                    isValid = false;
                    that._toaster.warning("Event bundles can't be associated with other type of products, please remove items from cart first!");
                    return isValid;
                }
            }
        } else {
            if (that.cartItems != null && that.cartItems.length > 0) {
                that.cartItems.forEach(function (cartItem) {
                    if (cartItem.productTypeId == WindowRef.lookup.product_type.event_bundle
                        && cartItem.bundleTypeId == WindowRef.lookup.bundle_type.event_bundle) {
                        if (!isContained) {
                            isContained = true;
                        }
                    }
                });
                if (isContained) {
                    isValid = false;
                    that._toaster.warning("Event bundles can't be associated with other type of products, please remove items from cart first!");
                    return isValid;
                }
            }
        }

        if (item.productTypeId != WindowRef.lookup.product_type.literature && item.productTypeId != WindowRef.lookup.product_type.event_bundle) {
            if (item.minOrderQuantity && item.orderQuantity && (item.minOrderQuantity > item.orderQuantity)) {
                isValid = false;
                that._toaster.warning("Please enter Order Quantity greater than Minimum Order Quantity!");
                return isValid;
            }
        }
        if (item.maximumOrderQuantity && item.orderQuantity) {
            if (item.maximumOrderQuantity < item.orderQuantity)
                item.isRequireApproval = true;
            else
                item.isRequireApproval = false;
            // that._toaster.warning("Please enter Order Quantity less that Maximum Order Quantity!");
            return isValid;
        }
        if (((item.productTypeId == WindowRef.lookup.product_type.literature && !item.isPDFAvailable)
            || item.productTypeId == WindowRef.lookup.product_type.event_bundle) && (item.orderQuantity == null || item.orderQuantity <= 0)) {
            isValid = false;
            that._toaster.warning("Please enter valid Order Quantity !");
        }
        return isValid;
    }

    addImageToCart(item: any) {
        let that = this;
        let model = null;
        item.productTypeId = WindowRef.lookup.product_type.image;
        let valid = that.dataValidator(item);
        if (!valid) {
            return;
        }
        model = that.getImageCartModel(item);

        if (model) {
            that._cartMessageService.sendMessage(model, true);
        }
    }

    getBundleCartModel(item: any) {
        var cartItem = new CartItem();
        cartItem.id = item.id;
        cartItem.name = item.name;
        cartItem.assetNumber = item.formNumber;
        cartItem.productTypeId = WindowRef.lookup.product_type.event_bundle;
        cartItem.productType = "Event Bundle";
        cartItem.bundleTypeId = item.bundleTypeId;
        cartItem.isPdfRequired = false;
        cartItem.maximumOrderQuantity = (item.bundleTypeId == WindowRef.lookup.bundle_type.inside_sales_bundle)
            ? item.maximumOrderQuantity : 1;
        cartItem.orderQuantity = item.orderQuantity;
        cartItem.isRequireApproval = item.isRequireApproval;
        cartItem.addedOn = new Date();
        return cartItem;
    }

    getLiteratureCartModel(item: any, isPdfRequired: boolean) {
        var cartItem = new CartItem();
        cartItem.id = item.id;
        cartItem.name = item.name;
        cartItem.assetNumber = item.formNumber;
        cartItem.productTypeId = WindowRef.lookup.product_type.literature;
        cartItem.productType = "Literature";
        cartItem.isPdfRequired = isPdfRequired;
        cartItem.fileId = item.fileId || (item.file && item.file.id) || null;
        cartItem.thumbnailFileId = item.thumbnailFileId;
        cartItem.customFileId = item.customFileId;
        cartItem.maximumOrderQuantity = item.maximumOrderQuantity;
        cartItem.minOrderQuantity = item.minOrderQuantity;
        cartItem.reOrderQuantity = item.reOrderQuantity;
        cartItem.quantityOnHand = item.quantityOnHand;
        cartItem.orderQuantity = item.orderQuantity;
        cartItem.isRequireApproval = item.isRequireApproval;
        cartItem.cropFileId = item.cropFileId;
        cartItem.addedOn = new Date();
        return cartItem;
    }


    getBusinessCardCartModel(item: any) {
        var cartItem = new CartItem();
        cartItem.id = item.id;
        cartItem.name = item.name;
        cartItem.productTypeId = WindowRef.lookup.product_type.business_card;
        cartItem.productType = "Business Card";
        cartItem.isPdfRequired = false;
        cartItem.file = item.file || null;
        cartItem.base64Url = item.base64Url;
        cartItem.maximumOrderQuantity = item.maximumOrderQuantity;
        cartItem.minOrderQuantity = item.minOrderQuantity;
        cartItem.reOrderQuantity = item.reOrderQuantity;
        cartItem.orderQuantity = item.orderQuantity;
        cartItem.quantityOnHand = item.quantityOnHand;
        cartItem.addedOn = new Date();
        cartItem.isRequireApproval = item.isRequireApproval;
        let detail = {
            typeId: item.typeId,
            nameOnCard: item.nameOnCard,
            titleOnCard: item.titleOnCard,
            emailOnCard: item.emailOnCard,
            phoneOnCard: item.phoneOnCard,
            mobileOnCard: item.mobileOnCard,
            addressOnCard: item.addressOnCard,
            departmentOnCard: item.departmentOnCard,
            costCenterOnCard: item.costCenterOnCard,
            addressObj: item.addressObj
        }

        cartItem.businessCardDetail = detail;
        return cartItem;
    }

    getPostCardCartModel(item: any) {
        var cartItem = new CartItem();
        cartItem.id = item.id;
        cartItem.name = item.name;
        cartItem.productTypeId = WindowRef.lookup.product_type.post_card;
        cartItem.productType = "PostCard";
        cartItem.isPdfRequired = false;
        cartItem.file = item.file || null;
        cartItem.thumbnailFileId = item.thumbnailFileId || null;
        cartItem.base64Url = item.base64Url;
        cartItem.maximumOrderQuantity = item.maximumOrderQuantity;
        cartItem.minOrderQuantity = item.minOrderQuantity;
        cartItem.reOrderQuantity = item.reOrderQuantity;
        cartItem.orderQuantity = item.orderQuantity;
        cartItem.quantityOnHand = item.quantityOnHand;
        cartItem.addedOn = new Date();

        let detail = {
            typeId: item.typeId,
            nameOnCard: item.nameOnCard,
            boothNumberOnCard: item.boothNumberOnCard,
            colorCodeOnCard: item.colorCodeOnCard,
            startDate: item.startDate,
            endDate: item.endDate,
            dateString: item.dateString,
            cityOnCard: item.cityOnCard,
            stateOnCard: item.stateOnCard,
            withOutMarginFile: item.withOutMarginModel,
            withMarginFile: item.withMarginModel,
            thumbnailFile: item.thumbnailModel
        }

        cartItem.postCardDetail = detail;

        return cartItem;
    }

    getImageCartModel(item: any) {
        var cartItem = new CartItem();
        cartItem.id = item.id;
        cartItem.name = item.name;
        cartItem.assetNumber = item.imageNumber;
        cartItem.productTypeId = WindowRef.lookup.product_type.image;
        cartItem.productType = "Image";
        cartItem.isPdfRequired = false;
        cartItem.isJPGFormatedRequired = item.isJPGFormatedRequired;
        cartItem.isPNGFormatedRequired = item.isPNGFormatedRequired;
        cartItem.isOriginalSizeRequired = item.isOriginalSizeRequired;
        cartItem.fileId = item.fileId || (item.file && item.file.id) || null;
        cartItem.thumbnailFileId = item.thumbnailFileId || null;
        cartItem.jpgFileId = item.jpgFileId || null;
        cartItem.pngFileId = item.pngFileId || null;
        cartItem.addedOn = new Date();
        return cartItem;
    }
}