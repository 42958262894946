import {Component} from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector:'confirm-dialog',
    templateUrl: './confirmation.model.component.html'
})
export class ConfirmationModelComponent {

    confirmMessage: string;

    constructor(public activeModal: NgbActiveModal) {
    }

    response(confirmation:any) {
        this.activeModal.close(confirmation);
    }
    dismiss(){
        this.activeModal.dismiss("cancel");
    }
}
