import { AuthComponent } from "./auth.component";
import { LoginComponent } from "./login/login.component";
import { ResetComponent } from "./reset/reset.component";
import { ForgotComponent } from "./forgot/forgot.component";
import { AuthInitialDataGuard } from "./auth.initial.data.service";
import { LogoutComponent } from "./logout/logout.component";
import { RegisterComponent } from "./register/register.component";
import { ResponseComponent } from "./register/response.component";
import { ResetPasswordComponent } from "./forgot/reset.password.component";
import { InvalidLinkComponent } from "./forgot/invalid.link.component";

export class Authentication {

    static getAllComponents(): any {
        return [
            AuthComponent,
            LoginComponent,
            ResetComponent,
            ForgotComponent,
            LogoutComponent,
            RegisterComponent,
            ResponseComponent,
            ResetPasswordComponent,
            InvalidLinkComponent
        ];
    }


    static getAllPaths(): any {
        return {
            path: 'auth', component: AuthComponent, children: [
                { path: '', component: LoginComponent, canActivate: [AuthInitialDataGuard] },
                { path: 'login', component: LoginComponent, data: { title: 'Login' } },
                //      { path: 'logout', component: LogoutComponent, data: { title: 'Logout' } },
                { path: 'reset/:token', component: ResetComponent, data: { title: 'Reset Password' } },
                { path: 'forgot', component: ForgotComponent, data: { title: 'Forgot Password' } },
                { path: 'register', component: RegisterComponent, data: { title: 'Register' } },
                { path: 'response', component: ResponseComponent, data: { title: 'Response' } },
                { path: 'resetPass/:token', component: ResetPasswordComponent, data: { title: 'Reset Password' } },
                { path: 'invalid', component: InvalidLinkComponent, data: { title: 'Invalid Link' } },
            ]
        };
    }
}
