import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { WindowRef } from "../services/window.client.service";


@Component({
    selector: 'image-upload',
    templateUrl: './image.upload.component.html'
})

export class ImageUploadComponent implements OnInit {


    @Input() url: string = '/logo/no.gif'; // put here the right path for image upload. 
    @Input() thumbnailFile: boolean = false;
    @Output() output: EventEmitter<any> = new EventEmitter<any>();

    filter = 'image/*';

    constructor
    (
           private _cookie: CookieService,
               private _window: WindowRef
             ) { }

    public fileUploaded(responseObj: any) {

        
        var cookie = this._cookie.get(this._window.nativeWindow.authTokenString);
        if (this.thumbnailFile && !responseObj.data.mimeType.includes("image")) {
            responseObj.err = "Invalid file format";
            this.output.emit(responseObj);
        }
        if (responseObj.err == null && responseObj.data) {
            var data = responseObj.data;
            this.url = "/api/util/file?path=" + data.Path + '&name=' + data.Name + '&contentType=' + data.MimeType + '&authToken=' + cookie;
            this.output.emit(responseObj);
        }
    }

    

    ngOnInit(): void {
        this.filter = this.thumbnailFile ? 'image/*' : 'application/postscript, image/*';
    }
}
