import { Injectable } from '@angular/core';

function _window(): any {
    // return the global native browser window object
    return window;
}

@Injectable()
export class WindowRef {
    static lookup: any;
    static lookupType: any;
    static lookupDb: any;
    static userIdentity: any;
    static userRoles: any;
    static selectedTZ: string;
    static softwareVersion: string;
    static appBaseUrl: string;
    static redirect_url: string;

    get nativeWindow(): any {
        return _window();
    }

    getLookup() {
        return WindowRef.lookup;
    }

    getLookupType() {
        return WindowRef.lookupType;
    }

    getLookupDb() {
        return WindowRef.lookupDb;
    }


    getUserIdentity() {
        return WindowRef.userIdentity;
    }

    getRedirectURL() {
        return WindowRef.redirect_url;
    }

    getUserRoles() {
        return WindowRef.userRoles;
    }

    getSoftwareVersion() {
        return WindowRef.softwareVersion;
    }

}
