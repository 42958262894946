import { Component } from "@angular/core";
import { WindowRef } from '../../shared/services/window.client.service';
import { PortalImageService } from '../../admin/portal_image/services/portal.image.service';
import { LoggerService } from '../../shared/services/logger.service';
import { LiteratureDetailService } from '../../marketing/literature/services/literature.detail.service';
import { Util } from '../../shared/services/util';
import { OrderItemCreatorService } from "../../marketing/addtocart/service/order.item.creator.service";
import { NgbCarouselConfig } from "@ng-bootstrap/ng-bootstrap";
import { OwlOptions } from "ngx-owl-carousel-o";
import { LoginHelperService } from '../../admin/common/services/login.helper.service';
@Component({
    selector: "dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ['./dashboard.component.css']
})

   
export class DashboardComponent {
    literatureList: any[] = [];
  WindowRef = WindowRef;
    ngOnInit(): void {
      this.getPortalImage();
      this.GetNewlyAddedLiterature();
      this.show = this._loginHelperService.getManagingCookiesObject() != "" ? false : true;
    }

    portal_image: any = {
        placeholder1File: {},
        placeholder2File: {},
        placeholder3File: {},
        placeholder4File: {},
        placeholder5File: {}
    }

    constructor(private _literatureSubCategoryListService: LiteratureDetailService,
        private _portalImageService: PortalImageService,
      public orderItemCreatorService: OrderItemCreatorService,
      private _loginHelperService: LoginHelperService,
        private _logger: LoggerService,config: NgbCarouselConfig) {
            config.interval = 2000;  
    config.wrap = true;  
    config.keyboard = false;  
      config.pauseOnHover = false;
    }

    GetNewlyAddedLiterature() {
        let that = this;
        that._literatureSubCategoryListService.GetNewlyAddedLiteratureList().then(data => {
            that._logger.log(data);
            that.literatureList = data;
            that.mapLiteratureCategoryLink();
        });
    }

    mapLiteratureCategoryLink() {
        let that = this;
        that.literatureList.map(function (item) {
            let data: any = Util.getCompleteUrlForCategoryId(WindowRef.lookup.product_type.literature, '#/' + WindowRef.appBaseUrl + '/literature', item.categoryId);
            item.category = data.category;
            item.url = data.url;
            return item;
        });

    }

    getPortalImage() {
        let that = this;
        this._portalImageService.GetPortalImage().then(function (data) {
            that.portal_image = data;
        });
    }

    quantitySelected(value: number, literature: any) {
        literature.orderQuantity = value;
        console.log(literature.orderQuantity);
    }
    customOptions: OwlOptions = {
        loop: true,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        dots: false,
        navSpeed: 700,
        navText: ["<i class='fa fa-long-arrow-left'></i>","<i class='fa fa-long-arrow-right'></i>"],
        responsive: {
          0: {
            items: 1
          },
          400: {
            items: 2
          }
        },
        nav: true
      }
      show: boolean= true;
  closemodal(type) {
    this._loginHelperService.setManagingCookiesObject(type);
        this.show = !this.show;
      }

}
