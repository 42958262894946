import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { WindowRef } from '../../shared/services/window.client.service';
import { LoggerService } from '../../shared/services/logger.service';
import { ActivatedRoute } from '@angular/router';
import { Util } from '../../shared/services/util';
// import { NgxCarousel } from 'ngx-carousel';
import { CartMessageService } from '../addtocart/service/cart.message.service';
import { OrderItemCreatorService, CartItem } from '../addtocart/service/order.item.creator.service';
import { BusinessCardService } from './services/businesscard.service';
import { ValidationMessageService } from '../../shared/services/validation.service';
import { NgForm } from '@angular/forms';
import domtoimage from 'dom-to-image';


@Component({
    selector: 'businesscard-add',
    templateUrl: './businesscard.component.html',
    styleUrls: ['./businesscard.component.css']
})
export class BuisnessCardComponent implements OnInit, OnDestroy {
    ngOnDestroy(): void {
        let that = this;
        if (that.subscribedRouterEvent != null) {
            that.subscribedRouterEvent.unsubscribe();
        }
    }

    @ViewChild('custom_card', { static: false }) custom_card: ElementRef;

    validationMessages: any = null;
    WindowRef = WindowRef;
    isForEdit: boolean = false;
    subscribedRouterEvent: any = null;
    typeOfCard: string = "";
    cardTypeId = null;
    isValidcostCenterOnCard = false;
    buisnessCardDetail: any = {
        id: null,
        typeId: null,
        name: "",
        nameOnCard: null,
        titleOnCard: null,
        emailOnCard: null,
        phoneOnCard: { phone1: null, phone2: null, phone3: null },
        mobileOnCard: { phone1: null, phone2: null, phone3: null },
        addressOnCard: "",
        addressObj: {},
        costCenterOnCard: "",
        costCenter: { costCenter1: null, costCenter2: null, costCenter3: null, costCenter4: null, costCenter5: '.4610.05450' },
        departmentOnCard: null,
        HTML: null,
        file: null,
        base64Url: null,
        orderQuantity: null,
        minOrderQuantity: 250,
        maximumOrderQuantity: 500
    }

    constructor(private _activatedRoute: ActivatedRoute,
        private _cartMessageService: CartMessageService,
        private _businessCardService: BusinessCardService,
        public orderItemCreatorService: OrderItemCreatorService,
        private _loggerService: LoggerService) {
        let that = this;
        that.validationMessages = ValidationMessageService;
    }


    ngOnInit() {
        console.log(domtoimage);
        let that = this;
        that.subscribedRouterEvent = this._activatedRoute.paramMap.subscribe(params => {
            that._loggerService.log(params);
            that.parseRequestDetail(params);
        });
    }

    parseRequestDetail(params?: any): any {
        let that = this;
        params = params || that._activatedRoute.snapshot.params;

        that.cardTypeId = params.get('id') || null;

        var card = WindowRef.lookupDb.buisness_card_type.find(function (item: any) {
            return item.id == that.cardTypeId;
        });
        if (!card) {
            that.typeOfCard = "";
        } else {
            if (that.cardTypeId == WindowRef.lookup.buisness_card_type.after_market_group) {
                that.typeOfCard = "The Aftermarket Group Business Card"
            }
            if (that.cardTypeId == WindowRef.lookup.buisness_card_type.corporate) {
                that.typeOfCard = "Corporate Business Card"
            }
            if (that.cardTypeId == WindowRef.lookup.buisness_card_type.pindot) {
                that.typeOfCard = "Pindot Business Card"
            }
            if (that.cardTypeId == WindowRef.lookup.buisness_card_type.post_acute) {
                that.typeOfCard = "Post Acute Care Sales Business Card"
            }
            if (that.cardTypeId == WindowRef.lookup.buisness_card_type.rehab) {
                that.typeOfCard = "Rehab Sales Business Card"
            }
            if (that.cardTypeId == WindowRef.lookup.buisness_card_type.top_end) {
                that.typeOfCard = "Top End Business Card"
            }
        }
        that.buisnessCardDetail.name = that.typeOfCard;
        that.buisnessCardDetail.typeId = that.cardTypeId;
        that.buisnessCardDetail.isRequireApproval = true;

        that._loggerService.log(that.cardTypeId);
    }

    preViewCard() {
        let that = this;
        that.createHtmlPreview();
    }

    createHtmlPreview() {
        let that = this;
        domtoimage.toPng(this.custom_card.nativeElement)
            .then(function (dataUrl: any) {
                that.buisnessCardDetail.base64Url = dataUrl;
                that.openPreview(dataUrl);
                //that._businessCardService.GetPreview(that.buisnessCardDetail).then(function (data) {
                //    that.buisnessCardDetail.file = data;
                //  that.openPreview(data);
                //    that._loggerService.log(data);
                //}, function (err) {
                //    that._loggerService.log(err);
                //});
            })
            .catch(function (error: any) {
                console.error('oops, something went wrong!', error);
            });
    }

    openPreview(data: any) {
        if (data) {
            //  window.open("/api/util/file?path=" + data.relativePath + "&name=" + data.name + "&contentType=" + data.mimeType, "_blank");

            let innerHtml = "<html ><body ><img style='margin-top:10%; margin-left:40%' src='" + data + "' /></body></html>";
            let that = this;
            let win = new that.WindowRef().nativeWindow.open("", "_blank");
            win.document.body.innerHTML = innerHtml;
            win.document.body.style.background = "#E1E1E1";
        }
    }

    clearBusinessCardData(form: NgForm) {
        let that = this;
        that.buisnessCardDetail = {
            id: null,
            nameOnCard: null,
            titleOnCard: null,
            emailOnCard: null,
            phoneOnCard: { phone1: null, phone2: null, phone3: null },
            mobileOnCard: { phone1: null, phone2: null, phone3: null },
            addressOnCard: "",
            addressObj: {},
            costCenterOnCard: "",
            costCenter: { costCenter1: null, costCenter2: null, costCenter3: null, costCenter4: null, costCenter5: '.4610.05450' },
            departmentOnCard: null,
            HTML: null,
            file: null,
            base64File: null,
            orderQuantity: null,
            minOrderQuantity: 250,
            maximumOrderQuantity: 500,
            typeId: that.cardTypeId,
            isRequireApproval: true
        }
        form.resetForm();
    }
    quantitySelected(value: number, b: any) {
        b.orderQuantity = value;
    }

    onAddressSelect() {
        let that = this;

        that.buisnessCardDetail.addressObj = WindowRef.lookupDb.i_addresses.find(function (item: any) {
            return that.buisnessCardDetail.addressOnCard == item.fullAddress;
        });
    }

    costCenterValidation() {
        setTimeout(() => {
            let that = this;

            var costCenter1 = that.buisnessCardDetail.costCenter.costCenter1 != null ? that.buisnessCardDetail.costCenter.costCenter1 : '';
            var costCenter2 = that.buisnessCardDetail.costCenter.costCenter2 != null ? '.' + that.buisnessCardDetail.costCenter.costCenter2 : '';
            var costCenter3 = that.buisnessCardDetail.costCenter.costCenter3 != null ? '.' + that.buisnessCardDetail.costCenter.costCenter3 : '';
            var costCenter4 = that.buisnessCardDetail.costCenter.costCenter4 != null ? '.' + that.buisnessCardDetail.costCenter.costCenter4 : '';
            var costCenter5 = that.buisnessCardDetail.costCenter.costCenter5

            that.buisnessCardDetail.costCenterOnCard = that.buisnessCardDetail.costCenter != null
                ? (costCenter1 + costCenter2 + costCenter3 + costCenter4 + costCenter5) : null;

            var obj = WindowRef.lookupDb.i_business_cost_centers.filter(function (item: any) {
                return item.value == that.buisnessCardDetail.costCenterOnCard;
            });
            if (obj == null || obj.length <= 0) {
                that.isValidcostCenterOnCard = true;
            } else {
                that.isValidcostCenterOnCard = false;
            }

        }, 0);

    }

    addBusinessCardToCart() {
        let that = this;
        // let scale = 1.5;
        // const BASE_DPI = 72;
        // const scale = 9;
        domtoimage.toPng(this.custom_card.nativeElement,
            {
                height: this.custom_card.nativeElement.offsetHeight * 15,
                width: this.custom_card.nativeElement.offsetWidth * 15,
                style: {
                    transform: `scale(${15})`,
                    transformOrigin: 'top left',
                    width: `${this.custom_card.nativeElement.offsetWidth}px`,
                    height: `${this.custom_card.nativeElement.offsetHeight}px`
                }
            })
            .then(function (dataUrl: any) {
                // dataUrl =  changeDpiDataUrl(dataUrl, BASE_DPI * scale)
                that.buisnessCardDetail.base64Url = dataUrl;
                that.orderItemCreatorService.addBusinessCardToCart(that.buisnessCardDetail, true);
            })
            .catch(function (error: any) {
                console.error('oops, something went wrong!', error);
            });
    }
}
