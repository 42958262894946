import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ListRecordStringService } from "../common/services/list.record.string.service";
import { ImageService } from "./services/image.service";
import { LoggerService } from "../../shared/services/logger.service";
import { WindowRef } from "../../shared/services/window.client.service";
import { NotifyFlashMessageService } from "../../shared/services/notify.flash.messages.service";





@Component({
    selector: "image-list",
  templateUrl: "./image.list.component.html",
    styleUrls: ['./image.list.component.css']

})
export class ImageListComponent extends ListRecordStringService  implements OnInit {

    WindowRef = WindowRef;
    totalCount: number = 0;
    imageList = [];
    search_filter: any = {
        filter: {
            imageNumber: "",
            name: "",
            departmentId: "",
            statusId: ""
        },
        pageSize: 25,
        pageNumber: 1
    }
    isRecordExist: boolean = true;

    constructor(private _imageService: ImageService,
        private _loggerService: LoggerService,
        private _activeRoute: ActivatedRoute,
        private _toaster: NotifyFlashMessageService,
        private _router: Router) {
        super();
    }

    ngOnInit(): void {
        let that = this;
        that.getList();
    }

    getList() {
        let that = this;
        that.isRecordExist = true;
        that._loggerService.log(that.search_filter);
        that._imageService.GetImageList(that.search_filter).then(function (data) {
            that._loggerService.log(data);
            that.calculateRecordString(data, that.search_filter);
            that.imageList = data.collection;
            that.totalCount = data.totalRecords;
            that.mapActivationStatus();
            that.isRecordExist = that.imageList.length > 0;
        });
    }

    updateImageStatus(item: any) {
        let that = this;
        let data = { id: item.id, statusId: item.statusId };
        if (item.isActive == true) {
            data.statusId = WindowRef.lookup.product_status.active;
        } else {
            data.statusId = WindowRef.lookup.product_status.inactive;
        }

        that._imageService.UpdateImageStatus(data).then(function (data) {
            if (data) {
                that._toaster.success("Successfully updated");
            } else {
                item.isActive = !item.isActive;
            }
        });
    }

    mapActivationStatus() {
        let that = this;
        that.imageList.map(function (item: any) {
            if (item.statusId == WindowRef.lookup.product_status.active) {
                item.isActive = true;
            } else {
                item.isActive = false;
            }
            return item;
        });
    }

    navigateToEdit(id: number) {
        this._router.navigate(["/admin/image-management/" + id]);
    }

    clearAllFilters() {
        let that = this;
        that.search_filter = {
            filter: {
                formNumber: "",
                name: "",
                departmentId: "",
                literatureTypeId: "",
                statusId: ""
            },
            pageSize: 25,
            pageNumber: 1
        }

        that.getList();
    }
}



