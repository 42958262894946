import { Component, OnInit } from '@angular/core';
import { LocalStorageWrapper } from '../../shared/services/local.storage.service';
import { WindowRef } from '../../shared/services/window.client.service';
import { CartMessageService } from './service/cart.message.service';
import { Subscription } from 'rxjs';
import { ValidationMessageService } from '../../shared/services/validation.service';
import { Location } from '@angular/common';
import { NotifyFlashMessageService } from '../../shared/services/notify.flash.messages.service';
import { Router } from '@angular/router';
import { UserOrderService } from './service/user.order.service';
import { ConfirmationModelComponent } from '../../admin/common/confirmation.model.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../admin/user/services/user.service';
import { AddressDistributionComponent } from '../addtocart/address.distribute.component';
import { TermsConditionsComponent } from '../../admin/common/terms.conditions.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'cart-detail',
  templateUrl: './cart.detail.component.html',
  styleUrls: ['./cart.detail.component.css']
})
export class CartDetailComponent implements OnInit {
    WindowRef = WindowRef;
    cartItems: any[] = [];
    subscription: Subscription;
    validationMessages: any = null;
    selectedAddress: any[] = [];
    addressModel: any = {};
    isImageType: boolean = false;
    isChecked: boolean = false;
    isEventBundle: boolean = false;
    showEmailField: boolean = false;

    noImageZip = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEwAACxMBAJqcGAAABVtJREFUeJztnc1rXUUYxp+ZmyYxiisrBC3miAhCUQpxIdh1kitIbIoU226E0AoKQf8KcSElC42Kq1RiNVYL3sQPEJTiwi5c2AqB5lRKI7ULF4pNcpuZLoJNztWGm5k558w57/PbnSE89+XOr++ZOR+3ACFELipoWmupD7pnHLDjMHoYMIPQ+t6gn5EHxqxCIQX0D7D2I4wl30MpW3ZZRRBOgMUrEzAbb0HrJFhmWVh7HtqcxMhjv5RdSt74C3DGNnBf+ia0eiNAPfFgzCp04xhGh+bLLiVPtHdCHScfALTuBzbOoJUeKbuUPPHrAItXJgD7aWbMoA1gBg07i5trlzD+xF9en5E31ip8u3w/NvQzgH0NUM3sHxgDo4+imcyVU2C+uAvQWuoDGr9mzvnGrKChn8NI8nOI4kphMX0ZMO8Delt3rK8E7qcA3TOenXy0Kz/5ADCafAijjwLGbA1qDW1O1/F04LEGsOMdAzOVn/x/aSZzUiRwF8Do4cxxw876FhMVQiTw6ABmMHN4c+2SZy3xIUACjzVAxxW+2Ff7rtRcAv/rABKosQQUoFtqKgEF2A01lIAC7JaaSUABXKiRBBTAlZpIQAF8qIEEFMCXiktAAUJQYQkoQCgqKgEFCEkFJaAAoamYBBQgDyokAQXIi4pIQAHypAISUIC8iVwC96eCF9Psq1OjSdjXzBbSQ1CYAfCAZ9INWDuJsUe/CFGWM630CLQ5HdvTxvF2gDCTDwB7YdV7AXL8iLQTxCtAmMnfROPBYFk+RChBzALUk8gkoABlEJEEPUV+mDfdLjQ7F6gx0kzm0EqRXRjekQBFLQzZAcokgk5AAcqmZAkoQAyUKAEFiIWSJKAAMVGCBBQgNgqWgALESIESUIBYKUgCChAzBUhAAWJnJwkW0kO+8dW6FFyFS7x5cLfLxsrMYuG3pzH2yEXXaHaAqvD/neAeKPMOrHV+GIcCVIlmMgeokx2jB/F1etA1kgJUjZHkAxh8kxmz6iXXOApQNZSyaJjp7KB91jWOAlQRu+d8x4DzT/RTgCoysu/P7IAecI2iAFUk4P9mQgGEQwGEQwGEE7MAN4IlGVwPllUz4hXA2kkY/OGdY3AdDTMZoKJaEu/NoM2XOct9oVMA8XYAUggUQDgUQDgUQDgUQDjx7gK6odtHxEL/fE2NYAcQDgUQDgUQDgUQDgUQTpy7AK7uC4MdQDgUQDgUQDgUQDgUQDhx7gJC/yIodwt3hR1AOBRAOBRAOBRAOBRAOHHuAri6Lwx2AOFQAOFQAOFQAOFQAOHEuQvgvYDCYAcQDgUQDgUQDgUQDgUQTpy7AK7uC4MdQDgUQDgUQDgUQDgUQDhx7gJ4L6Aw2AGEQwGEQwGEQwGEQwGEE+cugKv7wmAHEA4FEA4FEA4FEA4FEE6cuwDeCygMdgDhUADhUADhuAtg7K3M8Xdpv28xpEvOXRvoGFl3jfLoAOr3zGF7Y797FtkVvasd37VZcY1y3wVo+xOg9m3V0DgG4IJz3na4ut8Zo49n/ula7fy9u3cAi887Rl5B6/KTznmkO1rpAQAnMmMKZ13j3AXoV5/AZFpPL4AFLC4/5ZxJdqaVHoA2X0Jjz50xg6sw7XnXSL8WurD8IpT6uGN0HcC7MGYWt/ov4vmH/tl1Lk8BW5y7NoDe1f0w+jiAE5nJBwCLCYwln7nG+3+BrXQaGq965xAXTmE0mfIJ8L8O8PfQFCymvXPIbjmFH4de9w0J10K/unwYVr8N4OFgmeS/GFyFwpRP299O2HNoa6kPuvcwjHkBWg0DGMTm4pC4sw6YFVh9AQpnYdrzaD6+VnZRhJA6cBvA8UDDilBhiAAAAABJRU5ErkJggg==";

    shippingDetail = {
        name: null,
        email: null,
        phone: null,
        address: null,
        state: null,
        city: null,
        zipcode: null,
        emailOne: null,
        emailTwo: null,
    }

    constructor(private _location: Location,
        private _orderService: UserOrderService,
        private _toaster: NotifyFlashMessageService,
        private _router: Router,
        private _modelService: NgbModal,
        private _userService: UserService,
        private _cartMessageService: CartMessageService,
        private _modalService: NgbModal,
        private domSanitizer: DomSanitizer
    ) {
        let that = this;
        that.validationMessages = ValidationMessageService;
    }

    ngOnInit() {
        let that = this;
        that.subscription = that._cartMessageService.getMessage().subscribe((message: any) => {
            that.getExistingCartItems();
        });
        that.getExistingCartItems();
        that.getShippingInfo();
    }

    openModel() {
        let that = this;

        let reject = function (result: any) {
            that.getShippingInfo();
        };
        let resolve = function (data: any) {
            that.selectedAddress = [];
            that.addressModel = data;
            that.selectedAddress = data.collection;
        };
        let model = that._modelService.open(AddressDistributionComponent, { size: 'lg' } as NgbModalOptions);
        (model.componentInstance).addressList = that.selectedAddress;
        model.result.then((result) => {
            resolve(result);
        }, (result) => {
            reject(result);
        });

        return model;
    }

    getShippingInfo() {
        let that = this;
        that._userService.getShippingInfo().then(function (result) {
            that.shippingDetail = result;
        });
    }

    getExistingCartItems() {
        let that = this;
        that.isImageType = false;
        that.isChecked = true;
        that.isEventBundle = false;
        var localStorageObj = new LocalStorageWrapper();
        that.cartItems = localStorageObj.getCartItems();
        for (var x = 0; x < that.cartItems.length; x++) {
            that.cartItems[x].sequence = x + 1;
        }
        that.cartItems.forEach(function (cartItem) {
            if (cartItem.productTypeId == WindowRef.lookup.product_type.event_bundle
                && cartItem.bundleTypeId == WindowRef.lookup.bundle_type.event_bundle) {
                if (!that.isEventBundle) {
                    that.isEventBundle = true;
                }
            }
        });
        if (!that.isEventBundle) {
            var valid = false;
            var onlyImage = true;
            that.cartItems.forEach(function (cartItem) {
                if (cartItem.productTypeId == WindowRef.lookup.product_type.image) {
                    if (!valid)
                        valid = true;
                }
                if (cartItem.productTypeId != WindowRef.lookup.product_type.image) {
                    if (onlyImage)
                        onlyImage = false;
                }
            });
            if (valid) {
                that.isImageType = true;
                that.isChecked = false;
            }
            if (onlyImage) {
                that.showEmailField = true;
            }
        }
    }

    ngOnDestroy(): void {
        let that = this;
        that.subscription.unsubscribe();
    }


    removeItemFromCart(sequence: number, productTypeId: number, isPdfRequired: boolean) {
        let that = this;
        var localStorageObj = new LocalStorageWrapper();
        let otherItems: any[] = [];
        for (var x = 0; x < that.cartItems.length; x++) {
            if (that.cartItems[x].sequence == sequence && that.cartItems[x].isPdfRequired == isPdfRequired
                && that.cartItems[x].productTypeId == productTypeId) {
            } else {
                otherItems.push(that.cartItems[x]);
            }
        }
        localStorageObj.setCartItems(otherItems);
        that.getExistingCartItems();
        that._cartMessageService.sendMessage(null, false);
    }

    removeItemPromt(sequence: number, productTypeId: number, isPdfRequired: boolean) {
        let that = this;
        let reject = function (result: any) {
        };
        let resolve = function (result: any) {
            if (result) {
                that.removeItemFromCart(sequence, productTypeId, isPdfRequired);
            }
        };

        let message = "Are you sure you want to remove this item from Cart!";
        let model = that._modelService.open(ConfirmationModelComponent, { size: 'sm' } as NgbModalOptions);
        model.componentInstance.confirmMessage = message;
        model.result.then((result: any) => {
            resolve(result);
        }, (result: any) => {
            reject(result);
        });
    }


    clearCart() {
        let that = this;
        var localStorageObj = new LocalStorageWrapper();
        localStorageObj.setCartItems([]);
        that._cartMessageService.sendMessage(null, false);
    }

    placeBundleOrder() {
        let that = this;
        var isValid = true;

        if (!that.cartItems.length) {
            that._toaster.warning("Please add items in cart!");
            return;
        }

        that.cartItems.forEach(function (item) {
            item.maximumOrderQuantity = 1;
            item.orderQuantity = 1;
            if (item.productTypeId != WindowRef.lookup.product_type.event_bundle
                || item.bundleTypeId != WindowRef.lookup.bundle_type.event_bundle) {
                if (isValid) {
                    isValid = false;
                }
            }
        });
        if (!isValid) {
            return;
        };

        var bundle = { cartItems: that.cartItems, shippingDetail: that.shippingDetail }

        that._orderService.SaveBundleOrder(bundle).then(function (bundle) {
            that._toaster.success("Order placed successfully.");
            that.clearCart();
            that.navigateToList();
        });
    }

    placeOrder() {
        let that = this;
        var isValid = that.validateQuantity();
        if (!isValid) {
            return;
        };

        if (!that.cartItems.length) {
            that._toaster.warning("Please add items in cart!");
            return;
        }
        var data = { cartItems: that.cartItems, shippingDetail: that.shippingDetail, addressDistribution: that.addressModel }

        that._orderService.SaveOrder(data).then(function (data) {
            that._toaster.success("Order placed successfully.");
            that.clearCart();
            that.navigateToList();
        });
    }

    openCardPreview(data: any) {
        let that = this;
        if (data) {
            let innerHtml = "<html ><body ><img style='margin-top:10%; margin-left:40%' src='" + data + "' /></body></html>";

            let win = new that.WindowRef().nativeWindow.open("", "_blank");
            win.document.body.innerHTML = innerHtml;
            win.document.body.style.background = "#E1E1E1";
        }
    }

    navigateToList() {
        this._router.navigate(["/" + WindowRef.appBaseUrl + "/order/list"]);
    };

    cancel() {
        this._location.back();
    }

    validateQuantity() {
        let that = this;
        var isValid = true;
        that.cartItems.forEach(function (item) {
            if (isValid) {
                isValid = that.dataValidator(item);
            }
        });
        return isValid;
    }


    dataValidator(item: any) {
        let that = this;
        let isValid = true;
        if (item.productTypeId != WindowRef.lookup.product_type.literature) {
            if (item.minOrderQuantity && item.orderQuantity && (item.minOrderQuantity > item.orderQuantity)) {
                isValid = false;
                that._toaster.warning("Please enter Order Quantity greater that Minimum Order Quantity!");
                return isValid;
            }
        }
        if (item.maximumOrderQuantity && item.orderQuantity) {
            if (item.maximumOrderQuantity < item.orderQuantity)
                item.isRequireApproval = true;
            else
                item.isRequireApproval = false;
            // that._toaster.warning("Please enter Order Quantity less that Maximum Order Quantity!");
            return isValid;
        }
        if (item.productTypeId == WindowRef.lookup.product_type.literature && item.orderQuantity <= 0) {
            isValid = false;
            that._toaster.warning("Please enter valid Order Quantity !");
            return isValid;
        }
        return isValid;
    }

    getPlaceHolder(typeId: number) {
        if (typeId == WindowRef.lookup.product_type.business_card) {
            return "../../../assets/images/pdf_placeholder.png";
        }
    }

    quantitySelected(value: number, item: any) {
        let that = this;
        var isUpdated = false;
        var localStorageObj = new LocalStorageWrapper();
        that.cartItems.forEach(function (cartItem) {
            if (!isUpdated) {
                if (cartItem.sequence == item.sequence && item.productTypeId == cartItem.productTypeId) {
                    if (item.productTypeId == WindowRef.lookup.product_type.business_card)
                        cartItem.orderQuantity = value;
                    else
                        cartItem.orderQuantity = item.orderQuantity;
                    isUpdated = true;
                }
            }
        });

        if (isUpdated)
            localStorageObj.setCartItems(that.cartItems);
    }

    openModal() {
        let that = this;
        let reject = function (result: any) {
        };
        let resolve = function (keywords: any) {
        };
        let model = that._modalService.open(TermsConditionsComponent, { size: 'lg' } as NgbModalOptions);
        model.result.then((result) => {
            resolve(result);
        }, (result) => {
            reject(result);
        });
        return model;
    }
}
