import { Inject, Injectable } from '@angular/core';
// import * as rxjs from 'rxjs/Rx.js'


// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/catch';
import { HttpWrapperService } from '../../../shared/services/http.wrapper.service';
import { HttpClientModule } from '@angular/common/http';

@Injectable()
export class RegisterService {

    constructor(
        @Inject(HttpClientModule) private _http: HttpClientModule,
        @Inject(HttpWrapperService) private _httpWrapper: HttpWrapperService
    ) { }


    RegisterUser(data: any) {
        return this._httpWrapper.post({ url: 'api/users/register/save', data: data });
    }

}