import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'response',
  templateUrl: './response.component.html',
    styleUrls: ['../../admin/common/common.component.css']
})

export class ResponseComponent {
  
}
