import { Component } from "@angular/core";

@Component({
    selector: "image-dashboard",
    template: "<router-outlet></router-outlet>"
})


export class ImageManagementComponent {


}