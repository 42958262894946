import { Injectable } from "@angular/core";
import { HttpWrapperService } from "../../../shared/services/http.wrapper.service";
var qs = 'qs';



@Injectable()
export class LiteratureService{

    constructor(private httpWrapper:HttpWrapperService) {

    }

    GetLiterature(id:number) {
        return this.httpWrapper.get({ url: 'api/catalogue/literature/' + id });
    }


    SaveLiterature(data:any) {
        return this.httpWrapper.post({ url: 'api/catalogue/literature', data: data });
    }


    UpdateLiteratureStatus(data: any) {
        return this.httpWrapper.post({ url: 'api/catalogue/literature/status', data: data });
    }



    CheckUniqueNumber(data: any) {
        return this.httpWrapper.post({ url: 'api/catalogue/literature/check', data: data });
    }

    GetLiteratureList(data:any) {
        return this.httpWrapper.post({
            url: 'api/catalogue/literature/list',
            data: data
        });
    }



}
