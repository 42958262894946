import { Component, OnInit, Input } from "@angular/core";
import { trigger, state, style, animate, transition } from '@angular/animations';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { LoggerService } from "../../shared/services/logger.service";
import * as _ from 'lodash';
import { ActivatedRoute, Router } from "@angular/router";
import { WindowRef } from "../../shared/services/window.client.service";
import { NotifyFlashMessageService } from "../../shared/services/notify.flash.messages.service";


@Component({
    selector: "contact-info",
    templateUrl: "./contact.info.component.html",
    animations: [
        trigger('fadeInOut', [
            transition('void => *', [
                style({ opacity: 0 }), //style only for transition transition (after transiton it removes)
                animate(700, style({ opacity: 1 })) // the new state of the transition(after transiton it removes)
            ]),
            transition('* => void', [
                animate(0, style({ opacity: 0 })) // the new state of the transition(after transiton it removes)
            ])
        ])
    ]

})

export class ContactInfoComponent implements OnInit {
    WindowRef = WindowRef; 

  
    ngOnInit(): void {
       
       
    }

    constructor(private _activeModal: NgbActiveModal, private _loggerService: LoggerService,
        private _toaster: NotifyFlashMessageService,
        private _activatedRoute: ActivatedRoute) {
    }
       
   

    dismiss() {
        this._activeModal.close();
    }
}
