import { Component } from "@angular/core";



@Component({
    selector: 'marketing-footer',
  templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent {



}
