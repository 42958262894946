import { Component, OnInit } from '@angular/core';
import { WindowRef } from "../../shared/services/window.client.service";
import { EventBundleService } from "./services/eventbundle.service";
import * as _ from 'lodash';
import { ListRecordStringService } from "../common/services/list.record.string.service";
import { Router } from "@angular/router";
import { OrderItemCreatorService } from '../../marketing/addtocart/service/order.item.creator.service';

@Component({
    selector: "salesBundle-detail",
  templateUrl: "./eventbundle.detail.component.html",
})

export class SalesBundleDetailComponent extends ListRecordStringService implements OnInit {

    validationMessages: any = null;
    _isEdit: boolean = false;
    WindowRef = WindowRef;
    totalCount: number = 0;
    eventBundleList: any[] = [];
    title = "Inside Sales Bundles";

    constructor(private _eventbundleService: EventBundleService, private _router: Router,
        private orderItemCreatorService: OrderItemCreatorService) {
        super();
    }

    navigateToEdit(id: number) {
        this._router.navigate(["/admin/eventbundle/" + id]);
    }

    search_filter: any = {
        filter: {
            name: "",
            StartDate: "",
            EndDate: "",
            BundleTypeId: WindowRef.lookup.bundle_type.inside_sales_bundle,
            isDetail: true
        },
        pageSize: 25,
        pageNumber: 1

    }
    ngOnInit(): void {
        let that = this;
        that.getList();
    }

    getList() {
        let that = this;
        that._eventbundleService.GeteventBundleList(that.search_filter).then(function (data) {
            that.calculateRecordString(data, that.search_filter);
            that.eventBundleList = data.collection;
            that.totalCount = data.totalRecords
        });
    }

    clearAllFilters() {
        let that = this;
        that.search_filter = {
            filter: {
                name: "",
                StartDate: "",
                EndDate: "",
                BundleTypeId: WindowRef.lookupDb.bundle_type.inside_sales_bundle,
            },
            pageSize: 25,
            pageNumber: 1
        }
        that.getList();
    }
}
