import { Component, OnInit } from '@angular/core';
import { WindowRef } from '../../shared/services/window.client.service';
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from 'rxjs';
import { LoggerService } from '../../shared/services/logger.service';
import { ListRecordStringService } from '../../admin/common/services/list.record.string.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { OrderService } from './order.service';
import { NotifyFlashMessageService } from '../../shared/services/notify.flash.messages.service';
import { ConfirmationModelComponent } from '../../admin/common/confirmation.model.component';
import * as _ from 'lodash';


@Component({
    selector: 'order-list',
  templateUrl: './order.list.component.html',
  styleUrls: ['./order.detail.component.css']
})
export class OrderListComponent extends ListRecordStringService implements OnInit {
    WindowRef = WindowRef;
    totalCount: number = 0;
    orderList: any[] = [];
    subscription: Subscription;
    activeIndex: number = 0;
    orderId: number;
    public daterange: any = {};
    file: any;
    public options: any = {
        locale: { format: 'YYYY-MM-DD' },
        alwaysShowCalendars: false,
    };

    orderStatusList: any[] = [];

    public selectedDate(value: any, datepicker?: any) {
        datepicker.start = value.start;
        datepicker.end = value.end;

        this.daterange.start = value.start;
        this.daterange.end = value.end;
        this.daterange.label = value.label;
    }


    orderDetail: any = {
        cartItems: [],
        shippingDetail: {}
    };

    copyOrderDetail: any = {
        cartItems: [],
        shippingDetail: {}
    }

    search_filter: any = {
        filter: {
            date: "",
            orderId: "",
            statusId: ""
        },
        pageSize: 25,
        pageNumber: 1
    }

    constructor(private _orderService: OrderService,
        private _loggerService: LoggerService,
        private _toaster: NotifyFlashMessageService,
        private _modelService: NgbModal,
        private _activatedRoute: ActivatedRoute,
    ) {
        super();
        this.orderStatusList = this.WindowRef.lookupDb.order_status.filter((x: any) => x.id !== WindowRef.lookup.order_status.approval_pending && x.id !== WindowRef.lookup.order_status.rejected);

    }
    ngOnInit() {
        let that = this;

        let orderId = parseInt(this._activatedRoute.snapshot.params['id']);
        if (orderId > 0)
            that.search_filter.redirectOrderId = orderId;

        that.getOrderList(true);
    }


    getOrderList(needUpdate: boolean) {
        debugger;

        let that = this;
        that._orderService.ListOrder(that.search_filter).then(function (data: any) {
            that.orderList = data.collection;
            that.totalCount = data.totalRecords;
            that.calculateRecordString(data, that.search_filter);
            if (that.orderList.length && needUpdate && !that.search_filter.redirectOrderId) {
                that.getOrderDetail(that.orderList[0].id, 0);
            }
            if (that.orderList.length && that.search_filter.redirectOrderId) {
                let index = that.orderList.findIndex(x => x.Id === that.search_filter.redirectOrderId);
                if (index < 0 || index == null)
                    index = 0;
                that.getOrderDetail(that.search_filter.redirectOrderId, index);
            }
        })
    }

    fileUploaded(responseObj: any) {
        let that = this;
        that._loggerService.log(responseObj);
        that.file = responseObj.data;
    }

    uploadFile() {
        let that = this;
        if (that.file == null)
            return;
        that._orderService.ParseXMlFile(that.file).then(function (data) {
            that._loggerService.log(data);
            that._toaster.success("XML file uploaded!");
            that.file = null;
            that.getOrderList(true);
        });
    }


    getOrderDetail(id: number, index: number) {
        let that = this;
        that.orderId = id;
        that.activeIndex = index;
        that._orderService.GetOrder(id).then(function (data: any) {
            that.orderDetail = data;
            that.mapOrderStatusString();
            that.copyOrderDetail = _.cloneDeep(that.orderDetail);
            that._loggerService.log(data);
        })
    }

    mapOrderStatusString() {
        let that = this;
        that.orderDetail.cartItems.map(function (item: any) {
            var orderStatus = WindowRef.lookupDb.order_status.find(function (i: any) {
                return i.id == item.statusId;
            });

            var productType = WindowRef.lookupDb.product_type.find(function (i: any) {
                return i.id == item.productTypeId;
            });

            item.status = orderStatus && orderStatus.name || null;
            item.productType = productType && productType.name || null;
            return item;
        });
    }

    updateOrderStatus(cartItem: any) {
        let that = this;
        that._orderService.UpdateOrderStatus({ id: cartItem.id, statusId: cartItem.statusId }).then(function (data) {
            that._loggerService.log(data);
            that._toaster.success("Successfully updated!");
            that.getOrderList(false);
            that.getOrderDetail(that.orderId, that.activeIndex);
        });
    }


    clearAllFilters() {
        let that = this;
        that.search_filter = {
            filter: {
                userId: null,
                date: "",
                orderId: "",
                statusId: ""
            },
            pageSize: 25,
            pageNumber: 1
        }
        that.file = null;
        that.getOrderList(true);
    }

    changeStatus(cartItem: any) {
        let that = this;
        let reject = function (result: any) {
            that.revertChangedStatus(cartItem);
        };
        let resolve = function (result: any) {
            if (!result) {
                that.revertChangedStatus(cartItem);
            } else {
                that.updateOrderStatus(cartItem);
            }
        };

        let message = "Are you sure you want to update the existing status of order!";
        let model = that._modelService.open(ConfirmationModelComponent, { size: 'sm' } as NgbModalOptions);
        model.componentInstance.confirmMessage = message;
        model.result.then((result: any) => {
            resolve(result);
        }, (result: any) => {
            reject(result);
        });
    }


    revertChangedStatus(cartItem: any) {
        let that = this;

        let item = that.copyOrderDetail.cartItems.find(function (i: any) {
            return i.id == cartItem.id;
        });

        if (item) {
            cartItem.statusId = item.statusId;
        }

    }

    getCustomCss(order: any) {
        if (order.statusId == WindowRef.lookup.order_status.confirmed) {
            return 'badge-primary';
        } else if (order.statusId == WindowRef.lookup.order_status.shipped) {
            return 'badge-info';
        } else if (order.statusId == WindowRef.lookup.order_status.delivered) {
            return 'badge-success';
        } else if (order.statusId == WindowRef.lookup.order_status.cancelled) {
            return 'badge-danger';
        } else if (order.statusId == WindowRef.lookup.order_status.approval_pending) {
            return 'badge-warning';
        } else if (order.statusId == WindowRef.lookup.order_status.rejected) {
            return 'badge-danger';
        }
    }








}
