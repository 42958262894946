import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { WindowRef } from '../../shared/services/window.client.service';
import { LoggerService } from '../../shared/services/logger.service';
import { Util } from '../../shared/services/util';
import { CookieService } from "ngx-cookie-service";
import { LocalStorageWrapper } from '../../shared/services/local.storage.service';
import { Subscription } from 'rxjs';
import { NotifyFlashMessageService } from '../../shared/services/notify.flash.messages.service';
import { ConfirmationModelComponent } from '../../admin/common/confirmation.model.component';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'duke-nav-menu',
  templateUrl: './duke-navmenu.component.html',
  styleUrls: ['./duke.navmenu.component.css']
})
export class DukeNavMenuComponent implements OnInit {
    WindowRef = WindowRef;

    constructor(private _loggerService: LoggerService,
                private _toaster: NotifyFlashMessageService,
                private _window: WindowRef,
                private _cookie: CookieService,
                private _modalService:NgbModal,
                private router: Router) {
        

    }
    ngOnInit() {
        let that = this;
        that._loggerService.warn("Duke Component initialized!");       
    }

   


    logout() {

        let that = this;
        var localStorageObj = new LocalStorageWrapper();

      localStorageObj.purge();
      that._cookie.delete("authTokenName");


        delete (new WindowRef()).nativeWindow['dam_user_identity'];
        delete (new WindowRef()).nativeWindow['redirect_url'];

        setTimeout(function () {
            //     that._router.navigate(['/auth/login']);
            that._window.nativeWindow.open("Home/logout","_self")
        }, 1000);
    }

    checkToLogout() {
        let that = this;
        let reject = function (result: any) {

        };
        let resolve = function (result: any) {
            if (result) {
                that.logout();
            }
        };

        let model = that._modalService.open(ConfirmationModelComponent, { size: 'sm' } as NgbModalOptions);
        model.componentInstance.confirmMessage = "Are you sure you want to logout!";
        model.result.then((result: any) => {
            resolve(result);
        }, (result: any) => {
            reject(result);
        });


    }

 






    
}
