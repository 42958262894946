import { Component, OnInit } from "@angular/core";
import { LoggerService } from "../../shared/services/logger.service";
import { Router, ActivatedRoute } from "@angular/router";
import { WindowRef } from "../../shared/services/window.client.service";
import { ListRecordStringService } from "../common/services/list.record.string.service";
import { NotifyFlashMessageService } from "../../shared/services/notify.flash.messages.service";
import { UserService } from "./services/user.service";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { UpdateStatusComponent } from "./update.status.component";

import { ConfirmationModelComponent } from "../common/confirmation.model.component";


@Component({
    selector: "user-list",
  templateUrl: "./list.user.component.html",
})

export class ListUserComponent extends ListRecordStringService implements OnInit {
    WindowRef = WindowRef;
    totalCount: number = 0;
    userList: any[] = [];
    isRecordExist: boolean = true;

    search_filter: any = {
        filter: {
            name: "",
            typeId: "",
            statusId: "",
            email: ""
        },
        pageSize: 25,
        pageNumber: 1
    }

    constructor(private _userService: UserService,
        private _loggerService: LoggerService,
        private _activeRoute: ActivatedRoute,
        private _toaster: NotifyFlashMessageService,
        private _modalService: NgbModal,
        private _router: Router) {
        super();
    }

    ngOnInit(): void {
        let that = this;
        that.getList();
    }

    getList() {
        let that = this;
        that.isRecordExist = true;
        that._userService.GetUserList(that.search_filter).then(function (data) {
            that.calculateRecordString(data, that.search_filter);
            that.userList = data.collection;
            that.totalCount = data.totalRecords
            that.isRecordExist = that.userList.length > 0;
        });
    }

    filterUsers() {
        let that = this;
        that.search_filter.pageNumber = 1;
        that.getList();
    }

    navigateToEdit(id: number) {
        this._router.navigate(["/admin/user/" + id]);

    }    

    DeleteUser(id: number) {
        let that = this;
        let message = "Are you sure you want to delete this user?.";
        let model = that._modalService.open(ConfirmationModelComponent, { size: 'sm' } as NgbModalOptions);
        model.componentInstance.confirmMessage = message;

        model.result.then((result: any) => {
            if (result == true) {
                that._userService.DeleteUser(id).then(function (data) {
                    if (data == true)
                        that.getList();
                    else {
                        that._toaster.error("Unable to delete user because Order List is Pending!");
                    }
                }
                    , function (err) {

                    }
                );
            }
        });
        
    }

    openModel(id: number) {
        let that = this;

        let reject = function (result: any) {
            that.getList();
        };
        let resolve = function (user: any) {
            that.getList();
        };
        let model = that._modalService.open(UpdateStatusComponent, { size: 'lg' } as NgbModalOptions);
        (model.componentInstance).Id = id;
        model.result.then((result) => {
            resolve(result);
        }, (result) => {
            reject(result);
        });

        return model;
    }

    clearAllFilters() {
        let that = this;
        that.search_filter = {
            filter: {
                name: "",
                typeId: "",
                statusId: "",
                email: ""
            },
            pageSize: 25,
            pageNumber: 1
        }
        that.getList();
    }
}
