import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { ImageService } from "./services/image.service";
import { ActivatedRoute, Router } from "@angular/router";
import { LoggerService } from "../../shared/services/logger.service";
import { WindowRef } from "../../shared/services/window.client.service";
import { CategoryComponent } from "../common/category.component";
import { ManageKeywordModelComponent } from "../common/manage.keyword.model.component";
import { ConfirmationModelComponent } from "../common/confirmation.model.component";
import { NotifyFlashMessageService } from "../../shared/services/notify.flash.messages.service";

import { NgForm } from '@angular/forms';

@Component({
    selector: "image-edit",
  templateUrl: "./image.edit.component.html",
    styleUrls: ['./image.edit.component.css']

})

export class ImageEditComponent implements OnInit {

  @ViewChild(CategoryComponent, { static: false }) categoryComponent: CategoryComponent;

    _isEdit: boolean = false;
    fileName: string = ""
    customThumbnailFileName: string = "";
    isLiteratureFileChanged: boolean = false;
    isValidNumber: boolean = true;
    WindowRef = WindowRef;
    isNotImageFile: boolean = false;
    image = new Image();
    ngOnInit(): void {
        let that = this;
        that.image.statusId = WindowRef.lookup.product_status.active;
        let imageId = parseInt(this._activatedRoute.snapshot.params['id']);
        if (imageId) {
            that._isEdit = true;
            that.getImage(imageId);
        } else {
            that._isEdit = false;
        }

        that._loggerService.log(WindowRef.lookup);
        that._loggerService.log(WindowRef.lookupType);
    }

    constructor(private _imageService: ImageService,
        private _modelService: NgbModal,
        private _toaster: NotifyFlashMessageService,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _loggerService: LoggerService) {

    }


    checkUniqueNumber() {

        let that = this;
        that._imageService.CheckUniqueNumber({ imageNumber: that.image.imageNumber }).then(function (data) {
            that.isValidNumber = data.isValidNumber;
            if (!that.isValidNumber) {
                that._toaster.error("Please enter a valid image number");
            }
        }
            , function (err) {

            });

    }

    saveImage(form: NgForm) {
        let that = this;
        that._loggerService.log(that.image);
        that.populateFinalCategoryId();

        if (!that.isValidNumber) {
            that._toaster.error("Please enter a valid image number");
            return;
        }

        that._imageService.SaveImage(that.image).then(function (data) {
            that._loggerService.log(data);
            that._toaster.success("Image uploaded Successfully!");
            if (that._isEdit)
                that.navigateToList();
            else
                that.resetForm(form);
        }
            , function (err) {

            });
    }

    resetForm(form: NgForm) {
        let that = this;
        that.fileName = "";
        that.customThumbnailFileName = "";
        that.image.originalFile = null;
        that.image.name = "";
        that.image.imageNumber = "";
        that.image.keywords = [];
        that.isLiteratureFileChanged = false;
        form.controls.imageNumber.markAsUntouched();
        form.controls.name.markAsUntouched();
    }

    navigateToList() {
        this._router.navigate(["/admin/image-management"]);
    }

    getImage(id: number) {
        let that = this;
        this._imageService.GetImage(id).then(function (data) {
            that.image = data;
            that.fileName = data.originalFile && data.originalFile.fileName || "";
            if (data.originalFile.mimeType.includes("image")) {
                that.isNotImageFile = false;
                that.image.customThumbnailFile = null;
                that.customThumbnailFileName = '';
            } else {
                that.customThumbnailFileName = data.customThumbnailFile && data.customThumbnailFile.fileName || "";
            }
            that.calculateAndPopulateCategory();
        }
            , function (err) {

            });
    }


    thumbnailFileUploaded(responseObj: any) {
        let that = this;
        that._loggerService.log(responseObj);
        if (responseObj.err != null && responseObj.err != '') {
            that._toaster.error(responseObj.err);
            that.image.customThumbnailFile = null;
            that.customThumbnailFileName = '';
        } else {
            that.image.customThumbnailFile = responseObj.data;
            that.customThumbnailFileName = responseObj.data.fileName;
            that.isLiteratureFileChanged = true;
        }
    }

    fileUploaded(responseObj: any) {

        debugger;
        let that = this;
        that._loggerService.log(responseObj);
        that.image.originalFile = responseObj.data;
        that.fileName = responseObj.data.fileName;
        that.isLiteratureFileChanged = true;
        if (responseObj.data.mimeType.includes("image")) {
            that.isNotImageFile = false;
        } else {
            that.isNotImageFile = true;
        }
        that.image.customThumbnailFile = null;
        that.customThumbnailFileName = '';
    }


    openManageKeywordsModel() {
        let that = this;

        let reject = function (result: any) {

        };
        let resolve = function (keywords: any) {
            that.image.keywords = keywords;
        };
        let model = that._modelService.open(ManageKeywordModelComponent, { size: 'lg' } as NgbModalOptions);
        model.result.then((result) => {
            resolve(result);
        }, (result) => {
            reject(result);
        });
        model.componentInstance.isLiterature = false;
        model.componentInstance.name = that.image.name;
        model.componentInstance.uniqueNo = that.image.imageNumber;
        model.componentInstance.selectedKeywords = that.image.keywords;

        return model;

    }


    calculateAndPopulateCategory() {
        let that = this;
        let level = null;
        let tempCategoryId = null;
        if (that.image.categoryId) {
            let found = WindowRef.lookupDb.category.image.find(function (item: any) {
                return item.id == that.image.categoryId;
            });
            if (found) {
                level = found.level;
            }
        }
        if (level == 3) {
            let found = WindowRef.lookupDb.category.image.find(function (item: any) {
                return item.id == that.image.categoryId;
            });
            if (found) {
                that.categoryComponent.selectedCategories.selectedLevel3CategoryId = found.id;
                that.categoryComponent.selectedCategories.selectedLevel2CategoryId = found.parentCategoryId;
                let foundAgain = WindowRef.lookupDb.category.image.find(function (item: any) {
                    return item.id == found.parentCategoryId;
                });
                if (foundAgain) {
                    that.categoryComponent.selectedCategories.selectedLevel1CategoryId = foundAgain.parentCategoryId;
                }
            }
        }
        if (level == 2) {
            let found = WindowRef.lookupDb.category.image.find(function (item: any) {
                return item.id == that.image.categoryId;
            });
            if (found) {
                that.categoryComponent.selectedCategories.selectedLevel2CategoryId = found.id;
                that.categoryComponent.selectedCategories.selectedLevel1CategoryId = found.parentCategoryId;
            }
        }

        if (level == 1) {
            that.categoryComponent.selectedCategories.selectedLevel1CategoryId = that.image.categoryId;
        }

        that.categoryComponent.setCategories();
        that._loggerService.log(that.categoryComponent.selectedCategories);
    }

    populateFinalCategoryId() {
        let that = this;
        if (that.categoryComponent.selectedCategories.selectedLevel3CategoryId) {
            that.image.categoryId = that.categoryComponent.selectedCategories.selectedLevel3CategoryId;
            return;
        }


        if (that.categoryComponent.selectedCategories.selectedLevel2CategoryId) {
            that.image.categoryId = that.categoryComponent.selectedCategories.selectedLevel2CategoryId;
            return;
        }


        if (that.categoryComponent.selectedCategories.selectedLevel1CategoryId) {
            that.image.categoryId = that.categoryComponent.selectedCategories.selectedLevel1CategoryId;
            return;
        }

    }


    changeStatus() {
        let that = this;
        let reject = function (result: any) {
            that.revertActivationStatus();
        };
        let resolve = function (result: any) {
            if (!result) {
                that.revertActivationStatus();
            }
        };

        let message = that.getMessageForChangeStatus();
        let model = that._modelService.open(ConfirmationModelComponent, { size: 'sm' } as NgbModalOptions);
        model.componentInstance.confirmMessage = message;
        model.result.then((result: any) => {
            resolve(result);
        }, (result: any) => {
            reject(result);
        });


    }

    revertActivationStatus() {
        let that = this;
        if (that.image.statusId == WindowRef.lookup.product_status.active) {
            that.image.statusId = WindowRef.lookup.product_status.inactive;
        } else if (that.image.statusId == WindowRef.lookup.product_status.inactive) {
            that.image.statusId = WindowRef.lookup.product_status.active
        }
    }


    getMessageForChangeStatus() {
        let that = this;
        if (that.image.statusId == WindowRef.lookup.product_status.active) {
            return "Are you sure you want to activate the Literature";
        } else if (that.image.statusId == WindowRef.lookup.product_status.inactive) {
            return "Are you sure you want to deactivate the Literature";
        }
    }

}


export class Image {
    id?: number;
    name: string = "";
    categoryId: string = "";
    statusId: string = "";
    imageNumber: string = "";
    departmentId: string = "";
    fileResolutions: any[] = [];
    keywords: any[] = [];
    createdBy?: string;
    dateCreated?: string;
    DateUpdated?: string;
    UpdatedBy?: string;
    originalFile?: any;
    thumbnailFile?: any;
    customThumbnailFile?: any;
}
