import { Injectable } from '@angular/core';
import { Observable,Subject } from 'rxjs';
// import { Subject } from 'rxjs/Subject';
import { WindowRef } from '../../../shared/services/window.client.service';

@Injectable()
export class CartMessageService {
    private subject = new Subject<any>();

    sendMessage(message: any, type: boolean) {
        this.subject.next({ item: message, type: type});
    }

    clearMessage() {
        this.subject.next();
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }




}





