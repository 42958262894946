import { Component, Input, Output, EventEmitter, SimpleChanges, forwardRef } from '@angular/core';

 
import { IDropdownListItemModel, SelectedAutocompleteValue } from './viewmodels/dropdown.list.item.model';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, FormGroup } from '@angular/forms';

import { HttpClientModule, HttpClient } from '@angular/common/http';

@Component({
  selector: 'custom-autocomplete',
  styles: [`
        .custom-autocomplete-main {
            position: relative;
        }


        .custom-autocomplete-main  .menu {
            min-width: 190px;
            padding: 12px 0;
            box-shadow: 0 9px 20px rgba(0, 0, 0, 0.25);
            position: absolute;
            top: 100%;
            left: 0;
            background: #fff;
            z-index: 1000;
            max-height: 350px;
            overflow: auto;
        }

        .custom-autocomplete-main  .col .menu {
            width: 100%;
        }

        .custom-autocomplete-main  .menu-list {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        .custom-autocomplete-main  .menu-list-item {
            font-size: 14px;
            color: #0c0c0c;
            padding: 6px 20px;
        }

        .custom-autocomplete-main  .menu-list-item.selected {
            font-weight: bold;
            background-color: #f2f4f6;
        }

        .custom-autocomplete-main .menu-list-item:hover {
            background: #f2f4f6;
        }

    `],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CustomAutocompleteComponent), multi: true },
  ],
  templateUrl: './custom-autocomplete.component.html'
})
export class CustomAutocompleteComponent implements ControlValueAccessor {
  @Input('text-value')
  set _textValue(value: any) {
    this.selected.text = value;
  }



  @Input('is-required') isRequired: boolean=false;
  @Input('control-name') controlName: string = "";
  @Input('key-value')
  keyValue!: string;
  @Input('data-property') dataPropertyName: string = "data";
  @Input('param-get-search') paramGetSearch: string = "";
  @Input('is-disabled')
  isDisabled!: boolean;
  @Input('api-url')
  apiURL!: string;
  @Input('id-field')
  nameIDField!: string;
  @Input('description-field')
  nameDescriptionField!: string;
  @Input('min-chars') minChars: number = 0;
  @Input('placeholder') placeholder: string = "";

  @Output('onSelect') onSelect: EventEmitter<string> = new EventEmitter<string>();
  @Output('onTextChange') onTextChange: EventEmitter<string> = new EventEmitter<string>();

   term = new FormControl();

   selected: SelectedAutocompleteValue;

   firstSet = true;
   list: IDropdownListItemModel[];
   showList: boolean = false;
   indexSelected!: number;
   idSelected!: string;

   dontBlur = false;

  constructor(public http: HttpClient) {
    this.list = [];
    this.selected = new SelectedAutocompleteValue();
    var mainThis = this;
    this.term.valueChanges
     // .debounceTime(300)
      .subscribe((term) => {

       // if (term == null || term == '') return [];
        if (term == null) { term = '';};

        if (term.length >= this.minChars)
          this.fetch(term)
      });
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['textValue']) {
      this.selected.text = changes['textValue'].currentValue;
    }
    if (changes['keyValue']) {
      this.selected.value = changes['keyValue'].currentValue;
    }
  }


  fetch(search: string): void {
    this.indexSelected = 0;
    let url = `${this.apiURL}?${this.paramGetSearch}=${search}`;
    if (this.paramGetSearch == '') {
      url = `${this.apiURL}${search}`;
    }
    this.http
      .get(`${url}`)
      .subscribe((response: any) => {
       // let ret = response.json();
        let jsonret = this.dataPropertyName != '' && this.dataPropertyName != undefined ? response[this.dataPropertyName] : response;
        this.list = jsonret.map(
          (d: any) => {
            return { value: d[this.nameIDField], text: d[this.nameDescriptionField] };
          }
        );
        if (this.list.length > 0)
          this.list[0].selected = true;
      });
  }

  onFocus() {
    this.showList = true;
  }

  onBlur() {
    setTimeout(() => {
      if (!this.dontBlur)
        this.showList = false;
    }, 200);
  }

  onKeyDown(event: KeyboardEvent) {
    var key = event.keyCode;
    this.keyValue = '0';
    this.onChange(this.keyValue);
    this.onTextChange.emit();
    if (key == 13) {
      this.doSelectIndex(this.indexSelected);
      this.showList = false;
      event.preventDefault();
    }
  }

  onKeyUp(event: KeyboardEvent) {
    this.showList = true;
    var key = event.keyCode;
    if (key == 38 || key == 40 || key == 13) {
      if (key == 13) {
        this.showList = false;
      }
      else {
        if (key == 40) this.indexSelected++;
        else this.indexSelected--;
        this.refreshSelected();
      }
    }
  }

  doSelectIndex(index: number): void {
    this.indexSelected = index;
    //this.selected.Description = `${this.list[this.indexSelected].ID} - ${this.list[this.indexSelected].Name}`;
    this.selected.text = `${this.list[this.indexSelected].text}`;
    this.selected.value = this.list[this.indexSelected].value;

    this.keyValue = this.selected.value;
    this.onChange(this.keyValue);
    this.onSelect.emit(this.keyValue);

    this.firstSet = false;
  }

  refreshSelected(): void {
    this.list = this.list.filter((d, i) => {
      if (i == this.indexSelected) d.selected = true;
      else d.selected = false;
      return d;
    });
  }

  onClickOption(item: IDropdownListItemModel, index: number): void {
    this.dontBlur = true;

    this.indexSelected = index;
    this.refreshSelected();

    this.doSelectIndex(this.indexSelected);
    this.dontBlur = false;
  }

  /** Implemented as part of ControlValueAccessor. */
  onChange: (value: any) => any = () => {
    
  };

  onTouched: () => any = () => { };

  writeValue(value: any) {
    if (!this.firstSet && (value == undefined || value == null || value == 0)) { //this.selected.Description = ""; }
      console.log(this.selected);
      this.selected.text = "";
      this.firstSet = false;
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  ngOnDestroy() {

  }

}
