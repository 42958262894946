import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Deferred } from "./deferred";
import { CookieService } from "ngx-cookie-service";
import { WindowRef } from "./window.client.service";
import { NotifyFlashMessageService } from "./notify.flash.messages.service";
import { NgxSpinnerService} from 'ngx-spinner';


enum HttpMethod {
    Get = 1, Post = 2, Put = 3, Delete = 4
}


interface HttpRequestOptions {
    url?: string;
    method?: string;
    search?: string;
    headers?: any;
    body?: any;
}


@Injectable()
export class HttpWrapperService {

    defaultOptions: any;

    constructor(
        private _http: HttpClient,
        private _cookie: CookieService,
        private _toaster: NotifyFlashMessageService,
        private _window: WindowRef,
        private _router: Router,
        private _spinnerService: NgxSpinnerService
    ) {
        let that = this;

        this.defaultOptions = (function (httpMethod: any) {
            return {
                url: '',
                data: '',
                showOnSuccess: true,
                showOnFailure: true,
                disableNotification: false,
                method: httpMethod,
                success: function (deffered: any, result: any, def: any) {
                    var msg = '';
                    if (def.showOnSuccess && msg && msg.length > 0) {
                        if (!def.disableNotification && def.method != HttpMethod.Get) {
                            that._toaster.success(msg);
                        }
                    }

                    return deffered.resolve(result.data);
                },
                error: function (deffered: any, result: any, def: any) {
                    var errorMessage = '';

                    try {
                        let msg = null;
                        if (result.error != null) {
                            msg = result.error.message;
                        }
                        errorMessage = msg || result.message || JSON.parse(result._body).message || 'Some Error Occurred';
                    }
                    catch (e) {
                        errorMessage = 'Some Error Occurred';
                    }
                    finally {
                        that._toaster.error(errorMessage);

                        //In case of any Aun-authenticated requests, such delete to cookie, go to login page and reload
                      if (result && result.status && result.status == 401) {
                        that._cookie.delete(that._window.nativeWindow.authTokenString);
                            that._router.navigate(['/auth/login']);
                            that._window.nativeWindow.location.reload();
                        }

                        return deffered.reject(errorMessage);
                    }
                }
            };
        });

    }



    httpRequestOptions(defaultOptions: any): HttpRequestOptions {
        let output: HttpRequestOptions = {};

        if (defaultOptions.headers && defaultOptions.headers.skipfullpageloader) {
            var headers = new HttpHeaders();
            headers.append('skipfullpageloader', 'true');
            output.headers = headers;
        }

        return output;
    }

    get(opts: any): Promise<any> {
        let that = this;
        var deferred = new Deferred<any>();
        var def = Object.assign(this.defaultOptions(HttpMethod.Get), opts);
        var options = this.httpRequestOptions(def);
        this._spinnerService.show();
        this._http.get(def.url, options)
            .subscribe(
                function (data: any) {
                    that._spinnerService.hide();
                    return def.success(deferred, data, def)
                },
                function (error: any) {
                    that._spinnerService.hide();
                    return def.error(deferred, error, def);
                }
            );

        return deferred.promise;
    }

    delete(opts: any): Promise<any> {
        let that = this;
        var deferred = new Deferred<any>();
        var def = Object.assign(this.defaultOptions(HttpMethod.Delete), opts);
        var options = this.httpRequestOptions(def);
        this._spinnerService.show();
        this._http.delete(def.url, options)
            .subscribe(
                function (data: any) {
                   that._spinnerService.hide();
                    return def.success(deferred, data, def)
                },
                function (error: any) {
                    that._spinnerService.hide();
                    return def.error(deferred, error, def);
                }
            );

        return deferred.promise;
    }

    post(opts: any): Promise<any> {
        let that = this;
        var deferred = new Deferred<any>();
        var def = Object.assign(this.defaultOptions(HttpMethod.Post), opts);
        var options = this.httpRequestOptions(def);
        this._spinnerService.show();
        this._http.post(def.url, def.data, options)
            .subscribe(
                function (data: any) {
                    that._spinnerService.hide();
                    return def.success(deferred, data, def)
                },
                function (error: any) {
                    that._spinnerService.hide();
                    return def.error(deferred, error, def);
                }
            );

        return deferred.promise;
    }

    put(opts: any): Promise<any> {
        let that = this;
        var deferred = new Deferred<any>();
        var def = Object.assign(this.defaultOptions(HttpMethod.Put), opts);
        this._spinnerService.show();
        this._http.put(def.url, def.data)
            .subscribe(
                function (data) {
                    that._spinnerService.hide();
                    return def.success(deferred, data, def)
                },
                function (error) {
                    that._spinnerService.hide();
                    return def.error(deferred, error, def);
                }
            );

        return deferred.promise;
    }

    upload(opts: any): Promise<any> {
        let that = this;
        var deferred = new Deferred<any>();
        var def = Object.assign(this.defaultOptions(HttpMethod.Post), opts);
        this._spinnerService.show();
        this._http.post(def.url, def.data)
            .subscribe(
                function (data) {
                    that._spinnerService.hide();
                    return def.success(deferred, data, def)
                },
                function (error) {
                    that._spinnerService.hide();
                    return def.error(deferred, error, def);
                }
            );

        return deferred.promise;
    }

    getFileByPost(opts: any): Promise<any> {
        let that = this;
        var deferred = new Deferred<any>();
        var def = Object.assign(this.defaultOptions(HttpMethod.Post), opts);
        this._spinnerService.show();
        this._http.post(def.url,def.data, { responseType: 'arraybuffer' })
            .subscribe(
                function (result) {
                    that._spinnerService.hide();
                    return deferred.resolve(result);
                },
                function (error) {
                    that._spinnerService.hide();
                    var decodedString = String.fromCharCode.apply(null, new Uint8Array(error.data));
                    var obj = { data: JSON.parse(decodedString) };
                    return def.error(deferred, obj, def);
                }
            );

        return deferred.promise;
    }
}
