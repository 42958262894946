import { Component, Input, Output, EventEmitter } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { WindowRef } from "../services/window.client.service";


@Component({
    selector: 'custom-upload',
    templateUrl: './custom.upload.component.html'
})

export class CustomUploadComponent {
    @Input() url: string = '/logo/no.gif'; // put here the right path for image upload. 
    @Output() output: EventEmitter<any> = new EventEmitter<any>();
    @Input() filter: string = '*/*';
   

    constructor(private _cookie: CookieService,
        private _window: WindowRef) {
    }

    public fileUploaded(responseObj:any) {
        var cookie = this._cookie.get(this._window.nativeWindow.authTokenString);

        if (responseObj.err == null && responseObj.data) {
            var data = responseObj.data;
            this.url = "/api/util/file?path=" + data.Path + '&name=' + data.Name + '&contentType=' + data.MimeType + '&authToken=' + cookie;
            this.output.emit(responseObj);
        }
    }
}
