import { Injectable } from '@angular/core';
import { HttpWrapperService } from '../../shared/services/http.wrapper.service';


@Injectable()
export class ActivityService {

    constructor(private _httpWrapper: HttpWrapperService) {
    }

    GetActivity(data: any) {
        return this._httpWrapper.post({ url: "api/order/activityReporting/detail", data: data });
    }

    ListActivity(data: any) {
        return this._httpWrapper.post({ url: "api/order/activityReporting/list", data: data });
    }

    exportToExcel(data: any) {
        return this._httpWrapper.getFileByPost({ url: "api/order/activityReporting/exportExcel", data: data });
    }

    exportToCSV(data: any) {
        return this._httpWrapper.getFileByPost({ url: "api/order/activityReporting/exportCSV", data: data });
    }

    exportDetailToExcel(data: any) {
        return this._httpWrapper.getFileByPost({ url: "api/order/activityReporting/exportDetailExcel", data: data });
    }

    exportDetailToCSV(data: any) {
        return this._httpWrapper.getFileByPost({ url: "api/order/activityReporting/exportDetailCSV", data: data });
    }

}





