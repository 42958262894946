import { Component, OnInit, ViewChild, ElementRef, Renderer } from '@angular/core';
import { WindowRef } from '../../shared/services/window.client.service';
import { LoggerService } from '../../shared/services/logger.service';
import { ActivatedRoute } from '@angular/router';
import { OrderItemCreatorService } from '../addtocart/service/order.item.creator.service';
import { ValidationMessageService } from '../../shared/services/validation.service';
import { NgForm } from '@angular/forms';
import { PostCardService } from './services/postcard.service';
import { NotifyFlashMessageService } from '../../shared/services/notify.flash.messages.service';
import * as _ from 'lodash';


@Component({
    selector: 'postcard',
  templateUrl: './postcard.component.html',
  styleUrls: ['./postcard.component.css']
})
export class PostCardComponent implements OnInit {
  @ViewChild('post_card', { static: false }) post_card: ElementRef;
  @ViewChild("dtPick", { static: false }) datePicker: ElementRef;

    validationMessages: any = null;
    WindowRef = WindowRef;
    subscribedRouterEvent: any = null;
    typeOfCard: string = "";
    cardTypeId = null;
    postCardDetail: any = {
        id: null,
        typeId: null,
        name: "",
        nameOnCard: null,
        boothNumberOnCard: null,
        startDate: null,
        endCard: null,
        dateString: null,
        cityOnCard: null,
        stateOnCard: null,
        colorCodeOnCard: "",
        HTML: null,
        withOutMarginModel: null,
        withMarginModel: null,
        thumbnailModel: null,
        orderQuantity: null,
        minOrderQuantity: 100,
        maximumOrderQuantity: 500
    }
    datePickerOptions: any = {
        minDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
    }

    constructor(private _activatedRoute: ActivatedRoute,
        private _postCardService: PostCardService,
        public orderItemCreatorService: OrderItemCreatorService,
        private _toaster: NotifyFlashMessageService,
        private _loggerService: LoggerService, private renderer: Renderer) {
        let that = this;
        that.validationMessages = ValidationMessageService;
    }


    ngOnInit() {
        let that = this;
        that.subscribedRouterEvent = this._activatedRoute.paramMap.subscribe(params => {
            that._loggerService.log(params);
            that.parseRequestDetail(params);
        });
    }

    parseRequestDetail(params?: any): any {
        let that = this;
        params = params || that._activatedRoute.snapshot.params;

        that.cardTypeId = params.get('id') || null;

        var card = WindowRef.lookupDb.post_card_type.find(function (item: any) {
            return item.id == that.cardTypeId;
        });
        if (!card) {
            that.typeOfCard = "";
        } else {
            if (that.cardTypeId == WindowRef.lookup.post_card_type.reason_outcomes_matter) {
                that.typeOfCard = "Reason Outcomes Matter Postcard"
            }
            if (that.cardTypeId == WindowRef.lookup.post_card_type.facility_design) {
                that.typeOfCard = "Facility Design Postcard"
            }
            if (that.cardTypeId == WindowRef.lookup.post_card_type.pressure_management) {
                that.typeOfCard = "Pressure Management Postcard"
            }
            if (that.cardTypeId == WindowRef.lookup.post_card_type.safe_patient_handling) {
                that.typeOfCard = "Safe Patient Handling Postcard"
            }

        }
        that.postCardDetail.name = that.typeOfCard;
        that.postCardDetail.typeId = that.cardTypeId;
        that._loggerService.log(that.cardTypeId);
    }

    preViewCard() {
        let that = this;
        if (!that.checkValidDate()) {
            return;
        }

        that._postCardService.GetPreview(that.postCardDetail).then(function (data) {
            that._loggerService.log(data);
            that.postCardDetail.withOutMarginModel = data.withOutMarginModel;
            that.postCardDetail.withMarginModel = data.withMarginModel;
            that.postCardDetail.thumbnailModel = data.thumbnailModel;
            that.openPreview(data);
        }, function (err) {
            that._loggerService.log(err);
        });

    }
    focusOnDateRange() {
        let that = this;
        that.renderer.invokeElementMethod(that.datePicker.nativeElement, 'focus',[]);
        
    }
    checkValidDate() {
        let that = this;
        var isValid = true;
        if (!this.postCardDetail.startDate) {
            that._toaster.warning("Please enter Start Date");
            isValid = false;
            return isValid;
        }

        if (!this.postCardDetail.endDate) {
            that._toaster.warning("Please enter End Date");
            isValid = false;
            return isValid;
        }

        var today = new Date();

        if (this.postCardDetail.startDate < today || (this.postCardDetail.startDate > this.postCardDetail.endDate)) {
            that._toaster.warning("Please enter valid Start Date and End Date");
            isValid = false;
            return isValid;
        }
        return isValid;

    }


    calculateDateString(values: any) {
        let that = this;
        let monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        //that.postCardDetail.startDate = values.start.toDate();
        //that.postCardDetail.endDate = values.end.toDate();
      that.postCardDetail.startDate = values[0];
      that.postCardDetail.endDate = values[1];

        if (that.postCardDetail.startDate && that.postCardDetail.endDate) {
            let str = ""
            str = monthNames[that.postCardDetail.startDate.getMonth()] + " " + that.postCardDetail.startDate.getDate() + ", " + that.postCardDetail.startDate.getFullYear() + " - " +
                monthNames[that.postCardDetail.endDate.getMonth()] + " " + that.postCardDetail.endDate.getDate() + ", " + that.postCardDetail.endDate.getFullYear();
            that.postCardDetail.dateString = str;
        } else {
            that.postCardDetail.dateString = null;
        }

    }

    openPreview(data: any) {

        if (data) {

            window.open("/api/util/file?path=" + data.withOutMarginModel.relativePath + "&name=" + data.withOutMarginModel.name
                + "&contentType=" + data.withOutMarginModel.mimeType, "_blank");
        }
    }

    clearPostCardData(form: NgForm) {
        let that = this;
        that.postCardDetail = {
            id: null,
            typeId: that.postCardDetail.typeId,
            name: "",
            nameOnCard: null,
            boothNumberOnCard: null,
            startDate: null,
            endCard: null,
            cityOnCard: null,
            stateOnCard: null,
            colorCodeOnCard: "",
            HTML: null,
            withOutMarginModel: null,
            withMarginModel: null,
            thumbnailModel: null,
            orderQuantity: null,
            minOrderQuantity: 100,
            maximumOrderQuantity: 500
        }
        form.resetForm();
    }

    quantitySelected(value: number, b: any) {
        b.orderQuantity = value;
    }

    onColorSelect() {
        console.log(this.postCardDetail.colorOnCard);
    }












}
