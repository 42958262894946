import { Subject } from "rxjs";

var lookupKeyName = 'dam_lookup';
var lookupDbKeyName = 'dam_lookupDb';
var lookupTypeKeyName = 'dam_lookupType';
var userIdentityKeyName = 'dam_user_identity';
var userRoleKeyName = 'dam_user_roles';
var cartKeyName = 'dam_cart_items';
var redirectUrl = 'redirect_url';
var managingCookiesName = 'managingCookies';
function _localStorage(): any {
    // return the global native browser window object
    return localStorage;
}

function _window(): any {
    // return the global native browser window object
    return window;
}

function _getSitePrefix() {
    let sitePrefix = '';
    let window = _window();
    let authTokenName: string = window.authTokenString;

    if (authTokenName && authTokenName.length > 0 && authTokenName.indexOf('-') > -1) {
        sitePrefix = authTokenName.split('-')[0];
    }
    return sitePrefix;
}

export class LocalStorageWrapper {

    getLookup() {
        var ls = _localStorage();
        var lookup = ls.getItem(lookupKeyName);

        if (lookup) {
            return JSON.parse(lookup);
        }
        else {
            return null;
        }
    }

    setLookup(lookup: any) {
        var ls = _localStorage();
        ls.setItem(lookupKeyName, JSON.stringify(lookup));
    }


    getLookupDb() {
        var ls = _localStorage();
        var lookupDb = ls.getItem(lookupDbKeyName);

        if (lookupDb) {
            return JSON.parse(lookupDb);
        }
        else {
            return null;
        }
    }

    setLookupDb(lookupDb: any) {
        var ls = _localStorage();
        ls.setItem(lookupDbKeyName, JSON.stringify(lookupDb));
    }

    getLookupType() {
        var ls = _localStorage();
        var lookupType = ls.getItem(lookupTypeKeyName);

        if (lookupType) {
            return JSON.parse(lookupType);
        }
        else {
            return null;
        }
    }

    setLookupType(lookupType: any) {
        var ls = _localStorage();
        ls.setItem(lookupTypeKeyName, JSON.stringify(lookupType));
    }

    getUserIdentity() {
        var userIdentity = localStorage.getItem(userIdentityKeyName);

      if (userIdentity && userIdentity != 'undefined') {
            return JSON.parse(userIdentity);
        }
        else {
            return null;
        }
    }

    setUserIdentity(data: any) {
        var ls = _localStorage();
        ls.setItem(userIdentityKeyName, JSON.stringify(data));
    }

    getRedirectURL() {
        var userIdentity = localStorage.getItem(redirectUrl);

        if (userIdentity) {
            return JSON.parse(userIdentity);
        }
        else {
            return null;
        }
    }

    setRedirectURL(data: any) {
        var ls = _localStorage();
        ls.setItem(redirectUrl, JSON.stringify(data));
    }

  purge() {
    
        var ls = _localStorage();

        ls.removeItem(lookupKeyName);
        ls.removeItem(lookupTypeKeyName);
        ls.removeItem(lookupDbKeyName);
        ls.removeItem(userIdentityKeyName);
        ls.removeItem(userRoleKeyName);
        ls.removeItem(cartKeyName);
        ls.removeItem(redirectUrl);
        ls.removeItem(managingCookiesName);
    }

    getUserRoles() {
        var userRoles = localStorage.getItem(userRoleKeyName);

        if (userRoles) {
            return JSON.parse(userRoles);
        }
        else {
            return null;
        }
    }

    setUserRoles(data: any) {
        var ls = _localStorage();
        ls.setItem(userRoleKeyName, JSON.stringify(data));
    }



    getCartItems() {
        var ls = _localStorage();

        var cartList = localStorage.getItem(cartKeyName);
        if (cartList) {
            return JSON.parse(cartList);
        }
        else {
            return [];
        }
    }


    setCartItems(data: any) {
        var ls = _localStorage();
        ls.setItem(cartKeyName, JSON.stringify(data));
    }

  setManagingWebsiteCookies(data: any) {
    var ls = _localStorage();
    ls.setItem(managingCookiesName, data);
  }
  getManagingWebsiteCookies() {
    var managingCookies = localStorage.getItem(managingCookiesName);

    if (managingCookies != '' && managingCookies != null && managingCookies != 'undefined') {
      return managingCookies;
    }
    else {
      return '';
    }
  }
}
