import { Inject, Injectable } from '@angular/core';
// import * as rxjs from 'rxjs/Rx.js'


// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/catch';
import { HttpWrapperService } from '../../../shared/services/http.wrapper.service';
import { HttpClientModule } from '@angular/common/http';


export type Credentials = {
    userName: string,
    password: string,
    submitted?: boolean,
    submittedSignin?: boolean
};

@Injectable()
export class LoginService {

    private static isLoggedIn: boolean = false;

    constructor(
        @Inject(HttpClientModule) private _http: HttpClientModule,
        @Inject(HttpWrapperService) private _httpWrapper: HttpWrapperService
    ) {
        //$('.preloader').hide();
    }

    login(userPass: Credentials): any {

        var that = this;

        let reject = function (errMessage: any) {
            throw new Error(errMessage);
        };

        let resolve = function (data: any) {
            LoginService.isLoggedIn = true;
            //that._identityService.setUserIdentityObject(data);


            return data;
        };

        let postParams = {
            url: '/api/auth/login',
            data: {
                UserName: userPass.userName,
                Password: userPass.password
            },
            headers: { skipfullpageloader: "true" }
        };

        var promise = this._httpWrapper.post(postParams);

        return promise.then(resolve, reject);
    }

    logout(): any {
        LoginService.isLoggedIn = false;

        return this._httpWrapper.get({ url: '/api/user/logout', headers: { skipfullpageloader: "true" } });
    }

    isLoggedIn(): boolean {
        return LoginService.isLoggedIn
    }

    sudoLogin(userId: number) {
        return this._httpWrapper.get({ url: '/api/user/session/sudo/' + userId });
    }     
}
