import { Injectable } from "@angular/core";

@Injectable()
export class LoggerService {

    log(data:any) {
        console.log(data);
    }

    error(err:any) {
        console.error(err);
    }


    warn(data: any) {
        console.warn(data);
    }

}