import { Inject, Injectable } from '@angular/core';
import { HttpWrapperService } from '../../../shared/services/http.wrapper.service';

@Injectable()
export class ForgotService {

    constructor(
        @Inject(HttpWrapperService) private _httpWrapper: HttpWrapperService
    ) { }


    forgotPassword(email: any) {
        return this._httpWrapper.post({ url: 'api/users/forgotpassword/sendpasswordlink?email=' + email });
    }

    checkResetExpire(token: any) {
        return this._httpWrapper.post({ url: "api/users/forgotpassword/checkexpire?token=" + token });
    }

    resetPassword(data: any) {
        return this._httpWrapper.post({ url: "api/users/forgotpassword/resetpassword", data: data });
    }
}