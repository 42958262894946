import { Injectable } from '@angular/core';
import { HttpWrapperService } from '../../shared/services/http.wrapper.service';


@Injectable()
export class PendingOrderService {

    constructor(private _httpWrapper: HttpWrapperService) {
    }

    GetOrder(id: number) {
        return this._httpWrapper.get({ url: "api/order/order/" + id });
    }

    ListOrder(data: any) {
        return this._httpWrapper.post({ url: "api/order/order/list", data: data });
    }

    UpdateOrderStatus(data: any) {
        return this._httpWrapper.post({ url: "api/order/order/update", data: data });
    }


    ModifyOrder(data: any) {
        return this._httpWrapper.post({ url: "api/order/order/modify", data: data });
    }

}





