import { Component } from '@angular/core';

@Component({
    selector: 'auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent {

    
}
