import { Component, OnInit } from '@angular/core';
import { LoggerService } from '../../shared/services/logger.service';
import { PortalImageService } from '../../admin/portal_image/services/portal.image.service';
import { LiteratureDetailService } from '../literature/services/literature.detail.service';
import { OrderItemCreatorService } from '../addtocart/service/order.item.creator.service';
import { Util } from '../../shared/services/util';
import { WindowRef } from '../../shared/services/window.client.service';
import { NgbCarouselConfig } from "@ng-bootstrap/ng-bootstrap";
import { OwlOptions } from "ngx-owl-carousel-o";
@Component({
    selector: 'home',
  templateUrl: './home.component.html',
    styleUrls: ['./home.component.css', '../footer/footer.component.css', '../literature/literature.sub.category.list.component.css']
})
export class HomeComponent implements OnInit {
    WindowRef = WindowRef;
    literatureList: any[] = [];
    private _loginHelperService: any;
    ngOnInit(): void {
        this.getPortalImage();
        this.GetNewlyAddedLiterature();
    }

    portal_image: any = {
        placeholder1File: {},
        placeholder2File: {},
        placeholder3File: {},
      placeholder4File: {},
      placeholder5File: {}
    }

    constructor(private _literatureSubCategoryListService: LiteratureDetailService,
        private _portalImageService: PortalImageService,
        public orderItemCreatorService: OrderItemCreatorService,
        private _logger: LoggerService,config: NgbCarouselConfig) {
    }

    GetNewlyAddedLiterature() {
        let that = this;
        that._literatureSubCategoryListService.GetNewlyAddedLiteratureList().then(data => {
            that._logger.log(data);
            that.literatureList = data;
            that.mapLiteratureCategoryLink();
        });

    }

    mapLiteratureCategoryLink() {
        let that = this;
        that.literatureList.map(function (item) {
            let data: any = Util.getCompleteUrlForCategoryId(WindowRef.lookup.product_type.literature, '#/' + WindowRef.appBaseUrl + '/literature', item.categoryId);
            item.category = data.category;
            item.url = data.url;
            return item;
        });

    }


    getPortalImage() {
        let that = this;
        this._portalImageService.GetPortalImage().then(function (data) {
            that.portal_image = data;
        });
    }

    quantitySelected(value: number, literature: any) {
        literature.orderQuantity = value;
        console.log(literature.orderQuantity);
    }
    show: boolean= true;
  closemodal(type) {
        this.show = !this.show;
      }

}
