import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from "ngx-cookie-service";
import { LoginService, Credentials } from './service/login.service';
import { WindowRef } from '../../shared/services/window.client.service';
import { LocalStorageWrapper } from '../../shared/services/local.storage.service';
import { NotifyFlashMessageService } from '../../shared/services/notify.flash.messages.service';
import { LoginHelperService } from '../../admin/common/services/login.helper.service';


@Component({
    selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
    WindowRef = WindowRef;
    private _window: any = null;
    interval: any;
    userRoles: any[] = [];
    credentialObj: Credentials = {
        userName: '',
        password: '',
    };

    constructor(private router: Router,
        private _loginService: LoginService,
        private _cookie: CookieService,
        private _loginHelperService: LoginHelperService,
    ) {
        let that = this;
      this._window = new WindowRef();
      var tokenObject: any = this._cookie.get('authTokenName');

        if (tokenObject && new WindowRef().nativeWindow['redirect_url'] != null) {
            if (tokenObject.UserTypeId == WindowRef.lookup.user_type.duke)
                this.router.navigate([WindowRef.redirect_url]);
            else {
                that.logout();
            }
        }
        else
            this._loginHelperService.navigateToDashboard(tokenObject);
        //console.log("Cookies");
        //console.log(tokenObject.UserTypeId);
        //console.log("Lookup");
        //console.log(WindowRef.lookup.user_type);

    }

    logout() {
        let that = this;
        var localStorageObj = new LocalStorageWrapper();

      localStorageObj.purge();
      that._cookie.delete("authTokenName");
        delete (new WindowRef()).nativeWindow['dam_user_identity'];
        delete (new WindowRef()).nativeWindow['redirect_url'];
        setTimeout(function () {
            //     that._router.navigate(['/auth/login']);
            that._window.nativeWindow.location.reload();
        }, 1000);
    }



    login(): void {
        var that = this;

        var reject = function () { };
        var resolve = function (data: any) {
            that._loginHelperService.setCookieObject(data);
        };

        return this._loginService.login(this.credentialObj).then(resolve, reject);
    }

    signup(): void {
        this.router.navigate(['/auth/register']);
    }

    forgot(): void {
        this.router.navigate(['/auth/forgot']);
    }
}
