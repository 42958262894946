import { HttpWrapperService } from "../../../shared/services/http.wrapper.service";
import { Injectable } from "@angular/core";



@Injectable()
export class ImageDetailService {

    constructor(private _httpWrapper:HttpWrapperService) {

    }

    GetSubCategoryList(data:any) {
        return this._httpWrapper.post({ url: "api/order/image/search", data: data });
    }


    GetImageDetail(id: number) {
        return this._httpWrapper.get({ url: "api/order/image/" + id });
    }




}