import { Component, OnInit, OnDestroy, ElementRef, ViewChild, Renderer, ChangeDetectorRef, ViewRef  } from "@angular/core";
import { WindowRef } from "../../shared/services/window.client.service";
import { LoggerService } from "../../shared/services/logger.service";
import { CookieService } from "ngx-cookie-service";
import { LocalStorageWrapper } from "../../shared/services/local.storage.service";
import { ConfirmationModelComponent } from "../common/confirmation.model.component";
import { NgbModalOptions, NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { NotifyFlashMessageService } from "../../shared/services/notify.flash.messages.service";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { CartMessageService } from "../../marketing/addtocart/service/cart.message.service";
import { Subscription } from "rxjs";
import { Util } from "../../shared/services/util";
import { ContactInfoComponent } from "../common/contact.info.component";
import * as $ from "jquery";
//declare var $: any;
@Component({
    selector: 'admin-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss', './../../marketing/navmenu/navmenu.component.css']

})
export class HeaderComponent implements OnInit, OnDestroy {
    menuList: any[] = [];
    WindowRef = WindowRef;
    newItem: any;
    public href: string = "";
    colapseNavBarCss = '';
    cartItems: any[] = [];
    subscription: Subscription;
    url: string = "";
  searchString = "";
  changeText: boolean;
  @ViewChild('navBarDiv', { static: false }) navBar: any;
    toggleBody: boolean;
    search_filter: any = {
        filter: {
            searchString: "",
            productTypeId: null,
        }
    }
    closeResult: string;

    constructor(private _loggerService: LoggerService,
        private _window: WindowRef,
        private _modalService: NgbModal,
        private _cookie: CookieService, private _activatedRoute: ActivatedRoute,
        private _toaster: NotifyFlashMessageService,
        private _router: Router,
        private _elm: ElementRef,
        private _cartMessageService: CartMessageService, private renderer: Renderer,
      private modalService: NgbModal,
      private cdref: ChangeDetectorRef) {
      this.changeText = false;
    }

    logout() {

        let that = this;
        var localStorageObj = new LocalStorageWrapper();

      localStorageObj.purge();
      that._cookie.delete("authTokenName");
        delete (new WindowRef()).nativeWindow['dam_user_identity'];
        delete (new WindowRef()).nativeWindow['redirect_url'];

        setTimeout(function () {
            that._window.nativeWindow.open("Home/logout", "_self");
        }, 1000);
    }

    ngOnInit() {
        let that = this;
        that.menuList = Util.getDynamicMenuForAdmin();
        that._loggerService.warn(that.menuList);
        that.getExistingCartItems();
        that.subscription = that._cartMessageService.getMessage().subscribe((message: any) => {
            if (message.item) {
                that.checkAndAddNewItem(message);
            } else {
                that.getExistingCartItems();
            }
        });
        this.url = this._router.url;
        if (this.url.indexOf("admin/search") > -1) {
            this._activatedRoute.queryParams.subscribe((params: Params) => {
                that.search_filter.filter.searchString = params['searchString'].trim();
                that.search_filter.filter.productTypeId = params['productTypeId'];
                if (!that.search_filter.filter.productTypeId) {
                    that.search_filter.filter.productTypeId = WindowRef.lookup.product_type.literature;
                }
                that.searchByString();
            });
        } else {
            that.search_filter = {
                filter: {
                    searchString: "",
                    productTypeId: null,
                }
            }
        }

    }

    ngOnDestroy(): void {
        let that = this;
      that.subscription.unsubscribe();
      setTimeout(() => {
        if (this.cdref !== null && this.cdref !== undefined &&
          !(this.cdref as ViewRef).destroyed) {
          this.cdref.detectChanges();
        }
      }, 250);
    }
  searchByString() {
    this._loggerService.log(this.searchString);
        this._router.navigate(["/admin/search"], { queryParams: { 'searchString': this.search_filter.filter.searchString, 'productTypeId': this.search_filter.filter.productTypeId } });
    }

    getExistingCartItems() {
        let that = this;
        var localStorageObj = new LocalStorageWrapper();
        that.cartItems = localStorageObj.getCartItems();
        for (var x = 0; x < that.cartItems.length; x++) {
            that.cartItems[x].sequence = x + 1;
        }
    }





    checkAndAddNewItem(message: any) {
        let that = this;
        var localStorageObj = new LocalStorageWrapper();
        let duplicateItem = that.cartItems.find(function (i) {
            return (message.item.id == i.id && message.item.isPdfRequired == i.isPdfRequired) && ((message.item.productTypeId == WindowRef.lookup.product_type.literature)
                || (message.item.productTypeId == WindowRef.lookup.product_type.image));
        });

        if (duplicateItem) {
            that._toaster.warning("Item already added");
            return;
        }

        that.cartItems.push(message.item);
        localStorageObj.setCartItems(that.cartItems);
        that._toaster.success("Item successfully added to your cart");
        return;

    }


    checkToLogout() {
        let that = this;
        let reject = function (result: any) {

        };
        let resolve = function (result: any) {
            if (result) {
                that.logout();
            }
        };

        let model = that._modalService.open(ConfirmationModelComponent, { size: 'sm' } as NgbModalOptions);
        model.componentInstance.confirmMessage = "Are you sure you want to logout!";
        model.result.then((result: any) => {
            resolve(result);
        }, (result: any) => {
            reject(result);
        });


    }

    openContactInfoModel() {
        let that = this;

        let reject = function (result: any) {

        };
        let resolve = function (data: any) {

        };
        let model = that._modalService.open(ContactInfoComponent, { size: 'lg' } as NgbModalOptions);
        // model.result.then();
        model.result.then((result: any) => {
            resolve(result);
        }, (result: any) => {
            reject(result);
        });

    }

    hideTopNavBar() {
        var el = this.navBar.nativeElement;
        if ($(el).hasClass('show')) {
            $(el).removeClass('show');
            $('body').css({ 'overflow': 'auto', 'position': 'relative' });
            this.toggleBody = !this.toggleBody;
        }
    }

    bodyHidden() {
        if (!this.toggleBody) {
            $('body').css({ 'overflow': 'hidden', 'position': 'fixed', 'width': '100%' });
        }
        else {
            $('body').css({ 'overflow': 'auto', 'position': 'relative' });
        }
        this.toggleBody = !this.toggleBody;
    }


      
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
  mouseoutchangeText() {
    this.changeText = false;
  }
}
