import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'duke',
  templateUrl: './duke.component.html',
    encapsulation: ViewEncapsulation.None
})
export class DukeComponent {

}
