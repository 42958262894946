import { Injectable } from "@angular/core";
import { HttpWrapperService } from "../../../shared/services/http.wrapper.service";
//var qs = require('qs');
import qs from 'qs';



@Injectable()
export class PortalImageService {

    constructor(private httpWrapper: HttpWrapperService) {

    }

    GetPortalImage() {
        return this.httpWrapper.get({ url: 'api/catalogue/portalImage/get' });
    }

    SavePortalImage(data: any) {
        return this.httpWrapper.post({ url: 'api/catalogue/portalImage', data: data });
    }

    GetPortalImageList() {
        return this.httpWrapper.get({ url: 'api/catalogue/portalImage/list' });
    }
}
