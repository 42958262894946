import { Inject, Injectable } from '@angular/core';
import { HttpWrapperService } from '../../../shared/services/http.wrapper.service';
import { NotifyFlashMessageService } from '../../../shared/services/notify.flash.messages.service';

@Injectable()

export class AddressDistributionService {
    constructor(private _httpWrapper: HttpWrapperService, private _toaster: NotifyFlashMessageService
    ) { }

    saveAddress(address: any) {
        return this._httpWrapper.post({ url: 'api/order/address/save', data: address });
    }

    getAddressList(userId: number) {
        return this._httpWrapper.get({ url: 'api/order/address/' + userId });
    }

    //distributeAddress(model: any) {
    //    return this._httpWrapper.post({ url: 'api/order/address/distribute', data: model });
    //}
}





