import { Component, OnInit } from '@angular/core';
import { NotifyFlashMessageService } from '../../shared/services/notify.flash.messages.service';
import { ForgotService } from '../forgot/service/forgot.service';
import { Router, ActivatedRoute } from "@angular/router";

@Component({
    selector: 'forgot',
  template: './reset.password.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ResetPasswordComponent implements OnInit {
    infoObj: any = {
        password: '',
        confirmPassword: '',
        resetPasswordToken: '',
    };

    ngOnInit(): void {
        let that = this;
        that.infoObj.resetPasswordToken = this._activatedRoute.snapshot.params['token'];
        if (that.infoObj.resetPasswordToken == null)
            return;

        this.checkResetExpire();
    }

    constructor(private router: Router,
        private _toaster: NotifyFlashMessageService,
        private _forgotPasswordService: ForgotService,
        private _activatedRoute: ActivatedRoute,
    ) {
    }

    reset() {
        let that = this;
        that.infoObj.resetPasswordToken = this._activatedRoute.snapshot.params['token'];

        if (that.infoObj.password == null || that.infoObj.confirmPassword == null || that.infoObj.password == '' || that.infoObj.confirmPassword == '')
            return;

        if (that.infoObj.password != that.infoObj.confirmPassword) {
            that._toaster.error("Password and confirm password do not match!");
            return;
        }

        that._forgotPasswordService.resetPassword(that.infoObj).then(function (response) {
            if (response) {
                that._toaster.success("Password has been changed successfully!");
                setTimeout(function () {
                    that.router.navigate(['/auth/login']);
                }, 3000);
            }
        });
    };

    checkResetExpire() {
        let that = this;

        that._forgotPasswordService.checkResetExpire(that.infoObj.resetPasswordToken).then(function (data) {
            if (!data) {
                that.router.navigate(['/auth/invalid']);
            }
        });
    };
}
