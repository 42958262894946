import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LoggerService } from "../../shared/services/logger.service";
import { WindowRef } from "../../shared/services/window.client.service";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { LiteratureService } from "./services/literature.service";
import { CategoryComponent } from "../common/category.component";
import { ManageKeywordModelComponent } from "../common/manage.keyword.model.component";
import { ConfirmationModelComponent } from "../common/confirmation.model.component";
import { Clock } from "../../shared/services/clock";
import { NotifyFlashMessageService } from "../../shared/services/notify.flash.messages.service";
import * as _ from 'lodash';
import { NgForm } from '@angular/forms';

@Component({
    selector: "edit-literature",
  templateUrl: "./literature.edit.component.html",
    styleUrls: ['./literature.edit.component.css']
})


export class LiteratureEditComponent implements OnInit {

  @ViewChild(CategoryComponent, { static: false }) categoryComponent: CategoryComponent;

    _isEdit: boolean = false;
    fileName: string = ""
    isLiteratureFileChanged: boolean = false;
    WindowRef = WindowRef;
    isValidNumber: boolean = true;

    literature: any = {
        name: null,
        categoryId: "",
        statusId: WindowRef.lookup.product_status.active,
        formNumber: "",
        revDate: "",
        versionNumber: 1,
        departmentId: "",
        literatureTypeId: WindowRef.lookup.literature_type.fulfillment,
        isPDFAvailable: false,
        minOrderQuantity: 1,
        maximumOrderQuantity: null,
        quantityOnHand: 1,
        lowWaterMarkQuantity: null,
        reOrderQuantity: null,
        keywords: [],
        activationDate: Clock.getUTCString(new Date()),
        file: null,
        leftMargin: .25,
        rightMargin: .25,
        topMargin: .25,
        bottomMargin: .25,
        costCenter: "",
        productionTypeId: WindowRef.lookup.literature_production_type.inventory,
        isUpdated: false,
        isPdfOnlyLiterature: false,
    }
    copyLiterature: any = {};

    //activationDate: null,
    //dateCreated: null,
    //dateUpdated: null,
    //updatedBy: null,
    //createdBy: null

    ngOnInit(): void {
        let that = this;
        let literatureId = parseInt(this._activatedRoute.snapshot.params['id']);
        if (literatureId) {
            that._isEdit = true;
            that.getLiterature(literatureId);
        } else {
            that._isEdit = false;
        }

        that._loggerService.log(WindowRef.lookup);
        that._loggerService.log(WindowRef.lookupType);
    }


    constructor(private _literatureService: LiteratureService,
        private _activatedRoute: ActivatedRoute,
        private _toaster: NotifyFlashMessageService,
        private _loggerService: LoggerService,
        private _router: Router,
        private _modalService: NgbModal
    ) {

    }

    saveLiterature(form: NgForm) {
        let that = this;
        that._loggerService.log(that.literature);
        that.populateFinalCategoryId();
        if (that.isLiteratureFileChanged && that._isEdit) {
            that.literature.versionNumber = that.literature.versionNumber + 1
        }

        if (!that.isValidNumber) {
            that._toaster.warning("Please enter a valid form number");
            return;
        }

        if (!that.validateOrderQuantity()) {
            return;
        }

        if (that._isEdit) {
            if (that.copyLiterature.revDate != "rev" + that.literature.revDate)
                that.literature.isRevDateUpdated = true;
            if (that.copyLiterature.statusId != that.literature.statusId)
                that.literature.isStatusUpdated = true;
            if (that.copyLiterature.productionTypeId != that.literature.productionTypeId)
                that.literature.isProductionTypeUpdated = true;
            if (that.copyLiterature.departmentId != that.literature.departmentId)
                that.literature.isProductionCodeUpdated = true;
            if (that.isLiteratureFileChanged)
                that.literature.isFileUpdated = true;
        }

        that._literatureService.SaveLiterature(that.literature).then(function (data) {
            that._loggerService.log(data);
            if (that._isEdit)
                that.navigateToList();
            else
                that.resetForm(form);
        }
            , function (err) {

            });
    }

    resetForm(form: NgForm) {
        let that = this;
        that.fileName = "";
        that.literature.formNumber = null;
        that.literature.name = null;
        that.literature.formNumber = null;
        that.literature.revDate = null;
        that.literature.minOrderQuantity = 1;
        that.literature.maximumOrderQuantity = null;
        that.literature.quantityOnHand = 1;
        that.literature.lowWaterMarkQuantity = null;
        that.literature.reOrderQuantity = null;
        that.isLiteratureFileChanged = false;
        that.literature.isPdfOnlyLiterature = false;
        that.literature.file = null;
        that.literature.costCenter = null;
        that.literature.productionTypeId = WindowRef.lookup.literature_production_type.inventory,
            that.literature.keywords = [],
            form.controls.formNumber.markAsUntouched();
        form.controls.name.markAsUntouched();
        form.controls.minOrderQuantity.markAsUntouched();
        form.controls.quantityOnHand.markAsUntouched();
        form.controls.maximumOrderQuantity.markAsUntouched();
        form.controls.lowWaterMarkQuantity.markAsUntouched();
        form.controls.reOrderQuantity.markAsUntouched();
    }

    validateOrderQuantity() {
        let that = this;
        let isValid = true;
        //if (that.literature.minOrderQuantity < 1 && isValid) {
        //    isValid = false;
        //    that._toaster.warning("Please enter valid minimum order quantity");
        //}

        if (that.literature.maximumOrderQuantity < 1 && isValid) {
            isValid = false;
            that._toaster.warning("Please enter valid maximum order quantity");
        }
        if (that.literature.productionTypeId == WindowRef.lookup.literature_production_type.inventory) {
            if (that.literature.lowWaterMarkQuantity < 1 && isValid) {
                isValid = false;
                that._toaster.warning("Please enter valid low water mark quantity");
            }

            if (that.literature.reOrderQuantity < 1 && isValid) {
                isValid = false;
                that._toaster.warning("Please enter valid re-order quantity");
            }
        }
        //if (that.literature.quantityOnHand < 1 && isValid) {
        //    isValid = false;
        //    that._toaster.warning("Please enter valid on-hand quantity");
        //}

        //if ((that.literature.minOrderQuantity > that.literature.maximumOrderQuantity) && isValid) {
        //    isValid = false;
        //    that._toaster.warning("Please enter valid Min and Max order quantity");
        //}

        return isValid;
    }

    navigateToList() {
        this._router.navigate(["/admin/manage-literature"]);
    }

    getLiterature(id: number) {
        let that = this;
        this._literatureService.GetLiterature(id).then(function (data) {
            that.literature = data;
            that.fileName = data.file && data.file.fileName || "";
            that.calculateAndPopulateCategory();
            that.copyLiterature = _.cloneDeep(that.literature);
        }
            , function (err) {

            });
    }

    checkUniqueNumber() {
        let that = this;
        that._literatureService.CheckUniqueNumber({ formNumber: that.literature.formNumber }).then(function (data) {
            that.isValidNumber = data.isValidNumber;
            if (!that.isValidNumber) {
                that._toaster.error("Please enter a valid form number");
            }
        }
            , function (err) {

            });
    }

    fileUploaded(responseObj: any) {
        let that = this;
        that._loggerService.log(responseObj);
        that.literature.file = responseObj.data;
        that.fileName = responseObj.data.fileName;
        that.isLiteratureFileChanged = true;
    }


    openManageKeywordsModel() {
        let that = this;
        let reject = function (result: any) {

        };
        let resolve = function (keywords: any) {
            that.literature.keywords = keywords;
        };
        let model = that._modalService.open(ManageKeywordModelComponent, { size: 'lg' } as NgbModalOptions);
        model.result.then((result) => {
            resolve(result);
        }, (result) => {
            reject(result);
        });
        model.componentInstance.isLiterature = true;
        model.componentInstance.name = that.literature.name;
        model.componentInstance.uniqueNo = that.literature.formNumber;
        model.componentInstance.selectedKeywords = that.literature.keywords;

        return model;
    }


    calculateAndPopulateCategory() {
        let that = this;
        let level = null;
        let tempCategoryId = null;
        if (that.literature.categoryId) {
            let found = WindowRef.lookupDb.category.literature.find(function (item: any) {
                return item.id == that.literature.categoryId;
            });
            if (found) {
                level = found.level;
            }
        }
        if (level == 3) {
            let found = WindowRef.lookupDb.category.literature.find(function (item: any) {
                return item.id == that.literature.categoryId;
            });
            if (found) {
                that.categoryComponent.selectedCategories.selectedLevel3CategoryId = found.id;
                that.categoryComponent.selectedCategories.selectedLevel2CategoryId = found.parentCategoryId;
                let foundAgain = WindowRef.lookupDb.category.literature.find(function (item: any) {
                    return item.id == found.parentCategoryId;
                });
                if (foundAgain) {
                    that.categoryComponent.selectedCategories.selectedLevel1CategoryId = foundAgain.parentCategoryId;
                }
            }
        }
        if (level == 2) {
            let found = WindowRef.lookupDb.category.literature.find(function (item: any) {
                return item.id == that.literature.categoryId;
            });
            if (found) {
                that.categoryComponent.selectedCategories.selectedLevel2CategoryId = found.id;
                that.categoryComponent.selectedCategories.selectedLevel1CategoryId = found.parentCategoryId;
            }
        }

        if (level == 1) {
            that.categoryComponent.selectedCategories.selectedLevel1CategoryId = that.literature.categoryId;
        }

        that.categoryComponent.setCategories();
        that._loggerService.log(that.categoryComponent.selectedCategories);
    }

    populateFinalCategoryId() {
        let that = this;
        if (that.categoryComponent.selectedCategories.selectedLevel3CategoryId) {
            that.literature.categoryId = parseInt(that.categoryComponent.selectedCategories.selectedLevel3CategoryId);
            return;
        }


        if (that.categoryComponent.selectedCategories.selectedLevel2CategoryId) {
            that.literature.categoryId = parseInt(that.categoryComponent.selectedCategories.selectedLevel2CategoryId);
            return;
        }


        if (that.categoryComponent.selectedCategories.selectedLevel1CategoryId) {
            that.literature.categoryId = parseInt(that.categoryComponent.selectedCategories.selectedLevel1CategoryId);
            return;
        }
    }

    changeStatus() {
        let that = this;
        let reject = function (result: any) {
            that.revertActivationStatus();
        };
        let resolve = function (result: any) {
            if (!result) {
                that.revertActivationStatus();
            } else {
                that.setActivationDate();
            }
        };

        let message = that.getMessageForChangeStatus();
        let model = that._modalService.open(ConfirmationModelComponent, { size: 'sm' } as NgbModalOptions);
        model.componentInstance.confirmMessage = message;
        model.result.then((result) => {
            resolve(result);
        }, (result) => {
            reject(result);
        });
    }

    revertActivationStatus() {
        let that = this;
        if (that.literature.statusId == WindowRef.lookup.product_status.active) {
            that.literature.statusId = WindowRef.lookup.product_status.inactive;
            that.literature.activationDate = Clock.getUTCString(new Date());

        } else if (that.literature.statusId == WindowRef.lookup.product_status.inactive) {
            that.literature.statusId = WindowRef.lookup.product_status.active;
        }
    }

    setActivationDate() {
        let that = this;
        if (that.literature.statusId == WindowRef.lookup.product_status.active) {
            that.literature.activationDate = Clock.getUTCString(new Date());
        } else if (that.literature.statusId == WindowRef.lookup.product_status.inactive) {
            that.literature.activationDate = null;
        }
    }

    getMessageForChangeStatus() {
        let that = this;
        if (that.literature.statusId == WindowRef.lookup.product_status.active) {
            return "Are you sure you want to activate the Literature";
        } else if (that.literature.statusId == WindowRef.lookup.product_status.inactive) {
            return "Are you sure you want to deactivate the Literature";
        }
    }
}
