import { Component, Inject } from '@angular/core';

import { HttpWrapperService } from '../../shared/services/http.wrapper.service';

@Component({
    selector: 'fetchdata',
  templateUrl: './fetchdata.component.html'
})
export class FetchDataComponent {
    public forecasts: WeatherForecast[];

    constructor(private _httpWrapper: HttpWrapperService, @Inject('BASE_URL') baseUrl: string) {
        let that = this;
        //http.get(baseUrl + 'api/SampleData/WeatherForecasts').subscribe(result => {
        //    this.forecasts = result.json() as WeatherForecast[];
        //}, error => console.error(error));
        this._httpWrapper.get({ url: baseUrl + 'api/SampleData/WeatherForecasts' }).then(function (data) {
            console.log(data);
            that.forecasts = data;
        }, function () { });
    }
}

interface WeatherForecast {
    dateFormatted: string;
    temperatureC: number;
    temperatureF: number;
    summary: string;
}
