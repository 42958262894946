import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { EventBundleService } from "./services/eventbundle.service";
import { WindowRef } from "../../shared/services/window.client.service";
import { ListRecordStringService } from "../common/services/list.record.string.service";
import { NotifyFlashMessageService } from "../../shared/services/notify.flash.messages.service";

@Component({
    selector: "eventbundle-list",
  templateUrl: "./eventbundle.list.component.html",

})

export class EventBundleListComponent extends ListRecordStringService implements OnInit {
    WindowRef = WindowRef;
    totalCount: number = 0;
    eventbundleList: any[] = [];
    search_filter: any = {
        filter: {
            name: "",
            StartDate: "",
            EndDate: "",
            isDetail: false
        },
        pageSize: 25,
        pageNumber: 1
    }
    isRecordExist: boolean = true;

    constructor(private _eventbundleService: EventBundleService,
        private _toaster: NotifyFlashMessageService,
        private _router: Router) {
        super();
    }

    ngOnInit(): void {
        let that = this;
        that.getList();
    }

    getList() {
        let that = this;
        that.isRecordExist = true;
        that._eventbundleService.GeteventBundleList(that.search_filter).then(function (data) {
            that.calculateRecordString(data, that.search_filter);
            that.eventbundleList = data.collection;
            that.totalCount = data.totalRecords
            that.isRecordExist = that.eventbundleList.length > 0;
        });
    }

    updateEventBundleStatus(item: any) {
        let that = this;
        let data = { id: item.id, statusId: item.statusId };
        if (item.isActive == true) {
            data.statusId = WindowRef.lookup.product_status.active;
        } else {
            data.statusId = WindowRef.lookup.product_status.inactive;
        }

        that._eventbundleService.UpdateEventBundleStatus(data).then(function (data) {
            if (data) {
                that._toaster.success("Successfully updated");
            } else {
                item.isActive = !item.isActive;
            }
        });
    }

    //mapActivationStatus() {
    //    let that = this;
    //    that.eventbundleList.map(function (item: any) {
    //        if (item.statusId == WindowRef.lookup.product_status.active) {
    //            item.isActive = true;
    //        } else {
    //            item.isActive = false;
    //        }
    //        return item;
    //    });
    //}

    navigateToEdit(id: number) {
        this._router.navigate(["/admin/eventbundle/" + id]);
    }

    clearAllFilters() {
        let that = this;
        that.search_filter = {
            filter: {
                name: "",
                StartDate: "",
                EndDate: "",
            },
            pageSize: 25,
            pageNumber: 1
        }
        that.getList();
    }
}
