import { Component, OnInit } from "@angular/core";
import { LoggerService } from "../../shared/services/logger.service";
import { Router, ActivatedRoute } from "@angular/router";
import { LiteratureService } from "./services/literature.service";
import { WindowRef } from "../../shared/services/window.client.service";
import { ListRecordStringService } from "../common/services/list.record.string.service";
import { NotifyFlashMessageService } from "../../shared/services/notify.flash.messages.service";




@Component({
    selector: "literature-list",
  templateUrl: "./literature.list.component.html",
    styleUrls: ['./literature.list.component.css']

})
export class LiteratureListComponent extends ListRecordStringService implements OnInit{
    WindowRef = WindowRef;
    totalCount: number = 0;
    literatureList: any[] = [];

    search_filter:any= {
        filter: {
            formNumber: "",
            name: "",
            costCenter: "",
            literatureTypeId: "",
            statusId: ""
        },
        pageSize: 25,
        pageNumber:1
    }

    constructor(private _literatureService: LiteratureService,
        private _loggerService: LoggerService,
        private _activeRoute: ActivatedRoute,
        private _toaster: NotifyFlashMessageService,
        private _router: Router) {
        super();
    }


    ngOnInit(): void {
        let that = this;
        that.getList();
    }

    getList() {
        let that = this;
        that._loggerService.log(that.search_filter);
        that._literatureService.GetLiteratureList(that.search_filter).then(function (data) {
            that._loggerService.log(data);
            that.calculateRecordString(data, that.search_filter);
            that.literatureList = data.collection;
            that.totalCount = data.totalRecords
            that.mapActivationStatus();
        });
    }


    updateLiteratureStatus(item: any) {
        let that = this;
        let data = { id: item.id, statusId: item.statusId };
        if (item.isActive == true) {
            data.statusId = WindowRef.lookup.product_status.active;
        } else {
            data.statusId = WindowRef.lookup.product_status.inactive;
        }

        that._literatureService.UpdateLiteratureStatus(data).then(function (data) {
            if (data) {
                that._toaster.success("Successfully updated");
            } else {
                item.isActive = !item.isActive;
            }
        });
    }


    mapActivationStatus() {
        let that = this;
        that.literatureList.map(function (item: any) {
            if (item.statusId == WindowRef.lookup.product_status.active) {
                item.isActive = true;
            } else {
                item.isActive = false;
            }
            return item;
        });
    }

    navigateToEdit(id:number) {
        this._router.navigate(["/admin/manage-literature/" + id]);
    }


    clearAllFilters() {
        let that = this;
        that.search_filter = {
            filter: {
                formNumber: "",
                name: "",
                costCenter: "",
                literatureTypeId: "",
                statusId:""
            },
            pageSize: 25,
            pageNumber: 1
        }
        that.getList();
    }



}
