import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LoggerService } from "../../shared/services/logger.service";
import { WindowRef } from "../../shared/services/window.client.service";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { CategoryComponent } from "../common/category.component";
import { ManageKeywordModelComponent } from "../common/manage.keyword.model.component";
import { ConfirmationModelComponent } from "../common/confirmation.model.component";
import { Clock } from "../../shared/services/clock";
import { NotifyFlashMessageService } from "../../shared/services/notify.flash.messages.service";

@Component({
    selector: "preview-portal-image",
  templateUrl: "./portal.image.preview.component.html",
    styleUrls: ['./portal.image.preview.component.css','../footer/footer.component.css']
})


export class PortalImagePreviewComponent implements OnInit {

    WindowRef = WindowRef;

    portal_image: any = {
        id:null,
        placeholder1FilePath: null,
        placeholder1FileMimeType : null,
        placeholder2FilePath: null,
        placeholder2FileMimeType: null,
        placeholder3FilePath: null,
        placeholder3FileMimeType: null,
        placeholder4FilePath: null,
        placeholder4FileMimeType: null,
    }

    ngOnInit(): void {
        let that = this;

        that._route.queryParams
            .subscribe(params => {
                console.log(params); // {order: "popular"}

                that.portal_image.placeholder1FilePath = params.placeholder1FilePath;
                that.portal_image.placeholder2FilePath = params.placeholder2FilePath;
                that.portal_image.placeholder3FilePath = params.placeholder3FilePath;
                that.portal_image.placeholder4FilePath = params.placeholder4FilePath;

                that.portal_image.placeholder1FileMimeType = params.placeholder1FileMimeType;
                that.portal_image.placeholder2FileMimeType = params.placeholder2FileMimeType;
                that.portal_image.placeholder3FileMimeType = params.placeholder3FileMimeType;
                that.portal_image.placeholder4FileMimeType = params.placeholder4FileMimeType;
                
                console.log(that.portal_image); // popular
            });
        that._loggerService.log(WindowRef.lookup);
        that._loggerService.log(WindowRef.lookupType);
    }


    constructor(
        private _activatedRoute: ActivatedRoute,
        private _toaster: NotifyFlashMessageService,
        private _loggerService: LoggerService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _modalService: NgbModal
    ) {

    }



 


    



 



}
