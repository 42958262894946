import { Component, OnInit, Input } from "@angular/core";
import { trigger, state, style, animate, transition } from '@angular/animations';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { LoggerService } from "../../shared/services/logger.service";
import * as _ from 'lodash';
import { ActivatedRoute, Router } from "@angular/router";
import { WindowRef } from "../../shared/services/window.client.service";
import { AddressDistributionService } from "../addtocart/service/address.distribution.service";
import { NotifyFlashMessageService } from "../../shared/services/notify.flash.messages.service";
import { LocalStorageWrapper } from '../../shared/services/local.storage.service';
import { ValidationMessageService } from '../../shared/services/validation.service';

@Component({
    selector: "distribute-address",
    templateUrl: "./address.distribute.component.html",
    animations: [
        trigger('fadeInOut', [
            transition('void => *', [
                style({ opacity: 0 }), //style only for transition transition (after transiton it removes)
                animate(700, style({ opacity: 1 })) // the new state of the transition(after transiton it removes)
            ]),
            transition('* => void', [
                animate(0, style({ opacity: 0 })) // the new state of the transition(after transiton it removes)
            ])
        ])
    ]

})

export class AddressDistributionComponent implements OnInit {
    WindowRef = WindowRef;
    addAddress: boolean = false;
    address: any = {};
    userId: any;
    selectedAddress: any[] = [];
    showList: boolean = true;
    cartItems: any[] = [];
    total: number = 0;
    selected: number = 0;
    @Input() addressList: any[] = [];
    validationMessages: any = null;

    manageAddress() {
        this.addAddress = !this.addAddress;
    }

    ngOnInit(): void {
        let that = this;
        //let addressList = this._activatedRoute.snapshot.params['selectedAddress'];
        if (that.addressList != null && that.addressList.length > 0) {
            that.showList = false;
            that.selectedAddress = that.addressList;
        }

        that.userId = WindowRef.userIdentity != null ? WindowRef.userIdentity.Id : 0;
        that.address.userId = that.userId;

        if (that.addressList == null || that.addressList.length <= 0)
            that.getAddressList();
    }

    constructor(private _activeModal: NgbActiveModal, private _toaster: NotifyFlashMessageService,
        private _addressDistributionService: AddressDistributionService
    ) {
        let that = this;
        that.validationMessages = ValidationMessageService;
    }

    getAddressList() {
        let that = this;
        that.userId = WindowRef.userIdentity != null ? WindowRef.userIdentity.Id : 0;


        that._addressDistributionService.getAddressList(that.userId).then(function (data) {
            that.addressList = data.collection;
        });
    }

    saveAddress() {
        let that = this;
        that.userId = WindowRef.userIdentity != null ? WindowRef.userIdentity.Id : 0;
        that.address.userId = that.userId;

        if (that.address.Address == null || that.address.City == null || that.address.State == null || that.address.ZipCode == null ||
            that.address.Address == "" || that.address.City == "" || that.address.State == "" || that.address.ZipCode == "") {
            that._toaster.error("Please input complete address!");
            return;
        }

        if (that.address.Name == null || that.address.Email == null || that.address.Name == "" || that.address.Email == "") {
            that._toaster.error("Please input name and email!");
            return;
        }

        that._addressDistributionService.saveAddress(that.address).then(function (data) {
            if (data) {
                that._toaster.success("Address added successfully!");
                that.address = { userId: that.userId };
                that.getAddressList();
            }
            else
                that._toaster.error("Unable to add Address!");
        });
    }

    selectAddress() {
        let that = this;
        that.showList = !that.showList;
        this.getExistingCartItems();
        that.selectedAddress = [];
        that.addressList.forEach(function (item: any) {
            item.cartItems = [];
            if (item.isSelected) {
                item.userId = that.userId;
                var listCartItems = _.cloneDeep(that.cartItems);
                listCartItems.forEach(function (cartItem: any) {
                    if (cartItem.orderQuantity > 0)
                        item.cartItems.push(cartItem);
                });
                that.selectedAddress.push(item);
            }
        });
    }

    distributeAddress() {
        let that = this;
        var invalidDistribution = false;
        this.getExistingCartItems();
        that.cartItems.forEach(function (item: any) {
            if (!invalidDistribution) {
                var result = that.checkDistributionQuantity(item);
                if (!result) {
                    that._toaster.error("Quantity distribution is not equal to total quantity!");
                    invalidDistribution = true;
                }
            }
        });

        if (invalidDistribution)
            return;

        var model = { userId: that.userId, collection: that.selectedAddress };
        that._activeModal.close(model);
        //that._addressDistributionService.distributeAddress(model).then(function (data) {
        //    if (data) {
        //        //that._toaster.success("Address added successfully!");
        //    }
        //});
    }

    checkDistributionQuantity(item: any) {
        let that = this;
        that.total = 0;
        that.selected = 0;
        that.selectedAddress.forEach(function (address: any) {
            address.cartItems.forEach(function (cartItem: any) {
                if (cartItem.sequence == item.sequence) {
                    that.total = cartItem.orderQuantity;
                    that.selected = (+that.selected) + (+cartItem.quantity);
                }
            });
        });
        if (that.total == null || that.total <= 0)
            return true;
        else if (that.total == that.selected)
            return true;
        else
            return false;
    }

    getExistingCartItems() {
        let that = this;
        var localStorageObj = new LocalStorageWrapper();
        that.cartItems = localStorageObj.getCartItems();
        for (var x = 0; x < that.cartItems.length; x++) {
            that.cartItems[x].sequence = x + 1;
        }
    }

    changeStatus(address: any) {
        address.isSelected = !address.isSelected;
    }

    clear() {
        let that = this;
        that.address = { userId: that.userId };
    }

    dismiss() {
        this._activeModal.dismiss();
    }
}
