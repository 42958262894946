import { Component, OnInit } from '@angular/core';
import { LocalStorageWrapper } from '../../shared/services/local.storage.service';
import { WindowRef } from '../../shared/services/window.client.service';

import { Subscription } from 'rxjs';
import { LoggerService } from '../../shared/services/logger.service';
import { ListRecordStringService } from '../../admin/common/services/list.record.string.service';
import { NgbDateStruct, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CartMessageService } from '../../marketing/addtocart/service/cart.message.service';
import { NotifyFlashMessageService } from '../../shared/services/notify.flash.messages.service';
import { ConfirmationModelComponent } from '../../admin/common/confirmation.model.component';
import * as _ from 'lodash';
import { UserOrderService } from './service/user.order.service';


@Component({
    selector: 'order-list',
  templateUrl: './user.order.list.component.html',
  styleUrls: ['./order.detail.component.css']
})
export class UserOrderListComponent extends ListRecordStringService implements OnInit {
    WindowRef = WindowRef;
    totalCount: number = 0;
    orderList: any[] = [];
    subscription: Subscription;
    activeIndex: number = 0;
    orderId: number;
    public daterange: any = {};

    public options: any = {
        locale: { format: 'YYYY-MM-DD' },
        alwaysShowCalendars: false,
    };

    public selectedDate(value: any, datepicker?: any) {
        datepicker.start = value.start;
        datepicker.end = value.end;

        this.daterange.start = value.start;
        this.daterange.end = value.end;
        this.daterange.label = value.label;
    }


    orderDetail: any = {
        cartItems: [],
        shippingDetail: {}
    };

    copyOrderDetail: any = {
        cartItems: [],
        shippingDetail: {}
    }

    search_filter: any = {
        filter: {
            date: "",
            orderId: "",
            statusId: ""
        },
        pageSize: 25,
        pageNumber: 1
    }

    constructor(private _cartMessageService: CartMessageService,
        private _orderService: UserOrderService,
        private _loggerService: LoggerService,
        private _toaster: NotifyFlashMessageService,
        private _modelService: NgbModal
    ) {
        super();
    }
    ngOnInit() {
        let that = this;
        that.getOrderList(true);
    }


    getOrderList(needUpdate: boolean) {
        let that = this;
        that._orderService.ListOrder(that.search_filter).then(function (data: any) {
            that.orderList = data.collection;
            that.totalCount = data.totalRecords;
            that.calculateRecordString(data, that.search_filter);
            that._loggerService.log(data);
            if (that.orderList.length && needUpdate) {
                that.getOrderDetail(that.orderList[0].id, 0);
            }

        })
    }


    getOrderDetail(id: number, index: number) {
        let that = this;
        that.orderId = id;
        that.activeIndex = index;
        that._orderService.GetOrder(id).then(function (data: any) {
            that.orderDetail = data;
            that.mapOrderStatusString();
            that.copyOrderDetail = _.cloneDeep(that.orderDetail);
            that._loggerService.log(data);
        })
    }


    mapOrderStatusString() {
        let that = this;
        that.orderDetail.cartItems.map(function (item: any) {
            var orderStatus = WindowRef.lookupDb.order_status.find(function (i: any) {
                return i.id == item.statusId;
            });

            var productType = WindowRef.lookupDb.product_type.find(function (i: any) {
                return i.id == item.productTypeId;
            });

            item.status = orderStatus && orderStatus.name || null;
            item.productType = productType && productType.name || null;
            return item;
        });
    }



    updateOrderStatus(cartItem: any) {
        let that = this;
        //that._orderService.UpdateOrderStatus({ id: cartItem.id, statusId: cartItem.statusId }).then(function (data) {
        //    that._loggerService.log(data);
        //    that._toaster.success("Successfully updated!");
        //    that.getOrderList(false);
        //    that.getOrderDetail(that.orderId, that.activeIndex);
        //});
    }


    clearAllFilters() {
        let that = this;
        that.search_filter = {
            filter: {
                userId: null,
                date: "",
                orderId: "",
                statusId: ""
            },
            pageSize: 25,
            pageNumber: 1
        }
        that.getOrderList(true);
    }

    changeStatus(cartItem: any) {
        let that = this;
        let reject = function (result: any) {
            that.revertChangedStatus(cartItem);
        };
        let resolve = function (result: any) {
            if (!result) {
                that.revertChangedStatus(cartItem);
            } else {
                that.updateOrderStatus(cartItem);
            }
        };

        let message = "Are You sure you want to change the existing status!";
        let model = that._modelService.open(ConfirmationModelComponent, { size: 'sm' } as NgbModalOptions);
        model.componentInstance.confirmMessage = message;
        model.result.then((result: any) => {
            resolve(result);
        }, (result: any) => {
            reject(result);
        });
    }


    revertChangedStatus(cartItem: any) {
        let that = this;

        let item = that.copyOrderDetail.cartItems.find(function (i: any) {
            return i.id == cartItem.id;
        });

        if (item) {
            cartItem.statusId = item.statusId;
        }

    }

    getCustomCss(order: any) {
        if (order.statusId == WindowRef.lookup.order_status.confirmed) {
            return 'badge-primary';
        } else if (order.statusId == WindowRef.lookup.order_status.shipped) {
            return 'badge-info';
        } else if (order.statusId == WindowRef.lookup.order_status.delivered) {
            return 'badge-success';
        } else if (order.statusId == WindowRef.lookup.order_status.cancelled) {
            return 'badge-danger';
        } else if (order.statusId == WindowRef.lookup.order_status.approval_pending) {
            return 'badge-warning';
        } else if (order.statusId == WindowRef.lookup.order_status.rejected) {
            return 'badge-danger';
        }
    }








}
