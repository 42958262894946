import { Component, OnInit, OnDestroy } from "@angular/core";
import { LoggerService } from "../../shared/services/logger.service";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Util } from "../../shared/services/util";
// import 'rxjs/add/operator/switchMap';
import { ListRecordStringService } from "../../admin/common/services/list.record.string.service";
import { WindowRef } from "../../shared/services/window.client.service";
import { LiteratureDetailService } from "./services/literature.detail.service";
import { OrderItemCreatorService } from "../addtocart/service/order.item.creator.service";

@Component({
    selector: 'literature-category-sub-list',
  templateUrl: './literature.sub.category.list.component.html',
    styleUrls: ['./literature.sub.category.list.component.css']
})
export class LiteratureSubCategoryListComponent extends  ListRecordStringService implements OnInit,OnDestroy {
    WindowRef = WindowRef;
    totalCount: number = 0;
    literatureList: any[] = [];
    subscribedRouterEvent: any = null;
    requestDetail: any = {
        level1: null,
        level2: null,
        level3: null,
    };
    search_filter: any = {
        filter: {
            level1: null,
            level2: null,
            level3: null,
            categoryId: null
        },
        pageSize: 24,
        pageNumber: 1
    }
    isRecordExist: boolean = true;

    ngOnInit(): void {
        let that = this;
        that.subscribedRouterEvent = that._activatedRoute.paramMap.subscribe((params: ParamMap) => { 
            that._loggerService.log(params);
            that.parseRequestDetail(params);
        });
        that._loggerService.log("LiteratureSubCategoryListComponent initialized");  
    }

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _literatureSubCategoryListService: LiteratureDetailService,
        public orderItemCreatorService:OrderItemCreatorService,
        private _loggerService: LoggerService) {
        super();

    }

  getList(pc: string) {
    let that = this;
    if (pc != "pagechange") { this.search_filter.pageNumber = 1; }
        that.isRecordExist = true;
        that._literatureSubCategoryListService.GetSubCategoryList(that.search_filter).then(data => {
            that._loggerService.log(data);
            that.calculateRecordString(data, that.search_filter);
            that.literatureList = data.collection;
            that.mapLiteratureCategoryLink();
            that.totalCount = data.totalRecords;
            that.isRecordExist = that.literatureList.length > 0;
        });
    }

    mapLiteratureCategoryLink() {
        let that = this;
        that.literatureList.map(function (item) {
            let data: any = Util.getCompleteUrlForCategoryId(WindowRef.lookup.product_type.literature, '#/' + WindowRef.appBaseUrl + '/literature', item.categoryId);
            item.category = data.category;
            item.url = data.url;
            return item;
        });

    }

    parseRequestDetail(params?: any):any {
        let that = this;
        params = params || that._activatedRoute.snapshot.params;

        that.requestDetail = {
            level1: params.get('level1')  || null,
            level2: params.get('level2')  || null,
            level3: params.get('level3')  || null
        };

        that.search_filter.filter.level1 = params.get('level1') && Util.decodeUrl(params.get('level1')) || null;
        that.search_filter.filter.level2 = params.get('level2') && Util.decodeUrl(params.get('level2')) || null;
        that.search_filter.filter.level3 = params.get('level3') && Util.decodeUrl(params.get('level3')) || null;

        that.calculateCategory(that.search_filter.filter);

        that.getList('');
        that._loggerService.log(that.requestDetail);  
    }

    calculateCategory(filter: any) {
        let that = this;
        let filterText:string;
        let parentCategoryId:number;
        let categoryId = null;

        if (filter.level1) {
            filterText = filter.level1.toLowerCase();
            let categoryl1 = WindowRef.lookupDb.category.literature.find((item:any) => {
                return filterText == item.name.toLowerCase();
            });
            if (categoryl1) {
                categoryId = categoryl1.id;
                parentCategoryId = categoryl1.id;
            }

        }

        if (filter.level2) {
            filterText = filter.level2.toLowerCase();
            let categoryl2 = WindowRef.lookupDb.category.literature.find((item: any) => {
                return filterText == item.name.toLowerCase() &&  item.parentCategoryId == parentCategoryId;
            });
            if (categoryl2) {
                categoryId = categoryl2.id;
                parentCategoryId = categoryl2.id;
            }
        }

        if (filter.level3) {
            filterText = filter.level3.toLowerCase();
            let categoryl3 = WindowRef.lookupDb.category.literature.find((item: any) => {
                return filterText == item.name.toLowerCase() && item.parentCategoryId == parentCategoryId;
            });
            if (categoryl3) {
                categoryId = categoryl3.id;
            }
        }


        that.search_filter.filter.categoryId = categoryId;
    }

    ngOnDestroy() {

        if (this.subscribedRouterEvent) {
            this.subscribedRouterEvent.unsubscribe();
        }
    }

    quantitySelected(value: number, literature: any) {
        literature.orderQuantity = value;
    }
}
