import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from "ngx-cookie-service";
import { RegisterService } from './service/register.service';
import { WindowRef } from '../../shared/services/window.client.service';
import { LocalStorageWrapper } from '../../shared/services/local.storage.service';
import { NotifyFlashMessageService } from '../../shared/services/notify.flash.messages.service';
import { LoggerService } from "../../shared/services/logger.service";
import { LoginHelperService } from '../../admin/common/services/login.helper.service';

@Component({
    selector: 'register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css','../../admin/common/common.component.css']
})
export class RegisterComponent {
    WindowRef = WindowRef;
    private _window: any = null;
    interval: any;
    infoObj: any = {
        firstName: '',
        lastName: '',
        email: '',
        billToAccount: ''
    };

    constructor(private router: Router,
        private _registerService: RegisterService,
        private _toaster: NotifyFlashMessageService,
        private _cookie: CookieService,
        private _loggerService: LoggerService,
        private _loginHelperService: LoginHelperService,
    ) {
        let that = this;
        this._window = new WindowRef();
    }

    register(): void {
        var that = this;
        that._loggerService.log(that.infoObj);

        if (that.infoObj.email == null) {
            that._toaster.error("Please enter email-Id!");
            return;
        }

        if (that.infoObj.firstName == null || that.infoObj.lastName == null) {
            that._toaster.error("Please enter valid Name!");
            return;
        }

        if ((that.infoObj.billToAccount == null || that.infoObj.billToAccount == '') &&( that.infoObj.providerCategoryId == null || that.infoObj.providerCategoryId <= 0)) {
            that._toaster.error("Please select a provider category or enter cost center!");
            return;
        }

        if (that.infoObj.password == null || that.infoObj.confirmPassword == null) {
            that._toaster.error("Please enter password!");
            return;
        }

        if (that.infoObj.password != that.infoObj.confirmPassword) {
            that._toaster.error("Password and Confirm password does't match!");
            return;
        }

        that._registerService.RegisterUser(that.infoObj).then(function (result) {
            that._loggerService.log(result);
            if (result.goToLogin) {
                that._loginHelperService.setCookieObject(result);
            }
            if (result.goToResponse) {
                that.router.navigate(['/auth/response']);
            }
        }
            , function (err) {
                // that._window.nativeWindow.location.reload();
            });
    }

    onSelectionChange(k: any) {
        this.infoObj.providerCategoryId = k.id;
    }

    login(): void {
        this.router.navigate(['/auth/login']);
    }
}
