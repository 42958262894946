import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { LoggerService } from "../../shared/services/logger.service";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Util } from "../../shared/services/util";
// import 'rxjs/add/operator/switchMap';
import { ListRecordStringService } from "../../admin/common/services/list.record.string.service";
import { WindowRef } from "../../shared/services/window.client.service";
import { LiteratureDetailService } from "./services/literature.detail.service";
import { OrderItemCreatorService } from "../addtocart/service/order.item.creator.service";



@Component({
    selector: 'literature-quantity-select',
  templateUrl: './literature.quantity.select.component.html',
    styleUrls: ['./literature.quantity.select.component.css']
})
export class LiteratureQuantitySelectComponent implements OnInit {
  

    @Input() name: string = 'name';
    @Input() minOrderQuantity: number; 
    @Input() maxOrderQuantity: number; 
    @Input() selectedQuantity: any =""; 
    @Output() output: EventEmitter<any> = new EventEmitter<any>();
    quantities: number[] = [];
    constructor(
        private _logger:LoggerService,
        private _window: WindowRef) {
    }

    ngOnInit(): void {
        let that = this;
        this.createQuantity();
    }

    public onChange() {
        this.output.emit(this.selectedQuantity);    
    }

    createQuantity() {
        let that = this;
        let i = 1
        let count = that.maxOrderQuantity / that.minOrderQuantity;
        while (i <= count) {
              that.quantities.push(that.minOrderQuantity*i);
              i++
        }
    }
  

    




}
