import { animate, style, transition, trigger } from '@angular/animations';
import { Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "terms-conditions",
  templateUrl: "./terms.conditions.components.html",
    styleUrls: ['../literature/literature.edit.component.css'],
    animations: [
        trigger('fadeInOut', [
            transition('void => *', [
                style({ opacity: 0 }), //style only for transition transition (after transiton it removes)
                animate(700, style({ opacity: 1 })) // the new state of the transition(after transiton it removes)
            ]),
            transition('* => void', [
                animate(0, style({ opacity: 0 })) // the new state of the transition(after transiton it removes)
            ])
        ])
    ]
})

export class TermsConditionsComponent {

    constructor(private _activeModal: NgbActiveModal) {
    }

    dismiss() {
        this._activeModal.dismiss();
    }
}


