import { MarketingComponent } from "./marketing.component";
import { HomeComponent } from "./home/home.component";
import { FetchDataComponent } from "./fetchdata/fetchdata.component";
import { FooterComponent } from "./footer/footer.component";
import { ImageSubCategoryListComponent } from "./image/image.sub.category.list.component";
import { LiteratureSubCategoryListComponent } from "./literature/literature.sub.category.list.component";
import { ImageDetailComponent } from "./image/image.detail.component";
import { LiteratureDetailComponent } from "./literature/literature.detail.component";
import { CartDetailComponent } from "./addtocart/cart.detail.component";
import { OrderDetailComponent } from "./addtocart/order.detail.component";
import { BuisnessCardComponent } from "./businesscard/businesscard.component";
import { UserOrderListComponent } from "./addtocart/user.order.list.component";
import { AuthGuard } from "../auth/auth.role.service";
import { LiteratureQuantitySelectComponent } from "./literature/literature.quantity.select.component";
import { PostCardComponent } from "./postcard/postcard.component";
import { SearchComponent } from "./search/search.component";
import { AddressDistributionService } from "./addtocart/service/address.distribution.service";
import { AddressDistributionComponent } from "./addtocart/address.distribute.component";
import { SalesBundleDetailComponent } from "./../admin/eventbundle/salesBundle.detail.component";
import { EventBundleDetailComponent } from "./../admin/eventbundle/eventbundle.detail.component";

export class Marketing {
    static getAllComponents(): any {
        return [
            MarketingComponent,
            HomeComponent,
            FetchDataComponent,
            FooterComponent,
            ImageSubCategoryListComponent,
            LiteratureSubCategoryListComponent,
            ImageDetailComponent,
            LiteratureDetailComponent,
            CartDetailComponent,
            BuisnessCardComponent,
            OrderDetailComponent,
            UserOrderListComponent,
            LiteratureQuantitySelectComponent,
            PostCardComponent,
            SearchComponent,
            AddressDistributionComponent,
            EventBundleDetailComponent,
            SalesBundleDetailComponent
        ]
    }


    static getAllPaths(): any {
        return {
            path: 'app', component: MarketingComponent, canActivate: [AuthGuard], children: [
                { path: '', component: HomeComponent, data: { title: 'Dashboard' } },
                { path: 'home', component: HomeComponent, data: { title: 'Dashboard' } },
                { path: 'fetch-data', component: FetchDataComponent },
                { path: 'get-cart', component: CartDetailComponent },

                { path: 'event-bundle', component: EventBundleDetailComponent },
                { path: 'sales-bundle', component: SalesBundleDetailComponent },

                { path: 'literature/:id/add-to-cart', component: CartDetailComponent },
                { path: 'literature/:id/detail', component: LiteratureDetailComponent },
                { path: 'literature/:level1/:level2', component: LiteratureSubCategoryListComponent, data: { title: 'Literature' } },
                { path: 'literature/:level1', component: LiteratureSubCategoryListComponent, data: { title: 'Literature' } },
                { path: 'literature/:level1/:level2/:level3', component: LiteratureSubCategoryListComponent, data: { title: 'Literature' } },
                { path: 'literature', component: LiteratureSubCategoryListComponent, data: { title: 'Literature' } },
                
                { path: 'image/:id/detail', component: ImageDetailComponent },
                { path: 'image/:level1/:level2/:level3', component: ImageSubCategoryListComponent, data: { title: 'Image' } },
                { path: 'image/:level1/:level2', component: ImageSubCategoryListComponent, data: { title: 'Image' } },
                { path: 'image/:level1', component: ImageSubCategoryListComponent, data: { title: 'Image' } },
                { path: 'image', component: ImageSubCategoryListComponent, data: { title: 'Image' } },
                { path: 'card/:id', component: BuisnessCardComponent },
                { path: 'postcard/:id', component: PostCardComponent, data: { title: 'Postcards' } },
                { path: 'order-detail', component: OrderDetailComponent },
                { path: 'order/list', component: UserOrderListComponent },
                { path: 'search', component: SearchComponent },
                { path: '**', redirectTo: 'home' }
            ]
        }

    }

}
