import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';


//------------------------ new pages register here
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { NgxMaskModule } from 'ngx-mask';
import { Admin } from './admin/index';
import { AdminComponent } from './admin/admin.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { Authentication } from './auth/index';
import { AuthComponent } from './auth/auth.component';
import { LoginComponent } from './auth/login/login.component';
import { ForgotComponent } from './auth/forgot/forgot.component';
import { ResetComponent } from './auth/reset/reset.component';
import { LoginService } from './auth/login/service/login.service';
import { LoginHelperService } from './admin/common/services/login.helper.service';
import { MarketingComponent } from './marketing/marketing.component';
import { HomeComponent } from './marketing/home/home.component';
import { FetchDataComponent } from './marketing/fetchdata/fetchdata.component';
import { NavMenuComponent } from './marketing/navmenu/navmenu.component';
import { AppComponent } from './base/app.component';
import { Marketing } from './marketing/index';

//import { AuthInterceptor } from './shared/services/auth.interceptor.service';
import { HttpWrapperService } from './shared/services/http.wrapper.service';
import { CookieService } from 'ngx-cookie-service';
import { WindowRef } from './shared/services/window.client.service';
import { LoggerService } from './shared/services/logger.service';
import { Shared } from './shared/index';
import { LocalStorageWrapper } from './shared/services/local.storage.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LiteratureService } from './admin/literature/services/literature.service';
import { KeywordService } from './admin/common/services/keyword.service';
import {KeywordsComponent} from "./admin/image/keywords/keywords.component";
import {UploadImageComponent} from "./admin/image/upload-image/upload.image.component";
import { Debounce } from './shared/directive/debounce.directive';
import { ImageService } from './admin/image/services/image.service';
import { ListRecordStringService } from './admin/common/services/list.record.string.service';
import { ManageKeywordModelComponent } from './admin/common/manage.keyword.model.component';
import { ConfirmationModelComponent } from './admin/common/confirmation.model.component';
import { FlashMessagesModule } from 'flash-messages-angular';
import { NotifyFlashMessageService } from './shared/services/notify.flash.messages.service';
import { ImageDetailService } from './marketing/image/service/image.detail.service';
import { LiteratureDetailService } from './marketing/literature/services/literature.detail.service';
//import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CartMessageService } from './marketing/addtocart/service/cart.message.service';
import { OrderItemCreatorService } from './marketing/addtocart/service/order.item.creator.service';
import { BusinessCardService } from './marketing/businesscard/services/businesscard.service';
import { OrderService } from './duke/order/order.service';
import { Duke } from './duke/index';
import { UserOrderService } from './marketing/addtocart/service/user.order.service';
import { AuthInitialDataGuard } from './auth/auth.initial.data.service';
import { AuthGuard } from './auth/auth.role.service';
import { NgxSpinnerModule} from 'ngx-spinner';
import { PortalImageService } from './admin/portal_image/services/portal.image.service';
import { PostCardService } from './marketing/postcard/services/postcard.service';
import { ColorPickerModule } from 'ngx-color-picker';
//import { NgxDateRangeModule } from 'ngx-daterange';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SearchService } from './marketing/search/service/search.service';

import { RegisterService } from './auth/register/service/register.service';

import { UserService } from './admin/user/services/user.service';
import { UpdateStatusComponent } from './admin/user/update.status.component';

import { AddressDistributionComponent } from './marketing/addtocart/address.distribute.component';
import { AddressDistributionService } from './marketing/addtocart/service/address.distribution.service';
import { ContactInfoComponent } from './admin/common/contact.info.component';
import { PendingOrderService } from './admin/order/pending.order.service';
import { AdminAddressDistributionComponent } from './admin/order/admin.order.distribution.component';

import { ActivityService } from './admin/activityReporting/activity.service';
import { TermsConditionsComponent } from "./admin/common/terms.conditions.component";
import { EventBundleService } from './admin/eventbundle/services/eventbundle.service';
import { ForgotService } from './auth/forgot/service/forgot.service';
import { InvalidLinkComponent } from './auth/forgot/invalid.link.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { from } from 'rxjs';
import { CarouselModule } from 'ngx-owl-carousel-o';
import {IvyCarouselModule} from 'angular-responsive-carousel';


var allRoutes: any = [
    { path: '', redirectTo: 'auth', pathMatch: 'full' }
];

allRoutes.push(Authentication.getAllPaths());
allRoutes.push(Admin.getAllPaths());
allRoutes.push(Marketing.getAllPaths());
allRoutes.push(Duke.getAllPaths());

allRoutes.push({ path: '**', redirectTo: 'app' });

/**
 * Here we add Components
 */
var declarations = [
    AppComponent,
    NavMenuComponent,
    Debounce
] 

declarations = declarations.concat(Shared.getAllComponents());
declarations = declarations.concat(Authentication.getAllComponents());
declarations = declarations.concat(Admin.getAllComponents());
declarations = declarations.concat(Marketing.getAllComponents());
declarations = declarations.concat(Duke.getAllComponents());



@NgModule({
  declarations: [
    AppComponent,  
    declarations
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    //CarouselModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    NgbModule,
        ColorPickerModule,
    AngularFontAwesomeModule,
        RouterModule.forRoot(allRoutes, { useHash: true }),
        FlashMessagesModule.forRoot(),
    NgxSpinnerModule,
    NgxMaskModule.forRoot(),
    BrowserAnimationsModule,
    CarouselModule ,
    IvyCarouselModule
  ],
providers: [       
        {
            provide: APP_INITIALIZER,
           useFactory: loadConfigFactory,
            deps: [HttpClient],
            multi: true
        },
   
        AuthGuard,
        AuthInitialDataGuard,
        HttpWrapperService,
        NotifyFlashMessageService,
        CookieService,
        WindowRef,
        LoggerService,
        LoginService,
        LoginHelperService,
        LiteratureService,
        EventBundleService,
        ImageService,
        KeywordService,
        LiteratureDetailService,
        ImageDetailService,
        CartMessageService,
        OrderItemCreatorService,
        BusinessCardService,
        PostCardService,
        OrderService,
        UserOrderService,
        PortalImageService,
        SearchService,
        RegisterService,
        UserService,
        AddressDistributionService,
        PendingOrderService,
        ActivityService,
        ForgotService
        
    ], entryComponents: [
        ManageKeywordModelComponent,
        ConfirmationModelComponent,
        KeywordsComponent,
        UploadImageComponent,
        UpdateStatusComponent,
        AddressDistributionComponent,
        ContactInfoComponent,
        AdminAddressDistributionComponent,
        TermsConditionsComponent,
        InvalidLinkComponent
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function loadConfigFactory(http: HttpClient) {
    return function () {      
        var localStorageObj = new LocalStorageWrapper();
        var lookup = localStorageObj.getLookup();
        var lookupDb = localStorageObj.getLookupDb();
        var lookupType = localStorageObj.getLookupType();
        var userRoles = localStorageObj.getUserRoles();

        let user_Identity = (new WindowRef()).nativeWindow['dam_user_identity'];
        let redirect_URL = (new WindowRef()).nativeWindow['redirect_url'];
        localStorageObj.setUserIdentity(user_Identity);
        localStorageObj.setRedirectURL(redirect_URL);

        WindowRef.userIdentity = user_Identity;
        WindowRef.redirect_url = redirect_URL;

        var userIdentity = localStorageObj.getUserIdentity();

        // || !userRoles 
        if (!lookup || !lookupType || !lookupDb || !userIdentity) {
          http.get('/api/util/lookup/database').subscribe(function (result: any) {
                console.log('Fetched Lookup');

                if (result.data) {
                    let l_Data = makeLookUpData(result.data);
                    WindowRef.lookup = l_Data;
                    WindowRef.lookupDb = result.data;

                    localStorageObj.setLookup(makeLookUpData(result.data));
                    localStorageObj.setLookupDb(result.data);
                }
            });

          http.get('/api/util/lookup/types').subscribe(function (result: any) {
                console.log('Fetched Lookup Type');
                if (result.data) {
                    WindowRef.lookupType = result.data;
                    localStorageObj.setLookupType(result.data);
                }                
            });
                              
        }
        else {
            WindowRef.lookup = lookup;
            WindowRef.lookupType = lookupType;
            WindowRef.lookupDb = lookupDb;
            WindowRef.userIdentity = userIdentity;
            WindowRef.userRoles = userRoles;
            console.log(WindowRef.userIdentity);
            console.log('Lookup, LookupType and userIdentity read from local storage');
        }
    };
}


export function makeLookUpData(lookUpDb: any) {
    let lookUpData:any = {};
    let ingnoreKeys = ["category", "i_addresses", "i_business_cost_centers", "i_literature_cost_centers","i_all_cost_center"]; 
    for (var key in lookUpDb) {
        let hasIgnored = ingnoreKeys.find(function (item) {
            return item == key
        });
        if (lookUpDb.hasOwnProperty(key) && !hasIgnored) {
            let tempData:any = {

            }
            lookUpDb[key].forEach(function (i: any) {
                tempData[i.alias.toLowerCase()] = i.id;
            });

            lookUpData[key] = tempData;
        }       
    }

    return lookUpData;

}





