import { Component, OnInit, Input } from "@angular/core";
import { trigger, state, style, animate, transition } from '@angular/animations';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { UserService } from "./services/user.service";
import { LoggerService } from "../../shared/services/logger.service";
import * as _ from 'lodash';
import { ActivatedRoute, Router } from "@angular/router";
import { WindowRef } from "../../shared/services/window.client.service";

@Component({
    selector: "update-status",
  templateUrl: "./update.status.component.html",
    animations: [
        trigger('fadeInOut', [
            transition('void => *', [
                style({ opacity: 0 }), //style only for transition transition (after transiton it removes)
                animate(700, style({ opacity: 1 })) // the new state of the transition(after transiton it removes)
            ]),
            transition('* => void', [
                animate(0, style({ opacity: 0 })) // the new state of the transition(after transiton it removes)
            ])
        ])
    ]

})
export class UpdateStatusComponent implements OnInit {
    Id: number = 0;
    statusList: any[] = [];
    user: any = {}
    WindowRef = WindowRef;

    constructor(private _userService: UserService, private _activeModal: NgbActiveModal, private _loggerService: LoggerService,
        private _activatedRoute: ActivatedRoute) {

    }

    ngOnInit(): void {
        let that = this;
        that.getStatus();
        if (this.Id > 0)
            that.getUser(this.Id);
    }

    getStatus() {
        var that = this;
        that._userService.getStatus().then(function (result) {
            if (result != null && result.length > 0) {
                that.statusList = result;
            }
        });
    }

    getUser(id: number) {
        let that = this;
        this._userService.GetUser(id).then(function (data) {
            that.user = data;
        }
            , function (err) {

            });
    }

    saveUser() {
        let that = this;
        that._userService.SaveUser(that.user).then(function (data) {
            that._activeModal.close(data);
        }
            , function (err) {

            });
    }

    confirmToChangeStatus() {
        let that = this;
        if (!that.user.statusId) {
            return;
        }
        that.saveUser();
    }

    close() {
        let that = this;
        that._activeModal.close(false);
    }

    dismiss() {
        this._activeModal.dismiss();
    }
}
