import * as moment from 'moment';
import Moment = moment.Moment



export class Clock {

    private static dateFormat: string = 'MM/DD/YYYY';
    private static dateServerFormat: string = 'YYYY-MM-DD';
    private static timeFormat: string = 'HH:mm:ss';
    private static timeFormatWithMeridian: string = 'h:mm A';
    private static dateTimeFormat: string = Clock.dateFormat + ' ' + Clock.timeFormatWithMeridian;


    public static getFormats() {
        return {
            DateOnly: Clock.dateFormat,
            TimeOnly: Clock.timeFormat,
            DateTime: Clock.dateTimeFormat,
            TimeFormatWithMeridian:Clock.timeFormatWithMeridian
        };
    }



    static now(): string {
        return Clock.nowRaw().format(Clock.dateTimeFormat);
    }

    static nowRaw(): Moment {
        return moment();
    }

    static date(dateObj?: any, serverFormat?: boolean): string {
        let format = (serverFormat) ? Clock.dateServerFormat : Clock.dateFormat;
        return Clock.dateRaw(dateObj).format(format);
    }

    static dateInServerFormat(dateObj?: any): string {
        return Clock.dateRaw(dateObj).format(Clock.dateServerFormat);
    }

    //todo need to discuss with atanu (there is a difference between date and dateRaw)
    static dateRaw(dateObj?: any): Moment {
        if (!dateObj)
            return moment();

        return moment(dateObj);
    }

    static dateTimeFormatFromString(date: string): string {

        return moment(date).format(Clock.dateTimeFormat);
    }

    static timeStamp(dateObj?: Date): string {
        if (!dateObj)
            return moment().format(Clock.timeFormat);

        return moment(dateObj).format(Clock.timeFormat);
    }

    static dateTime(dateObj?:any): string {
        if (!dateObj)
            return moment().format(Clock.dateTimeFormat);

        return moment(dateObj).format(Clock.dateTimeFormat);
    }

    static isValidDateTime(dateObj?:any): boolean {
        return moment(dateObj).isValid();
    }

    

    static getUTCString(date: any): string
    {
        return moment.utc(date).format();
    }

    static secondsToHourMinuteFormat(inputSeconds: number): string {
        var hours: any = Math.floor(inputSeconds / 3600);
        var minutes: any = Math.floor((inputSeconds - (hours * 3600)) / 60);
        var seconds: any = inputSeconds - (hours * 3600) - (minutes * 60);

        if (hours < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        if (seconds < 10) {
            seconds = "0" + seconds;
        }

        return hours + 'h ' + minutes + 'm';
    }

    static secondsToHourMinuteSecondFormat(inputSeconds: number): string {
        var hours: any = Math.floor(inputSeconds / 3600);
        var minutes: any = Math.floor((inputSeconds - (hours * 3600)) / 60);
        var seconds: any = inputSeconds - (hours * 3600) - (minutes * 60);

        if (hours < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        if (seconds < 10) {
            seconds = "0" + seconds;
        }

        return hours + 'h ' + minutes + 'm ' + seconds + 's';
    }

    static formatTime(dateString: string): string {
        var d = new Date(dateString);
        var hour: any = d.getHours();
        var minute: any = d.getMinutes();
        var amPm = "PM";

        if (hour < 12) {
            amPm = "AM";
        }
        else if (hour > 12) {
            hour = hour - 12;
        }

        if (hour < 10)
            hour = "0" + hour;

        if (minute < 10) {
            minute = "0" + minute;
        }

        return hour + ":" + minute + " " + amPm;
    }

    static checkDateIsAfter(higherDate:any, lowerDate:any) {
        return moment(higherDate).isAfter(lowerDate, 'day');
    }

    static getStartOrEndDate(date: Date, isStartDate: boolean) {
        let formattedDate = new Date(date);
        if (isStartDate) {
            formattedDate.setHours(0);
            formattedDate.setMinutes(0);
        }
        else {
            formattedDate.setHours(23);
            formattedDate.setMinutes(59)
        }

        return formattedDate;
    }
}
