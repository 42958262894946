import { Component, OnInit } from '@angular/core';
import { WindowRef } from '../../shared/services/window.client.service';
import { ListRecordStringService } from '../../admin/common/services/list.record.string.service';
import { ActivityService } from './activity.service';
import { Router, ActivatedRoute } from "@angular/router";

@Component({
    selector: 'activity-detail',
  templateUrl: './activity.detail.component.html',
 //   styles: [require('../../duke/order/order.detail.component.css')]
})
export class ActivityDetailComponent extends ListRecordStringService implements OnInit {
    WindowRef = WindowRef;
    totalCount: number = 0;
    orderId: number = 0;
    public daterange: any = {};
    public options: any = {
        locale: { format: 'YYYY-MM-DD' },
        alwaysShowCalendars: false,
    };
    downloading: boolean = false;

    public selectedDate(value: any, datepicker?: any) {
        datepicker.start = value.start;
        datepicker.end = value.end;

        this.daterange.start = value.start;
        this.daterange.end = value.end;
        this.daterange.label = value.label;
    }

    activityDetail: any = {};

    search_filter: any = {
        filter: {
            id: "",
        },
        order: 0,
        propName: "",
    }

    constructor(private _activityService: ActivityService,
        private _activatedRoute: ActivatedRoute,
    ) {
        super();
    }

    ngOnInit() {
        let that = this;
        let orderId = parseInt(this._activatedRoute.snapshot.params['id']);
        that.search_filter.filter.id = orderId;
        that.getActivityDetail(that.search_filter);
    }

    SortColumns: any = {
        FormNumber: 'FormNumber',
        AssetName: 'AssetName',
        CostCenter: 'CostCenter',
        Quantity: 'Quantity',
        Level1: 'Level1',
        Level2: 'Level2',
        Level3: 'Level3',
    }

    getActivityDetail(search_filter: any) {
        let that = this;
        that.orderId = search_filter.filter.id;
        that._activityService.GetActivity(search_filter).then(function (data: any) {
            that.activityDetail = data;
        })
    }

    sorting(propName: string) {
        let that = this;
        that.search_filter.propName = propName;
        that.search_filter.order = (that.search_filter.order == 0) ? 1 : 0;
        that.getActivityDetail(that.search_filter);
    }

    exportToExcel() {
        let that = this;
        that.downloading = true;
        that._activityService.exportDetailToExcel(that.search_filter).then(function (result: any) {
            var fileName = "orderDetail.xlsx";
            that.downloadFile(result, fileName);
            that.downloading = false;
        })
    }

    exportToCSV() {
        let that = this;
        that.downloading = true;
        that._activityService.exportDetailToCSV(that.search_filter).then(function (result: any) {
            var fileName = "orderDetail.csv";
            that.downloadFile(result, fileName);
            that.downloading = false;
        })
    }


    downloadFile(data: any, fileName: any) {
        var file = new Blob([data], {
            type: 'application/octet-binary'
        });
        var fileUrl = URL.createObjectURL(file);

        // for IE 10+
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(file, fileName);
        } else {
            var element = document.createElement('a');
            element.href = fileUrl;
            element.setAttribute('download', fileName);
            element.setAttribute('target', '_blank');
            document.body.appendChild(element);
            element.click();
        }
    };
}
