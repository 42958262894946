import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LoggerService } from "../../shared/services/logger.service";
import { WindowRef } from "../../shared/services/window.client.service";
import { NotifyFlashMessageService } from "../../shared/services/notify.flash.messages.service";
import { PortalImageService } from "./services/portal.image.service";

@Component({
    selector: "edit-portal-image",
  templateUrl: "./portal.image.edit.component.html",
    styleUrls: ['./portal.image.edit.component.css']
})


export class PortalImageEditComponent implements OnInit {


    _isEdit: boolean = false;
    WindowRef = WindowRef;

    portal_image: any = {
        externalPortalImage: { typeId: 2 },
        internalPortalImage: { typeId: 1 }
    }

    internalPlaceHolderFileNames = {
        placeholder1FileName: null,
        placeholder2FileName: null,
        placeholder3FileName: null,
      placeholder4FileName: null,
      placeholder5FileName: null,
    }
    externalPlaceHolderFileNames = {
        placeholder1FileName: null,
        placeholder2FileName: null,
        placeholder3FileName: null,
        placeholder4FileName: null,
    }


    internalPortalImage: any = {
        id: null,
        placeholder1File: null,
        placeholder2File: null,
        placeholder3File: null,
        placeholder4File: null,
        placeholder1FileUrl: null,
        placeholder2FileUrl: null,
        placeholder3FileUrl: null,
        placeholder4FileUrl: null,
        typeId: 1
    }

    externalPortalImage: any = {
        id: null,
        placeholder1File: null,
        placeholder2File: null,
        placeholder3File: null,
        placeholder4File: null,
        placeholder1FileUrl: null,
        placeholder2FileUrl: null,
        placeholder3FileUrl: null,
        placeholder4FileUrl: null,
        typeId: 2
    }

    ngOnInit(): void {
        let that = this;
        that.getPortalImageList();

        that._loggerService.log(WindowRef.lookup);
        that._loggerService.log(WindowRef.lookupType);
    }

    constructor(private _portalImageService: PortalImageService,

        private _activatedRoute: ActivatedRoute,
        private _toaster: NotifyFlashMessageService,
        private _loggerService: LoggerService,
        private _router: Router) {

    }

    getPortalImageList() {
        let that = this;
        this._portalImageService.GetPortalImageList().then(function (data) {
            that.internalPortalImage = data.internalPortalImage;
            that.externalPortalImage = data.externalPortalImage;

            if (that.internalPortalImage && that.internalPortalImage.placeholder1File) {
                that.internalPlaceHolderFileNames.placeholder1FileName = that.internalPortalImage.placeholder1File.fileName;
                that.internalPlaceHolderFileNames.placeholder2FileName = that.internalPortalImage.placeholder2File.fileName;
                that.internalPlaceHolderFileNames.placeholder3FileName = that.internalPortalImage.placeholder3File.fileName;
              that.internalPlaceHolderFileNames.placeholder4FileName = that.internalPortalImage.placeholder4File.fileName
              that.internalPlaceHolderFileNames.placeholder5FileName = that.internalPortalImage.placeholder5File.fileName
            }
            if (that.externalPortalImage && that.externalPortalImage.placeholder1File) {
                that.externalPlaceHolderFileNames.placeholder1FileName = that.externalPortalImage.placeholder1File.fileName;
                that.externalPlaceHolderFileNames.placeholder2FileName = that.externalPortalImage.placeholder2File.fileName;
                that.externalPlaceHolderFileNames.placeholder3FileName = that.externalPortalImage.placeholder3File.fileName;
                that.externalPlaceHolderFileNames.placeholder4FileName = that.externalPortalImage.placeholder4File.fileName
            }
        });
    }

    savePortalImage() {
        let that = this;
        that.portal_image.internalPortalImage = that.internalPortalImage;
        that.portal_image.externalPortalImage = that.externalPortalImage;

        that._portalImageService.SavePortalImage(that.portal_image).then(function (data) {
            if (data) {
                that._toaster.success("Successfully Updated.");
                that.navigateToHome();
            }
        });
    }

    getPreview() {
        let that = this;
        window.open("#/admin/portal/preview?placeholder1FilePath=" +
            that.internalPortalImage.placeholder1File.relativePath + "&placeholder1FileMimeType=" + that.internalPortalImage.placeholder1File.mimeType + "&placeholder2FilePath=" + that.internalPortalImage.placeholder2File.relativePath + "&placeholder2FileMimeType=" + that.internalPortalImage.placeholder2File.mimeType + "&placeholder3FilePath=" + that.internalPortalImage.placeholder3File.relativePath + "&placeholder3FileMimeType=" + that.internalPortalImage.placeholder3File.mimeType + "&placeholder4FilePath=" + that.internalPortalImage.placeholder4File.relativePath + "&placeholder4FileMimeType=" + that.internalPortalImage.placeholder4File.mimeType, "_blank");

    }
    getPreviewForExternal() {
        let that = this;
        window.open("#/admin/portal/preview?placeholder1FilePath=" +
            that.externalPortalImage.placeholder1File.relativePath + "&placeholder1FileMimeType=" + that.externalPortalImage.placeholder1File.mimeType + "&placeholder2FilePath=" + that.externalPortalImage.placeholder2File.relativePath + "&placeholder2FileMimeType=" + that.externalPortalImage.placeholder2File.mimeType + "&placeholder3FilePath=" + that.externalPortalImage.placeholder3File.relativePath + "&placeholder3FileMimeType=" + that.externalPortalImage.placeholder3File.mimeType + "&placeholder4FilePath=" + that.externalPortalImage.placeholder4File.relativePath + "&placeholder4FileMimeType=" + that.externalPortalImage.placeholder4File.mimeType, "_blank");
    }

    internalPlaceHolder1Uploaded(responseObj: any) {
        let that = this;
        that._loggerService.log(responseObj);
        that.internalPortalImage.placeholder1File = responseObj.data;
        that.internalPlaceHolderFileNames.placeholder1FileName = responseObj.data.fileName;
    }

    internalPlaceHolder2Uploaded(responseObj: any) {
        let that = this;
        that._loggerService.log(responseObj);
        that.internalPortalImage.placeholder2File = responseObj.data;
        that.internalPlaceHolderFileNames.placeholder2FileName = responseObj.data.fileName;
    }

    internalPlaceHolder3Uploaded(responseObj: any) {
        let that = this;
        that._loggerService.log(responseObj);
        that.internalPortalImage.placeholder3File = responseObj.data;
        that.internalPlaceHolderFileNames.placeholder3FileName = responseObj.data.fileName;
    }
    internalPlaceHolder4Uploaded(responseObj: any) {
        let that = this;
        that._loggerService.log(responseObj);
        that.internalPortalImage.placeholder4File = responseObj.data;
        that.internalPlaceHolderFileNames.placeholder4FileName = responseObj.data.fileName;
    }
  internalPlaceHolder5Uploaded(responseObj: any) {
    let that = this;
    that._loggerService.log(responseObj);
    that.internalPortalImage.placeholder5File = responseObj.data;
    that.internalPlaceHolderFileNames.placeholder5FileName = responseObj.data.fileName;
  }
    externalPlaceHolder1Uploaded(responseObj: any) {
        let that = this;
        that._loggerService.log(responseObj);
        that.externalPortalImage.placeholder1File = responseObj.data;
        that.externalPlaceHolderFileNames.placeholder1FileName = responseObj.data.fileName;
    }

    externalPlaceHolder2Uploaded(responseObj: any) {
        let that = this;
        that._loggerService.log(responseObj);
        that.externalPortalImage.placeholder2File = responseObj.data;
        that.externalPlaceHolderFileNames.placeholder2FileName = responseObj.data.fileName;
    }

    externalPlaceHolder3Uploaded(responseObj: any) {
        let that = this;
        that._loggerService.log(responseObj);
        that.externalPortalImage.placeholder3File = responseObj.data;
        that.externalPlaceHolderFileNames.placeholder3FileName = responseObj.data.fileName;
    }
    externalPlaceHolder4Uploaded(responseObj: any) {
        let that = this;
        that._loggerService.log(responseObj);
        that.externalPortalImage.placeholder4File = responseObj.data;
        that.externalPlaceHolderFileNames.placeholder4FileName = responseObj.data.fileName;
    }

    validateImagesize(fileObj: any) {
        let isValid = true;
        if (5242880) {
        }
    }


    navigateToHome() {
        this._router.navigate(["/admin/dashboard"]);
    }
}
